import {Component, HostListener, OnInit} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CompanyObject} from "../../../core/shared/models/company/companyObject";
import {CompanyAddEditComponent} from "../company-add-edit/company-add-edit.component";
import {Router} from "@angular/router";
import {CompanyService} from "../../shared/company.service";
import {MatTableDataSource} from "@angular/material/table";
import {Subject} from "rxjs";

@Component({
  selector: "app-companies-list",
  templateUrl: "./companies-list.component.html",
  styleUrls: ["./companies-list.component.scss"]
})
export class CompaniesListComponent implements OnInit {

  tableDataSource: MatTableDataSource<CompanyObject>;
  itemsToShow: CompanyObject[] = [];
  txtIcon: string;
  cardTitle: string;
  newShiftText: string;
  dc: string [];
  txtName: string;
  txtCompanyId: string;
  txtContactEmail: string;
  txtContactPhone: string;
  noDataText: string;
  innerWidth: number;
  dialogWidth: number;
  showBar: boolean;
  dcSubject: Subject<string[]> = new Subject<string[]>();


  @HostListener("window:resize", ["$event"])
  onresize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = [ "companyId", "Name"];
      this.dialogWidth = this.innerWidth - 50;
    } else {
      this.dc = ["companyId", "Name", "contactEmail", "contactPhone"];
      this.dialogWidth = 600;
      this.dcSubject.next(this.dc);
    }
  }

  constructor(private service: CompanyService, private router: Router,
              private addEditDialog: MatDialog) { }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<CompanyObject>();
    this.tableDataSource.data = this.itemsToShow;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = [ "companyId", "Name"];
      this.dialogWidth = this.innerWidth - 50;
    } else {
      this.dc = ["companyId", "Name", "contactEmail", "contactPhone"];
      this.dialogWidth = 600;
      this.dcSubject.next(this.dc);
    }
    this.initText();
    this.getItems();
  }

  initText(): void {
    this.txtName = "Nombre";
    this.txtCompanyId = "NIT";
    this.txtContactEmail = "Email";
    this.txtContactPhone = "Teléfono";
    this.txtIcon = "corporate_fare";
    this.cardTitle = "Empresas";
    this.newShiftText = "Agregar Empresa";
    this.noDataText = "No hay Datos en este momento!";
  }

  getItems(): void {
    this.showBar = true;
    this.service.loadAdminCompanies().subscribe(
      res => {
        if (res) {
          this.itemsToShow = res;
          this.tableDataSource.data = this.itemsToShow;
        }
        this.showBar = false;
      }, error => {
        console.error("Error:", error);
        this.showBar = false;
      }
    );
  }
  addEditItem(item?: CompanyObject): void {
    let theItem: CompanyObject;
    let focusable = true;
    if (item) {
      theItem = item;
      focusable = false;
    }
    const dialogRef: MatDialogRef<CompanyAddEditComponent> = this.addEditDialog.open(CompanyAddEditComponent, {
        panelClass: "dialogWidth",
        autoFocus: focusable,
        disableClose: true,
        closeOnNavigation: false,
        maxWidth: "100vw",
        data: theItem
      });
  }
}
