import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {AngularFireStorage} from "@angular/fire/storage";
import {from, Observable} from "rxjs";
import {CollaboratorObject} from "../../core/shared/models/persons/collaborator.object";
import {HttpClient} from "@angular/common/http";
import {QueryObject} from "../../core/shared/models/common/query.object";

@Injectable({
  providedIn: "root"
})
export class CollaboratorsService {

  private static CURRENT_COLLABORATOR: CollaboratorObject;
  private readonly DOC_TYPES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/public/document-types.json";
  private readonly COLOMBIA_CITIES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/public/toBePrivate/colombia.json";
  private readonly COLLECTION_NAME = "Collaborators";

  constructor(private angularFirestore: AngularFirestore, private angularFireStorage: AngularFireStorage,
              private httpClient: HttpClient) { }

  get collaborator(): CollaboratorObject{
    return CollaboratorsService.CURRENT_COLLABORATOR;
  }

  set collaborator(collaborator: CollaboratorObject) {
    CollaboratorsService.CURRENT_COLLABORATOR = collaborator;
  }

  getDocumentTypes(): Observable<any> {
    const location = this.angularFireStorage.storage.refFromURL(this.DOC_TYPES_LOCATION);
    return from(location.getDownloadURL());
  }

  getColombiaLocations(): Observable<any> {
    const location = this.angularFireStorage.storage.refFromURL(this.COLOMBIA_CITIES_LOCATION);
    return from(location.getDownloadURL());
  }

  getDocumentTypesFromURL(url: string): Observable<any> {
    return from(this.httpClient.get(url));
  }

  getColombiaLocationsFromURL(url: string): Observable<any> {
    return from(this.httpClient.get(url));
  }

  addCollaboratorsTransaction(item: CollaboratorObject): Observable<any> {
    const object: any = Object.assign({}, item);
    object.logs = item.logs.map(obj => Object.assign({}, obj));
    object.specialInfoItems = item.specialInfoItems.map(obj => Object.assign({}, obj));
    return from(this.angularFirestore.collection<CollaboratorObject>(this.COLLECTION_NAME).add(object));
  }

  updateCollaboratorTransaction(itemId: string, item: CollaboratorObject): Observable<any> {
    const object: any = Object.assign({}, item);
    object.logs = item.logs.map(obj => Object.assign({}, obj));
    object.specialInfoItems = item.specialInfoItems.map(obj => Object.assign({}, obj));
    return from(this.angularFirestore.collection<CollaboratorObject>(this.COLLECTION_NAME)
      .doc(itemId).set(object, {merge: true}));
  }

  loadPersonalTransactions(): Observable<CollaboratorObject[]> {
    return this.angularFirestore.collection<CollaboratorObject>(this.COLLECTION_NAME).valueChanges({idField: "uid"});
  }

  loadCollaboratorsByFilter(queryObject: QueryObject): Observable<CollaboratorObject[]> {
    console.log("query", queryObject);
    return this.angularFirestore.collection<CollaboratorObject>(this.COLLECTION_NAME, ref => ref
      .where(queryObject.field, "==", queryObject.value)).valueChanges();
  }
}
