<div fxFlex fxFill fxLayout="column">
  <mat-sidenav-container class="container_item">
    <mat-sidenav class="sidenav-general" (closedStart)="startClosing()" (openedStart)="startOpening()" #mainSidenav opened="true" [mode]="theSideNavMode">
      <app-main-menu (itemClick)="clickedItem()"></app-main-menu>
    </mat-sidenav>
    <mat-sidenav-content class="container_item_content">
      <app-main-toolbar [currentUser]="theCurrentUser" (toggleMenuEmmiter)="toggleMenu()"
                        [currentUserObservable]="theCurrentUserSubject.asObservable()"
                        [showMenuButtonSubject]="theToolbarButtonSubject.asObservable()"></app-main-toolbar>
      <router-outlet class="main_content" ></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
