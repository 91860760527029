export class VehicleForListObject {
  plate: string;
  reason: string;
  showWarning: boolean;
  constructor(v?: VehicleForListObject) {
    if (v) {
      this.plate = v.plate;
      this.reason = v.reason;
      this.showWarning = v.showWarning;
    }
  }
}
