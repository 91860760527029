import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {PersonTransactionObject} from "../../../../core/shared/models/access-personel/personTransaction.object";
import {Observable, Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {VehicleForListObject} from "../../../../core/shared/models/access-vehicle/vehicle-for-list.object";

@Component({
  selector: "app-vehicles-admin-access-list",
  templateUrl: "./vehicles-admin-access-list.component.html",
  styleUrls: ["./vehicles-admin-access-list.component.scss"]
})
export class VehiclesAdminAccessListComponent implements OnInit, OnDestroy {

  @Input() transactionData: VehicleForListObject[] = [];
  @Input() updateMyData: Observable<VehicleForListObject[]> = new Observable<VehicleForListObject[]>();
  tableDataSource: MatTableDataSource<VehicleForListObject>;
  showNoData: boolean;
  txtNoData: string;
  txtPlate: string;
  txtInfo: string;
  txtAlert: string;
  dc: string[] = [];
  innerWidth: number;
  updateMyDataSubscription: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<VehicleForListObject>();
    this.tableDataSource.data = this.transactionData;
    this.initTexts();
    this.dc = [this.txtPlate, this.txtInfo, this.txtAlert];
    this.updateMyDataSubscription = this.updateMyData.subscribe(
      next => {
        this.transactionData = next;
        this.tableDataSource.data = this.transactionData;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.updateMyDataSubscription) {
      this.updateMyDataSubscription.unsubscribe();
    }
  }

  initTexts(): void {
    this.txtNoData = "No existen registros de vehículos dentro de la obra!";
    this.txtPlate = "Placas";
    this.txtInfo = "INFO.";
    this.txtAlert = "?";
  }

}
