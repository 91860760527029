<form [formGroup]="vehicleForm">
  <div mat-dialog-title fxLayout="row">
    <mat-icon>{{txtIcon}}</mat-icon>
    <h3 class="card-title-h3">{{txtTitle}}</h3>
  </div>
  <div mat-dialog-content  class="main-content">
    <div fxLayout="column">
      <div class="centered">
        {{txtConfirmPretext}}
      </div>
      <div class="detail-text centered">
        {{txtPlate}}
      </div>
    </div>
    <div fxLayout="column">
      <mat-form-field appearance="fill" [ngClass]="{hidden: isExit}">
        <mat-label>{{txtReason}}</mat-label>
        <mat-select [formControl]="reasonControl" (selectionChange)="processOtherValue($event)" required>
          <mat-option *ngFor="let item of enumData | keyvalue" [value]="item.value">{{item.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" [ngClass]="{hidden: !isOther}">
        <mat-label>{{txtReason}}</mat-label>
        <input [formControl]="textReasonControl" matInput required>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="common-button" type="button" (click)="closeMe()" mat-raised-button>{{txtCancel|uppercase}}</button>
    <button class="common-button" type="button" (click)="confirm()"
            [disabled]="((vehicleForm.invalid && isOther) || reasonControl.invalid) && !isExit"
            mat-raised-button color="accent">{{txtAccept|uppercase}}</button>
  </div>
</form>

