<div>
  <form [formGroup]="searchGroup" (ngSubmit)="search(searchGroup.getRawValue())">
    <div class="search-box" fxLayout="row" fxLayout.lt-sm="column">
      <mat-form-field appearance="fill" fxFlex="40" class="search-box-item" ngClass.lt-sm="search-box-item-small">
        <mat-label>{{txtSearch}}</mat-label>
        <mat-select [formControl]="fieldControl">
          <mat-option *ngFor="let field of searchFields" [value]="field">
            {{field}}
          </mat-option>
        </mat-select>
        <mat-error>{{txtErrorField}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" fxFlex="40" class="search-box-item" ngClass.lt-sm="search-box-item-small">
        <mat-label>{{txtSearchValue}}</mat-label>
        <input [formControl]="valueControl" matInput required>
        <mat-error>{{txtErrorValue}}</mat-error>
      </mat-form-field>
      <div class="button-container" ngClass.lt-sm="button-container-small">
        <button mat-raised-button class="common-button" fxFlex.lt-sm="100"><mat-icon>{{txtSearchIcon}}</mat-icon>{{txtSearchButton|uppercase}}</button>
      </div>
    </div>
  </form>
</div>
