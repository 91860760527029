export class CommonDialogDataObject {
  icon: string;
  title: string;
  content: string;
  showLoading: boolean;

  constructor(c?: CommonDialogDataObject) {
    if (c) {
      this.icon = c.icon;
      this.title = c.title;
      this.content = c.content;
    }
  }
}
