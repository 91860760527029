import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VehiclesAccessListComponent } from "./components/vehicles-access-list/vehicles-access-list.component";
import { VehiclesAccessAddEditComponent } from "./components/vehicles-access-add-edit/vehicles-access-add-edit.component";
import { VehiclesAccessDetailsComponent } from "./components/vehicles-access-details/vehicles-access-details.component";
import {CoreModule} from "../core/core.module";
import { ConfirmAndReasonComponent } from "./components/dialog/confirm-and-reason/confirm-and-reason.component";
import { VehiclesAdminAccessListComponent } from "./components/admin/vehicles-admin-access-list/vehicles-admin-access-list.component";



@NgModule({
    declarations: [VehiclesAccessListComponent, VehiclesAccessAddEditComponent,
      VehiclesAccessDetailsComponent, ConfirmAndReasonComponent, VehiclesAdminAccessListComponent],
  exports: [
    VehiclesAccessDetailsComponent,
    VehiclesAdminAccessListComponent
  ],
    imports: [
        CommonModule,
        CoreModule,
    ]
})
// @ts-ignore
export class VehiclesAccessModule { }
