import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {AuthService} from "../../shared/auth.service";
import {IconsService} from "../../../core/shared/icons.service";
import {Router} from "@angular/router";
import {LoginObject} from "../../../core/shared/models/auth/login.object";
import {MatDialog} from "@angular/material/dialog";
import {VerifyReminderDialogComponent} from "../dialogs/verify-reminder-dialog/verify-reminder-dialog.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {

  subTitle: string;
  title: string;
  loginText: string;
  registerText: string;
  emailText: string;
  passwordText: string;
  googleText: string;
  forgotText: string;
  requiredEmail: string;
  requiredPassword: string;
  shortPassword: string;
  longPassword: string;
  wrongPasswordText: string;
  wrongUsernameText: string;
  wrongOtherThingText: string;
  logOutText: string;
  userLogged: boolean;
  errorLoginText = "";
  loginError: boolean;
  loginForm: FormGroup;
  usernameControl: FormControl;
  passwordControl: FormControl;
  loggingIn: boolean;

  loginSub: Subscription;
  userDataSub: Subscription;
  userDataSub2: Subscription;
  loginGoogleSub: Subscription;

  constructor(private authService: AuthService, private iconsService: IconsService, private router: Router,
              private matVerifyDialog: MatDialog) { }

  ngOnInit(): void {
    this.userLogged = this.authService.isUserAuth();
    this.iconsService.registerIcons();
    this.loggingIn = false;
    this.initTexts();
    this.initForm();
  }

  initForm(): void {
    this.usernameControl = new FormControl("", [Validators.required, Validators.email]);
    this.passwordControl = new FormControl("", [Validators.minLength(6), Validators.maxLength(20)]);
    this.loginForm = new FormGroup({
      usernameControl: this.usernameControl,
      passwordControl: this.passwordControl
    });
  }

  initTexts(): void {
    this.subTitle = "Bienvenidos - Iniciar sesión";
    this.title = "KostruAccess";
    this.loginText = "Iniciar sesión";
    this.registerText = "Registrarse";
    this.emailText = "Correo electrónico";
    this.passwordText = "Contraseña";
    this.googleText = "Iniciar sesión con Google";
    this.forgotText = "Olvidó su contraseña?";
    this.requiredEmail = "El correo electrónico es obligatorio";
    this.requiredPassword = "Se requiere una contraseña";
    this.shortPassword = "La contraseña es demasiado corta";
    this.longPassword = "La contraseña es demasiado Larga";
    this.wrongPasswordText = "contraseña incorrecta, por favor verifiquelos e intente de nuevo! o trate de recuperarla!";
    this.wrongUsernameText = "El usuario no existe en nuestra base de datos, por favor verifiquelos e intente de nuevo!";
    this.wrongOtherThingText = "Algo saliò mal, por favor intentelo de nuevo!!";
    this.logOutText = "Cerrar Sesión";
  }

  ngOnDestroy(): void {
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }
    if (this.userDataSub) {
      this.userDataSub.unsubscribe();
    }
    if (this.loginGoogleSub) {
      this.loginGoogleSub.unsubscribe();
    }
    if (this.userDataSub2) {
      this.userDataSub2.unsubscribe();
    }
  }

  logOut(): void {
    this.authService.logout();
  }

  printUser(event): void {
    console.log(event);
  }

  printError(event): void {
    console.error(event);
  }

  showError(kind: string): void {
    this.loggingIn = false;
    this.loginError = true;
    if (kind === "auth/user-not-found") {
      console.log(kind);
      this.errorLoginText = this.wrongUsernameText;
    } else if (kind === "auth/wrong-password") {
      this.errorLoginText = this.wrongPasswordText;
    } else {
      this.errorLoginText = this.wrongOtherThingText;
    }
  }
  hasError(controlName: string, errorName: string): boolean {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  submitLogin(formData: any): void {
    this.loggingIn = true;
    document.body.classList.add("busy_cursor");
    this.loginError = false;
    const login: LoginObject = { userName: formData.usernameControl, password: formData.passwordControl};
    this.loginSub = this.authService.loginWithUsernameAndPassword(login).subscribe(
      next => {
        console.log("User Verified: ", next.user.emailVerified);
        this.userDataSub = this.authService.getCurrentUser(next.user.uid).subscribe(
          nextUser => {
            console.log("Next User", next, nextUser);
            if (nextUser) {
              if (!next.user.emailVerified) {
                const showVerify = this.matVerifyDialog.open(VerifyReminderDialogComponent, {
                  width: "300px",
                });
                showVerify.afterClosed().subscribe(
                  nextDialog => this.router.navigate(["/dashboard"])
                );
              } else {
                this.router.navigate(["/dashboard"]);
              }
            }
            else {
              this.router.navigate(["/auth/userInfo"]);
            }
          }, error2 => this.showError(error2.code)
        );
      }, error => {
        this.showError(error.code);
        console.error("error login in with user and password", error);
      }
    );
  }
}
