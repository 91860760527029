<div class="login-area" fxLayoutAlign="space-around center">
  <mat-card class="login-card mat-elevation-z5" ngClass.lt-md="login-card-small">
    <form [formGroup]="forgotForm" (ngSubmit)="submitForgot(forgotForm.getRawValue())">
      <img class="image_size" ngClass.lt-md="image_size_small" src="assets/images/kostruAccessLogo.png" alt="KostruAccessLogoV1">
      <mat-card-header >
        <mat-card-title><h3 class="card-title-h3-login">{{subTitle}}</h3></mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <mat-form-field fxFlex class="FormFieldAll" appearance="fill">
          <mat-label>{{emailText}}</mat-label>
          <input matInput #iemailForgotten [formControl]="forgotEmailControl" required>
          <mat-error *ngIf="hasError('forgotEmailControl', 'required')">{{requiredEmail}}</mat-error>
          <mat-error *ngIf="hasError('forgotEmailControl', 'email')">{{requiredEmail}}</mat-error>
          <mat-error *ngIf="emailNotFound">{{emailNotFoundText}}</mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-raised-button class="common-button" type="button" (click)="goBack()">{{cancelButton | uppercase}}</button>
        <button mat-raised-button class="common-button" [disabled]="!forgotForm.valid" color="primary" type="submit">{{sendButton | uppercase}}</button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>


