import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LogEventKindEnum} from "../../../core/shared/models/common/enums/logEventKind.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProjectObject} from "../../../core/shared/models/company/project.object";
import {LogObject} from "../../../core/shared/models/common/log.object";
import {CompanyService} from "../../shared/company.service";
import {Observable, Subscription} from "rxjs";
import * as moment from "moment";
import {CompanyProjectInfoObject} from "../../../core/shared/models/company/company-project-info.object";
import {AuthService} from "../../../auth/shared/auth.service";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {CommonDialogObject} from "../../../core/shared/models/common/commonDialog.object";
import {GenericDialogComponent} from "../../../core/components/dialogs/generic-dialog/generic-dialog.component";

@Component({
  selector: "app-project-add-edit",
  templateUrl: "./project-add-edit.component.html",
  styleUrls: ["./project-add-edit.component.scss"]
})
export class ProjectAddEditComponent implements OnInit, OnDestroy {

  private ADD_EVENT = "Proyecto - Nuevo";
  private EDIT_EVENT = "Proyecto - Editado";
  private DELETE_EVENT = "Poyecto - Eliminado";
  private CURRENT_USER = "Espiritu Santo - espiritu@santo.com";

  txtOptions: string;
  txtInfoProject: string;
  txtDateProject: string;
  txtCancel: string;
  txtAccept: string;
  txtItemIcon: string;
  dialogTitle: string;
  txtName: string;
  txtCompanyName: string;
  txtDescription: string;
  txtLocationDep: string;
  txtLocationCit: string;
  txtExtraInfo: string;
  txtEnabled: string;
  txtDelete: string;
  txtStartDateMoment: string;
  txtExpectedMoment: string;
  txtEndDateMoment: string;

  txtRequiredName: string;
  txtRequiredDescription: string;
  txtRequiredLocationDep: string;
  txtRequiredLocationCit: string;
  txtRequiredStart: string;
  txtRequiredExpected: string;
  txtRequiredEnd: string;

  projectForm: FormGroup;
  nameControl: FormControl;
  companyNameControl: FormControl;
  descriptionControl: FormControl;
  extraInfoControl: FormControl;
  locationDepartmentControl: FormControl;
  locationCityControl: FormControl;
  startDateMomentControl: FormControl;
  endDateMomentControl: FormControl;
  companyIDControl: FormControl;
  enabledControl: FormControl;
  deleted: boolean;
  logs: LogObject[] = [];
  isNew: boolean;
  current: ProjectObject;
  original: ProjectObject;

  items: any[] = [];
  itemsSub: Subscription;
  colombiaSub: Subscription;
  projectSubscription: Subscription;
  cities: any[] = [];

  @ViewChild("deleteButton", {static: false}) deleteButton: ElementRef;

  constructor(private service: CompanyService, @Inject(MAT_DIALOG_DATA) private data: CompanyProjectInfoObject,
              private matDialogRef: MatDialogRef<ProjectAddEditComponent>, private authService: AuthService,
              private confirmDeleteDialog: MatDialog) { }

  ngOnInit(): void {
    this.initText();
    this.initForm();
    this.getLocation();
    this.isNew = true;
    if (this.data) {
      this.isNew = !this.data.project;
    }
    if (!this.isNew) {
      this.original = new ProjectObject(this.data.project);
      this.companyNameControl.setValue(this.original.companyName);
      this.companyIDControl.setValue(this.original.companyId);
    } else {
      this.companyNameControl.setValue(this.data.companyName);
      this.companyIDControl.setValue(this.data.companyId);
      this.enabledControl.setValue(true);
      this.enabledControl.disable();
      this.locationCityControl.disable();
    }
    const current: UserInfoObject = this.authService.getCurrentUserInfo();
    this.CURRENT_USER = `${current.userName} - ${current.userEmail}`;
    console.log("Data", this.data);
  }

  ngOnDestroy(): void {
    if (this.itemsSub) {
      this.itemsSub.unsubscribe();
    }
    if (this.colombiaSub) {
      this.colombiaSub.unsubscribe();
    }
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
  }

  getLocation(): void {
    this.itemsSub = this.service.getColombiaLocations().subscribe(
      res => {
         this.colombiaSub = this.service.getColombiaLocationsFromURL(res).subscribe(
          res2 => {
            this.items = res2;
            this.getItems();
          },
          error => console.error(error)
        );
      },
      error2 => console.error(error2)
    );
  }

  initForm(): void {
    this.nameControl = new FormControl ("", [Validators.required]);
    this.descriptionControl = new FormControl ("");
    this.extraInfoControl = new FormControl ("");
    this.companyNameControl = new FormControl("");
    this.companyIDControl = new FormControl("");
    this.startDateMomentControl = new FormControl("", [Validators.required]);
    this.endDateMomentControl = new FormControl("", [Validators.required]);
    this.locationDepartmentControl = new FormControl ("", [Validators.required]);
    this.locationCityControl = new FormControl ("", [Validators.required]);
    this.enabledControl = new FormControl("");
    this.projectForm = new FormGroup({
      nameControl: this.nameControl,
      descriptionControl: this.descriptionControl,
      extraInfoControl: this.extraInfoControl,
      locationDepartmentControl: this.locationDepartmentControl,
      locationCityControl: this.locationCityControl,
      startDateMomentControl: this.startDateMomentControl,
      endDateMomentControl: this.endDateMomentControl,
      enabledControl: this.enabledControl,
    });
  }

  getItems(): void {
    if (this.original) {
      this.nameControl.setValue(this.original.name);
      this.descriptionControl.setValue(this.original.description);
      this.extraInfoControl.setValue(this.original.extraInfo);
      this.companyNameControl.setValue(this.original.companyName);
      if (this.items && this.items.length > 0) {
        const department = this.items.findIndex(x => x.departamento === this.original.locationDepartment);
        this.locationDepartmentControl.setValue(this.items[department]);
        this.cities = this.items[department].ciudades;
        this.locationCityControl.setValue(this.original.locationCity);
      }
      this.enabledControl.setValue(this.original.enabled);
      this.startDateMomentControl.setValue(moment(this.original.startDateMoment, "DD/MM/YYYY"));
      this.endDateMomentControl.setValue(moment(this.original.endDateMoment, "DD/MM/YYYY"));
    }
  }

  showDeleteDialog(): void {
    const theData: CommonDialogObject = {
      title: "Eliminar Empresa!",
      content: `¿Confima que desea eliminar el Proyecto ${this.current.name}? ¡Esta opciòn no se puede deshacer!`
    };
    const confirmDialogRef = this.confirmDeleteDialog.open(GenericDialogComponent, {
      width: "310px",
      disableClose: true,
      closeOnNavigation: false,
      data: theData
    });
    confirmDialogRef.afterClosed().subscribe(
      next => {
        if (next) {
          this.delete();
        }
      }
    );
  }

  delete(): void {
    if (this.original) {
      this.original.deleted = true;
      this.original.logs.push(new LogObject(moment().unix(), this.DELETE_EVENT, LogEventKindEnum.DELETE, this.CURRENT_USER));
      this.projectSubscription = this.service.updateProject(this.original.uid, this.original).subscribe(
        res => {
          this.matDialogRef.close();
        }
      );
    }
  }

  save(): void {
    if (this.isNew) {
      this.current = new ProjectObject();
    } else {
      this.current = new ProjectObject(this.original);
    }
    this.current.name = this.nameControl.value;
    this.current.companyName = this.companyNameControl.value;
    this.current.companyId = this.companyIDControl.value;
    this.current.description = this.descriptionControl.value;
    this.current.extraInfo = this.extraInfoControl.value;
    this.current.locationDepartment = this.locationDepartmentControl.value.departamento;
    this.current.locationCity = this.locationCityControl.value;
    this.current.enabled = this.enabledControl.value;
    this.current.startDateMoment = this.startDateMomentControl.value.format("DD/MM/YYYY");
    this.current.endDateMoment = this.endDateMomentControl.value.format("DD/MM/YYYY");

    if (this.isNew) {
      this.current.logs = [];
      this.current.logs.push(new LogObject(moment().unix(), this.ADD_EVENT, LogEventKindEnum.CREATE, this.CURRENT_USER));
      this.projectSubscription = this.service.addProject(this.current).subscribe(
        res => {
          this.matDialogRef.close();
        }
      );
    } else {
      const events: string [] = [];
      const title: string = this.EDIT_EVENT + "<br>";
      events.push(title);
      if (this.current.name !== this.original.name) {
        events.push(`Nombre del proyecto: ${this.original.name} => ${this.current.name}` + "<br>");
      }
      if (this.current.companyName !== this.original.companyName) {
        events.push(`Nombre de la empresa: ${this.original.companyName} => ${this.current.companyName}` + "<br>");
      }
      if (this.current.companyId !== this.original.companyId) {
        events.push(`Id de la Emrpesa: ${this.original.companyId} => ${this.current.companyId}` + "<br>");
      }
      if (this.current.extraInfo !== this.original.extraInfo) {
        events.push(`Información extra: ${this.original.extraInfo} => ${this.current.extraInfo}` + "<br>");
      }
      if (this.current.description !== this.original.description) {
        events.push(`Descripción: ${this.original.description} => ${this.current.description}` + "<br>");
      }
      if (this.current.locationDepartment !== this.original.locationDepartment) {
        events.push(`Departamento: ${this.original.locationDepartment} => ${this.current.locationDepartment}` + "<br>");
      }
      if (this.current.locationCity !== this.original.locationCity) {
        events.push(`Ciudad: ${this.original.locationCity} => ${this.current.locationCity}` + "<br>");
      }
      if (this.current.enabled !== this.original.enabled) {
        events.push(`Proyecto habilitado: ${this.original.enabled} => ${this.current.enabled}` + "<br>");
      }
      if (this.current.startDateMoment !== this.original.startDateMoment) {
        events.push(`Fecha de inicio: ${this.original.startDateMoment} => ${this.current.startDateMoment}` + "<br>");
      }
      if (this.current.endDateMoment !== this.original.endDateMoment) {
        events.push(`Fecha final: ${this.original.endDateMoment} => ${this.current.endDateMoment}` + "<br>");
      }
      let eventText = "";
      console.log("events", events);
      if (events.length > 1) {
        for (const linea of events) {
          eventText += linea;
        }
        this.current.logs.push(new LogObject(moment().unix(), eventText, LogEventKindEnum.MODIFY, this.CURRENT_USER));
        this.projectSubscription = this.service.updateProject(this.current.uid, this.current).subscribe(
          res => {
            this.matDialogRef.close();

          }
        );
      } else {
        this.matDialogRef.close();
      }
    }
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.projectForm.controls[controlName].hasError(errorName);
  }

  updateCities(event: any): void {
    if (event.value.ciudades && event.value.ciudades.length > 0) {
      this.cities = event.value.ciudades;
      this.locationCityControl.enable();
    }
  }

  initText(): void {
    if (this.data){
      this.dialogTitle = "Editar proyecto";
    } else {
      this.dialogTitle = "Nuevo proyecto";
    }
    this.txtInfoProject = "Datos del proyecto";
    this.txtDateProject = "Fechas del proyecto";
    this.txtOptions = "Opciones del proyecto";
    this.txtItemIcon = "assignment";
    this.txtName = "Nombre del proyecto";
    this.txtDescription = "Descripción";
    this.txtCompanyName = "Nombre de la empresa";
    this.txtLocationDep = "Departamento";
    this.txtLocationCit = "Ciudad";
    this.txtExtraInfo = "Información extra";
    this.txtEnabled = "Habilitar/Deshabilitar proyecto";
    this.txtDelete = "Borrar";
    this.txtStartDateMoment = "Fecha de inicio";
    this.txtExpectedMoment = "Fecha estimada";
    this.txtEndDateMoment = "Fecha final";

    this.txtRequiredName = "El nombre es requerido";
    this.txtRequiredDescription = "La descripción es requerida";
    this.txtRequiredLocationCit = "La ciudad es requerida";
    this.txtRequiredLocationDep = "El departamento es requerido";
    this.txtRequiredStart = "La fecha de inicio es requerida";
    this.txtRequiredExpected = "La fecha estimada es requerida";
    this.txtRequiredEnd = "La fecha final es requerida";

    this.txtCancel = "Cancelar";
    this.txtAccept = "Aceptar";
  }
}
