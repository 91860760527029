import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-inventory-list",
  templateUrl: "./inventory-list.component.html",
  styleUrls: ["./inventory-list.component.scss"]
})
export class InventoryListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
