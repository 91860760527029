import {Component, Inject, OnInit} from "@angular/core";
import {UserKindObject} from "../../../../core/shared/models/auth/UserKind.object";
import {UsersService} from "../../../shared/users.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSelectChange} from "@angular/material/select";
import {ProjectObject} from "../../../../core/shared/models/company/project.object";
import {UserRoleInProjectObject} from "../../../../core/shared/models/auth/userRoleInProject.object";
import {ProjectInUserObject} from "../../../../core/shared/models/company/projectInUser.object";
import {UserKindEnum} from "../../../../core/shared/models/auth/enums/userKind.enum";

@Component({
  selector: "app-users-projects-add-edit",
  templateUrl: "./users-projects-add-edit.component.html",
  styleUrls: ["./users-projects-add-edit.component.scss"]
})
export class UsersProjectsAddEditComponent implements OnInit {

  txtIcon: string;
  txtDialogTitle: string;
  txtAdd: string;
  txtRole: string;
  txtProject: string;
  selected: UserRoleInProjectObject;
  theInitialObjects: UserKindObject[] = [];
  theObjects: UserKindObject[] = [];
  theProjects: ProjectObject[] = [];
  formGroup: FormGroup;
  projectControl: FormControl;
  permissionControl: FormControl;
  theCap = 140;

  constructor(private usersService: UsersService, private dialogRef: MatDialogRef<UsersProjectsAddEditComponent>,
              @Inject(MAT_DIALOG_DATA) private data: { current_roles: ProjectInUserObject[],
                existing_projects: ProjectObject[],  cap: number }) { }

  ngOnInit(): void {
    this.initTexts();
    this.initForm();
    console.log(this.data);
    this.theProjects = this.data.existing_projects;
    if (this.data.cap) {
      this.theCap = this.data.cap;
    }
  }

  initTexts(): void {
    this.txtIcon = "supervised_user_circle";
    this.txtDialogTitle = "Agregar Permiso";
    this.txtAdd = "Agregar";
    this.txtProject = "Proyecto";
    this.txtRole = "Permiso";
  }

  initForm(): void {
    this.projectControl = new FormControl("", Validators.required);
    this.permissionControl = new FormControl("", Validators.required);
    this.formGroup = new FormGroup({
      permissionControl: this.permissionControl,
      projectControl: this.projectControl
    });
    this.permissionControl.disable();
  }

  changeProject(selectedDataProject: MatSelectChange): void {
    console.log("Selected Value", selectedDataProject);
    let idx = -1;
    if (this.data.current_roles.length > 0) {
      idx = this.data.current_roles.findIndex(x => x.projectName === selectedDataProject.value.name);
      this.selected = new UserRoleInProjectObject();
      if (idx >= 0) {
        console.log("Existe y tiene permisos");
        this.selected.projectName = this.data.current_roles[idx].projectName;
        this.selected.projectUID = this.data.current_roles[idx].projectID;
      } else {
        console.log("No Existe y no tiene permisos");
        this.selected.projectName = selectedDataProject.value.name;
        this.selected.projectUID = selectedDataProject.value.uid;
      }
    } else {
      this.selected = new UserRoleInProjectObject();
      this.selected.projectName = selectedDataProject.value.name;
      this.selected.projectUID = selectedDataProject.value.uid;
    }
    for (const uke in UserKindEnum) {
      if (!isNaN(Number(uke))) {
        const item = this.usersService.userKindEnumsToSingleObject(Number(uke));
        if (item.value > 9 && item.value <= this.theCap) {
          this.theInitialObjects.push(item);
        }
      }
    }
    this.theObjects = [];
    if (this.theInitialObjects.length > 0) {
      let theRoles: UserKindObject[] = [];
      if (idx > -1) {
        theRoles = this.usersService.userKindEnumsToObjects(this.data.current_roles[idx].roles);
      }
      this.theObjects = this.usersService.userRoleComparer(this.theCap, theRoles, this.theInitialObjects);
      console.log("Loading");
    }
    this.permissionControl.enable();
  }

  changedRole(data: MatSelectChange): void {
    console.log(data, this.selected);
    this.selected.role = data.value;
  }

  addRole(): void {
    this.dialogRef.close(this.selected);
  }

}
