<div mat-dialog-title>
  <div fxLayout="row">
    <mat-icon>{{txtItemIcon}}</mat-icon>
    <h3 class="card-title-h3">{{dialogTitle}}</h3>
    <span fxFlex></span>
    <button #deleteButton mat-icon-button color="warn" type="button" (click)="showDeleteDialog()"><mat-icon>delete</mat-icon></button>
  </div>
</div>
<form [formGroup]="companyForm" (ngSubmit)="save()" autocomplete="off">
  <div mat-dialog-content class="dialog-size" ngClass.lt-sm="dialog-size-small">
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50" class="first-line item">
          <mat-label>{{txtName}}</mat-label>
          <input matInput [formControl]="nameControl" required>
          <mat-error *ngIf="hasError('nameControl', 'required')">
            {{txtRequiredName}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" [fxFlex]="nitWidth" class="first-line item" ngClass.lt-sm="first-line-small">
          <mat-label>{{txtCompanyID}}</mat-label>
          <input matInput [formControl]="companyIDControl" required>
          <mat-error *ngIf="hasError('companyIDControl', 'required')">
            {{txtRequiredCompany}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="20" class="item first-line" [ngClass]="{'hidden': !isNew}" ngClass.lt-sm="first-line-small">
          <mat-label>{{txtLimit}}</mat-label>
          <input type="number" matInput [formControl]="limitControl" required>
          <mat-error *ngIf="hasError('limitControl', 'required')">
            {{txtRequiredLimit}}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50"  class="item">
          <mat-label>{{txtContactEmail}}</mat-label>
          <input matInput [formControl]="contactEmailControl" required>
          <mat-error *ngIf="hasError('contactEmailControl', 'required')">
            {{txtRequiredContactEmail}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50" class="item">
          <mat-label>{{txtContactPhone}}</mat-label>
          <input matInput [formControl]="contactPhoneControl" required>
          <mat-error *ngIf="hasError('contactPhoneControl', 'required')">
            {{txtRequiredContactPhone}}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="100" class="item">
          <mat-label>{{txtBusinessAddress}}</mat-label>
          <input matInput [formControl]="BusinessAddressControl" required>
          <mat-error *ngIf="hasError('BusinessAddressControl', 'required')">
            {{txtRequiredAddress}}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
          <mat-label>{{txtBusinessDepartment}}</mat-label>
          <mat-select [formControl]="BusinessDepartmentControl" matInput required (selectionChange)="updateCities($event)">
            <mat-option *ngFor="let item of items" [value]="item">{{item.departamento}}</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('BusinessDepartmentControl', 'required')">
            {{txtRequiredDepartment}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
          <mat-label>{{txtBusinessCity}}</mat-label>
          <mat-select [formControl]="BusinessCityControl" matInput required>
            <mat-option *ngFor="let city of cities" [value]="city">{{city}}</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('BusinessCityControl', 'required')">
            {{txtRequiredCity}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <app-projects-list *ngIf="!isNew" [companyId]="original.uid" [companyName]="original.name"></app-projects-list>
    <section class="checked-item">
      <mat-slide-toggle color="primary"  ngClass.lt-sm="checked-item-small" [formControl]="enabled">{{txtEnabled}}</mat-slide-toggle>
    </section>
    <div *ngIf="isNew" class="theSpacing theNewCompany">
      {{theNewCompanyText}}
    </div>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center" class="item">
    <button mat-raised-button color="accent" class="button-class" type="button" (click)="cancel()">{{txtCancel|uppercase}}</button>
    <button mat-raised-button color="primary" class="button-class" type="submit" [disabled]="companyForm.invalid">{{txtAccept|uppercase}}</button>
  </div>
</form>
