export class SearchObject {
  field: string;
  value: string;

  constructor(s?: SearchObject) {
    if (s) {
      this.field = s.field;
      this.value = s.value;
    }
  }
}
