import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-search-admin-access",
  templateUrl: "./search-admin-access.component.html",
  styleUrls: ["./search-admin-access.component.scss"]
})
export class SearchAdminAccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
