<div mat-dialog-title>
  <div fxLayout="row">
    <mat-icon>{{txtItemIcon}}</mat-icon>
    <h3 class="card-title-h3">{{dialogTitle}}</h3>
    <span fxFlex></span>
    <button #deleteButton mat-icon-button color="warn" type="button" (click)="showDeleteDialog()"><mat-icon>delete</mat-icon></button>
  </div>
</div>
<form [formGroup]="projectForm" (ngSubmit)="save()" autocomplete="off">
  <div mat-dialog-content class="dialog-size" ngClass.lt-sm="dialog-size-small">
    <div fxLayout="column">
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="100" class="first-line item">
          <mat-label>{{txtName}}</mat-label>
          <input matInput [formControl]="nameControl" required>
          <mat-error *ngIf="hasError('nameControl', 'required')">
            {{txtRequiredName}}
          </mat-error>
        </mat-form-field>
        <mat-form-field  appearance="fill" fxFlex.lt-sm="100" fxFlex="50"  class="first-line item hidden">
          <mat-label>{{txtCompanyName}}</mat-label>
          <input matInput [formControl]="companyNameControl" readonly>
        </mat-form-field>
        <mat-form-field  appearance="fill" fxFlex.lt-sm="100" fxFlex="50"  class="first-line item hidden">
          <mat-label>{{txtCompanyName}}</mat-label>
          <input matInput [formControl]="companyIDControl" readonly>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="100" class="first-line item" ngClass.lt-sm="first-line-small">
          <mat-label>{{txtDescription}}</mat-label>
          <textarea rows="4" matInput [formControl]="descriptionControl" required></textarea>
          <mat-error *ngIf="hasError('descriptionControl', 'required')">
            {{txtRequiredDescription}}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="100" class="first-line item" ngClass.lt-sm="first-line-small">
          <mat-label>{{txtExtraInfo}}</mat-label>
          <textarea rows="4" matInput [formControl]="extraInfoControl"></textarea>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
          <mat-label>{{txtLocationDep}}</mat-label>
          <mat-select [formControl]="locationDepartmentControl" matInput required (selectionChange)="updateCities($event)">
            <mat-option *ngFor="let item of items" [value]="item">{{item.departamento}}</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('locationDepartmentControl', 'required')">
            {{txtRequiredLocationDep}}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
          <mat-label>{{txtLocationCit}}</mat-label>
          <mat-select [formControl]="locationCityControl" matInput required>
            <mat-option *ngFor="let city of cities" [value]="city">{{city}}</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('locationCityControl', 'required')">
            {{txtRequiredLocationCit}}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field  appearance="fill" fxFlex.lt-sm="100" fxFlex="50" class="first-line item">
          <mat-label>{{txtStartDateMoment}}</mat-label>
          <input [formControl]="startDateMomentControl" matInput [matDatepicker]="pickerStart" matInput required>
          <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
          <mat-datepicker #pickerStart startView="multi-year"></mat-datepicker>
          <mat-error *ngIf="hasError('startDateMomentControl', 'required')">
            {{txtRequiredStart}}
          </mat-error>
        </mat-form-field>
        <mat-form-field  appearance="fill" fxFlex.lt-sm="100" fxFlex="50" class="first-line item">
          <mat-label>{{txtEndDateMoment}}</mat-label>
          <input [formControl]="endDateMomentControl" matInput [matDatepicker]="prickerEnd" matInput required>
          <mat-datepicker-toggle matSuffix [for]="prickerEnd"></mat-datepicker-toggle>
          <mat-datepicker #prickerEnd startView="multi-year"></mat-datepicker>
          <mat-error *ngIf="hasError('endDateMomentControl', 'required')">
            {{txtRequiredEnd}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <section class="checked-item">
      <mat-slide-toggle color="primary" [formControl]="enabledControl">{{txtEnabled | uppercase}}</mat-slide-toggle>
    </section>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center" class="item">
    <button mat-raised-button color="accent" class="common-button" type="button" (click)="cancel()">{{txtCancel|uppercase}}</button>
    <button mat-raised-button color="primary" class="common-button" type="submit" [disabled]="projectForm.invalid">{{txtAccept|uppercase}}</button>
  </div>
</form>
