import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotFoundKindEnum} from "../../../../core/shared/models/auth/enums/notFoundKind.enum";
import {NotFoundEmailDialogDataObject} from "../../../../core/shared/models/auth/notFoundEmailDialogData.object";

@Component({
  selector: "app-forgot-dialog",
  templateUrl: "./forgot-dialog.component.html",
  styleUrls: ["./forgot-dialog.component.scss"]
})
export class ForgotDialogComponent implements OnInit {

  displayText: string;
  displayTitle: string;

  constructor(public matDialogRef: MatDialogRef<ForgotDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: NotFoundEmailDialogDataObject) { }

  ngOnInit(): void {
    if (this.data.kind === NotFoundKindEnum.INVALID) {
      this.displayText = "Por favor Ingrese un correo electrónico valido!";
      this.displayTitle = "Correo electrónico invalido";
    }
    if (this.data.kind === NotFoundKindEnum.NOT_FOUND) {
      this.displayText = "El correo electrónico no existe en nuestra base de datos, por favor verifiquelo o pongase en contacto con nosotros!";
      this.displayTitle = "Correo electrónico no registrado";
    }

  }

  closeDialog(): void {
    this.matDialogRef.close();
  }

}
