import {Component, Inject, OnInit} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {LogObject} from "../../../shared/models/common/log.object";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {LogDialogDataObject} from "../../../shared/models/common/logDialogData.object";
import * as moment from "moment";
import {LogEventKindEnum} from "../../../shared/models/common/enums/logEventKind.enum";
import {Moment} from "moment/moment";

@Component({
  selector: "app-logs-list",
  templateUrl: "./logs-list.component.html",
  styleUrls: ["./logs-list.component.scss"]
})
export class LogsListComponent implements OnInit {

  txtDialogIcon: string;
  txtDialogTitle: string;
  txtClose: string;
  txtDate: string;
  txtEventKind: string;
  txtUser: string;
  dc: string[] = [];

  tableDataSource: MatTableDataSource<LogObject>;
  logsToShow: LogObject[] = [];

  constructor(@Inject(MAT_DIALOG_DATA)private logDialogData: LogDialogDataObject) { }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<LogObject>();
    this.initTexts();
    this.initData();
  }

  initTexts(): void {
    this.txtDialogIcon = "fact_check";
    this.txtDialogTitle = "Registro de eventos";
    this.txtClose = "Cerrar Eventos";
    this.txtDate = "Fecha y Hora";
    this.txtEventKind = "Tipo de evento";
    this.txtUser = "Hecho por";
  }

  initData(): void {
    if (this.logDialogData) {
      this.logsToShow = this.logDialogData.logs;
      this.txtDialogTitle = `${this.logDialogData.title} - Registro de eventos`;
      this.logsToShow = this.logDialogData.logs;
      this.tableDataSource.data = this.logsToShow;
    }
  }

  showDetails(item: LogObject): void {
    console.log("Item to show", item);
    // TODO: Implementar el dialogo y mostrar sus datos;
  }

  mapKid(kind: LogEventKindEnum): string {
    switch (kind) {
      case LogEventKindEnum.CREATE:
        return "CREAR";
    case LogEventKindEnum.MODIFY:
      return "MODIFICAR";
    case LogEventKindEnum.DELETE:
      return "ELIMINAR";
      default:
        return "N/A";
    }
  }

  momentToString(dateNumber: number): string {
    const format = "DD/MM/YYYY HH:mm:ss";
    return moment.unix(dateNumber).format(format);
  }

}
