import {Component, Inject, OnInit} from "@angular/core";
import {CollaboratorsService} from "../../shared/collaborators.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CollaboratorObject} from "../../../core/shared/models/persons/collaborator.object";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import * as moment from "moment";
import {SpecialInfoObject} from "../../../core/shared/models/persons/complementary/specialInfo.object";
import {LogObject} from "../../../core/shared/models/common/log.object";
import {LogEventKindEnum} from "../../../core/shared/models/common/enums/logEventKind.enum";
import {AuthService} from "../../../auth/shared/auth.service";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";

@Component({
  selector: "app-collaborators-add-edit",
  templateUrl: "./collaborators-add-edit.component.html",
  styleUrls: ["./collaborators-add-edit.component.scss"]
})
export class CollaboratorsAddEditComponent implements OnInit {

  private ADD_EVENT = "Colaborador - Nuevo";
  private EDIT_EVENT = " Colaborador - Modificado";
  private DELETE_EVENT = "Colaborador - Eliminado";
  private CURRENT_USER = "pepito Perez - pepito@perez.com";

  txtFirstName: string;
  txtSecondName: string;
  txtLastName: string;
  txtSecondLastName: string;
  txtDocumentNumber: string;
  txtDocumentType: string;
  txtDocumentLocationOne: string;
  txtDocumentLocationTwo: string;
  txtDocumentExpeditionDate: string;
  txtPhone: string;
  txtEmail: string;
  txtAddress: string;
  txtBirthDate: string;
  txtBloodTypeOne: string;
  txtBloodTypeTwo: string;
  txtActiveCollaborator: string;
  txtRole: string;

  txtRequiredFirstName: string;
  txtRequiredLastName: string;
  txtRequiredIdentify: string;
  txtRequiredTypeIdentify: string;
  txtRequiredFromIdentify: string;
  txtRequiredBlood: string;
  txtRequiredBirthDate: string;
  txtRequiredPhone: string;
  txtValidPhoneMin: string;
  txtValidPhoneMax: string;
  txtRequiredEmail: string;
  txtValidEmail: string;
  txtRequiredAddress: string;
  txtRequiredLaborCCF: string;
  txtRequiredLaborARL: string;
  txtRequiredLaborEPS: string;
  txtRequiredLaborIPS: string;
  txtRequiredLaborFPS: string;
  txtRequiredLaborCES: string;
  txtRequiredLaborSalary: string;
  txtRequiredLaborRole: string;
  txtDocNumberShort: string;
  txtDocNumberLong: string;
  txtCCF: string;
  txtCES: string;
  txtARL: string;
  txtIPS: string;
  txtEPS: string;
  txtFPS: string;
  txtSalary: string;

  txtAccept: string;
  txtCancel: string;
  dialogTitle: string;
  txtItemIcon: string;
  txtPersonalInfo: string;
  txtContactInfo: string;
  txtLaborInfo: string;
  txtSpecialInfo: string;
  currentUser: UserInfoObject;
  original: CollaboratorObject;
  current: CollaboratorObject;
  // Form
  collaboratorsForm: FormGroup;
  personalFirstName: FormControl;
  personalSecondName: FormControl;
  personalLastName: FormControl;
  personalSecondLastName: FormControl;
  personalIdentification: FormControl;
  personalIdentificationType: FormControl;
  personalIdentificationFrom: FormControl;
  personalIdentificationFromCity: FormControl;
  personalIdentificationExpeditionDate: FormControl;
  personalContactFrom: FormControl;
  personalContactFromCity: FormControl;
  personalBloodType: FormControl;
  personalBloodTypeTwo: FormControl;
  personalBirthDateString: FormControl; // Format "DD/MM/YYYY"
  contactPhone: FormControl;
  contactEmail: FormControl;
  contactAddress: FormControl;
  laborCCF: FormControl;
  laborARL: FormControl;
  laborEPS: FormControl;
  laborIPS: FormControl;
  laborFPS: FormControl;
  laborCES: FormControl;
  laborRole: FormControl;
  laborSalary: FormControl;
  useSpecialInfo: FormControl;
  activeFC: FormControl;

  items: any[] = [];
  itemsObs: Observable<any[]>;
  itemsSub: Subscription;
  docs: any[] = [];
  docObs: Observable<any[]>;
  docSub: Subscription;
  cities: any[] = [];
  citiesContact: any[] = [];
  bloodTypes: string[] = ["A", "B", "AB", "O"];
  rhFactor: string[] = ["Positivo", "Negativo"];
  specialInfoObjects: SpecialInfoObject[] = [];
  isNew: boolean;

  constructor(private collaboratorsService: CollaboratorsService, private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private data, private matDialogRef: MatDialogRef<CollaboratorsAddEditComponent>) { }

  ngOnInit(): void {
    this.initTexts();
    this.isNew = !this.data;
    if (!this.isNew) {
      this.original = this.data;
    }
    if (this.current) {
      if (this.current.specialInfoItems && this.current.specialInfoItems.length > 0) {
        this.specialInfoObjects = this.current.specialInfoItems;
      } else {
        this.current.specialInfoItems = [];
      }
    }
    this.initForm();
    this.getDocuments();
    this.currentUser = this.authService.getCurrentUserInfo();
    this.CURRENT_USER = `${this.currentUser.userName} - ${this.currentUser.userEmail}`;
  }

  getDocuments(): void {
    this.itemsSub = this.collaboratorsService.getColombiaLocations().subscribe(
      res => {
        this.collaboratorsService.getColombiaLocationsFromURL(res).subscribe(
          res2 => {
            this.items = res2;
            this.docSub = this.collaboratorsService.getDocumentTypes().subscribe(
              res3 => {
                this.collaboratorsService.getDocumentTypesFromURL(res3).subscribe(
                  res4 => {
                    this.docs = res4;
                    this.loadData();
                  },
                  error3 => console.log(error3)
                );
                this.docObs = this.collaboratorsService.getDocumentTypesFromURL(res3).pipe();
              },
              error4 => console.error(error4)
            );
          },
          error2 => console.log(error2)
        );
      } ,
      error => console.error(error)
    );
  }

  initTexts(): void {
    if (this.data) {
      this.dialogTitle = "Editar Colaborador";
    } else {
      this.dialogTitle = "Nuevo Colaborador";
    }
    this.txtFirstName = "Nombre";
    this.txtSecondName = "Segundo Nombre";
    this.txtLastName = "Primer Apellido";
    this.txtSecondLastName = "Segundo Apellido";
    this.txtDocumentNumber = "No. de documento";
    this.txtDocumentType = "Tipo de Documento";
    this.txtDocumentLocationOne = "Lugar de expedición - Departamento";
    this.txtDocumentLocationTwo = "Lugar de expedición - Ciudad";

    this.txtPhone = "Teléfono";
    this.txtEmail = "Correo electrónico";
    this.txtAddress = "Dirección";
    this.txtBirthDate = "Fecha de Nacimento";
    this.txtBloodTypeOne = "Grupo Sanguíneo";
    this.txtBloodTypeTwo = "Factor RH";

    this.txtCCF = "Caja de compensación";
    this.txtCES = "Fondo de Cesantias";
    this.txtARL = "ARL";
    this.txtIPS = "IPS";
    this.txtEPS = "EPS";
    this.txtFPS = "Fondo de Pensiones";
    this.txtSalary = "Salario";
    this.txtRole = "Cargo";

    this.txtItemIcon = "contacts";
    this.txtPersonalInfo = "Información Personal";
    this.txtContactInfo = "Datos de Contacto";
    this.txtLaborInfo = "Datos Laborales";
    this.txtSpecialInfo = "Certificados y otros";

    this.txtRequiredFirstName = "El nombre es requerido";
    this.txtRequiredLastName = "El apellido es requerido";
    this.txtRequiredIdentify = "El No. de documento es requerido";
    this.txtRequiredTypeIdentify = "Tipo de identificación requerido";
    this.txtRequiredFromIdentify = "Lugar de expedición requerido";
    this.txtRequiredBlood = "Tipo de sangre requerido";
    this.txtRequiredBirthDate = "Fecha de nacimiento requerida";
    this.txtRequiredPhone = "Número de teléfono requerido";
    this.txtValidPhoneMin = "El número es demasiado corto";
    this.txtValidPhoneMax = "El número es demasiado largo";
    this.txtRequiredEmail = "Correo electrónico requerido";
    this.txtValidEmail = "Ingrese un correo válido por favor";
    this.txtRequiredAddress = "Dirección requerida";
    this.txtRequiredLaborCCF = "CCF requerida";
    this.txtRequiredLaborARL = "ARL requerida";
    this.txtRequiredLaborEPS = "EPS requerida";
    this.txtRequiredLaborIPS = "IPS requerida";
    this.txtRequiredLaborFPS = "FPS requerida";
    this.txtRequiredLaborCES = "CES requerida";
    this.txtRequiredLaborSalary = "Salario requerido";
    this.txtRequiredLaborRole = "Cargo requerido";
    this.txtDocNumberShort = "El No. de documento es muy corto";
    this.txtDocNumberLong = "El No. de documento es muy largo";
    this.txtActiveCollaborator = "Colaborador activo?";
    this.txtAccept = "Aceptar";
    this.txtCancel = "Cancelar";
  }

  initForm(): void {
    this.personalFirstName = new FormControl("", [Validators.required]);
    this.personalSecondName = new FormControl();
    this.personalLastName = new FormControl("", [Validators.required]);
    this.personalSecondLastName = new FormControl();
    this.personalIdentification = new FormControl("", [Validators.required, Validators.minLength(7),
      Validators.maxLength(18)]);
    this.personalIdentificationType = new FormControl("", [Validators.required]);
    this.personalIdentificationFrom = new FormControl("", [Validators.required]);
    this.personalIdentificationFromCity = new FormControl("", Validators.required);
    this.personalContactFrom = new FormControl("", [Validators.required]);
    this.personalContactFromCity = new FormControl("", Validators.required);
    this.personalBloodType = new FormControl("", [Validators.required]);
    this.personalBloodTypeTwo = new FormControl("", [Validators.required]);
    this.personalBirthDateString = new FormControl("", [Validators.required]);
    this.personalIdentificationExpeditionDate = new FormControl("", Validators.required);
    this.contactPhone = new FormControl("", [Validators.required, Validators.minLength(7),
      Validators.maxLength(15)]);
    this.contactEmail = new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]);
    this.contactAddress = new FormControl("", [Validators.required]);
    this.laborCCF = new FormControl("", [Validators.required]);
    this.laborARL = new FormControl("", [Validators.required]);
    this.laborEPS = new FormControl("", [Validators.required]);
    this.laborIPS = new FormControl("", [Validators.required]);
    this.laborFPS = new FormControl("", [Validators.required]);
    this.laborCES = new FormControl("", [Validators.required]);
    this.laborSalary = new FormControl("", [Validators.required]);
    this.laborRole = new FormControl("", [Validators.required]);
    this.activeFC = new FormControl("");
    this.useSpecialInfo = new FormControl("");
    this.collaboratorsForm = new FormGroup(
      {
        personalFirstName: this.personalFirstName,
        personalSecondName: this.personalSecondName,
        personalLastName: this.personalLastName,
        personalSecondLastName: this.personalSecondLastName,
        personalIdentification: this.personalIdentification,
        personalIdentificationType: this.personalIdentificationType,
        personalIdentificationFrom: this.personalIdentificationFrom,
        personalIdentificationFromCity: this.personalIdentificationFromCity,
        personalBloodType: this.personalBloodType,
        personalBloodTypeTwo: this.personalBloodTypeTwo,
        personalIdentificationExpeditionDate: this.personalIdentificationExpeditionDate,
        personalBirthDateString : this.personalBirthDateString,
        contactPhone: this.contactPhone,
        contactEmail: this.contactEmail,
        contactAddress: this.contactAddress,
        personalContactFromCity: this.personalContactFromCity,
        personalContactFrom: this.personalContactFrom,
        laborCCF: this.laborCCF,
        laborARL: this.laborARL,
        laborEPS: this.laborEPS,
        laborIPS: this.laborIPS,
        laborFPS: this.laborFPS,
        laborCES: this.laborCES,
        laborSalary: this.laborSalary,
        laborRole: this.laborRole,
        useSpecialInfo: this.useSpecialInfo,
        activeFC: this.activeFC
      }
    );
    if (this.isNew) {
      this.personalIdentificationFromCity.disable();
      this.personalContactFromCity.disable();
    }
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.collaboratorsForm.controls[controlName].hasError(errorName);
  }

  loadData(): void {
    if (this.original) {
      this.personalFirstName.setValue(this.original.personalFirstName);
      this.personalSecondName.setValue(this.original.personalSecondName);
      this.personalLastName.setValue(this.original.personalLastName);
      this.personalSecondLastName.setValue(this.original.personalSecondLastName);
      this.personalIdentification.setValue(this.original.personalIdentification);
      this.personalIdentificationType.setValue(this.original.personalIdentificationType);
      if (this.items && this.items.length > 0) {
        const ibdx = this.items.findIndex(x => x.departamento === this.original.contactDepartment);
        this.personalIdentificationFrom.setValue(this.items[ibdx]);
        this.cities = this.items[ibdx];
        this.personalIdentificationFromCity.setValue(this.original.personalIdentificationFromCity);
      }
      this.personalBloodType.setValue(this.original.personalBloodType);
      this.personalBloodTypeTwo.setValue(this.original.personalBloodTypeRH);
      this.personalBirthDateString.setValue(moment(this.original.personalBirthDateString, "DD/MM/YYYY"));
      this.contactPhone.setValue(this.original.contactPhone);
      this.contactEmail.setValue(this.original.contactEmail);
      this.contactAddress.setValue(this.original.contactAddress);
      if (this.items && this.items.length > 0) {
        const ibdx = this.items.findIndex(x => x.departamento === this.original.contactDepartment);
        this.personalContactFrom.setValue(this.items[ibdx]);
        this.citiesContact = this.items[ibdx];
        this.personalContactFromCity.setValue(this.original.contactCity);
      }
      this.laborCCF.setValue(this.original.laborCCF);
      this.laborARL.setValue(this.original.laborARL);
      this.laborEPS.setValue(this.original.laborEPS);
      this.laborIPS.setValue(this.original.laborIPS);
      this.laborFPS.setValue(this.original.laborFPS);
      this.laborCES.setValue(this.original.laborCES);
      this.laborSalary.setValue(this.original.laborSalary);
      this.laborRole.setValue(this.original.laborRole);
      this.useSpecialInfo.setValue(this.original.useSpecialInfo);
      if (this.original.specialInfoItems && this.original.specialInfoItems.length > 0) {
        this.specialInfoObjects = this.original.specialInfoItems;
      } else {
        this.specialInfoObjects = [];
      }
    }
  }

  save(): void{
    if (this.isNew){
      this.current = new CollaboratorObject();
    }else {
      this.current = this.original;
    }
    this.current.personalFirstName = this.personalFirstName.value;
    this.current.personalSecondName = this.personalSecondName.value;
    this.current.personalLastName = this.personalLastName.value;
    this.current.personalSecondLastName = this.personalSecondLastName.value;
    this.current.personalIdentificationType = this.personalIdentificationType.value;
    this.current.personalIdentification = this.personalIdentification.value;
    this.current.personalIdentificationFrom = this.personalIdentificationFrom.value.departamento;
    this.current.personalIdentificationFromCity = this.personalIdentificationFromCity.value;
    this.current.personalBloodType = this.personalBloodType.value;
    this.current.personalBloodTypeRH = this.personalBloodTypeTwo.value;
    this.current.personalBirthDateString = this.personalBirthDateString.value.format("DD/MM/YYYY");
    this.current.contactPhone = this.contactPhone.value;
    this.current.contactEmail = this.contactEmail.value;
    this.current.contactAddress = this.contactAddress.value;
    this.current.contactCity = this.personalContactFromCity.value;
    this.current.contactDepartment = this.personalContactFrom.value.departamento;
    this.current.laborCCF = this.laborCCF.value;
    this.current.laborARL = this.laborARL.value;
    this.current.laborEPS = this.laborEPS.value;
    this.current.laborIPS = this.laborIPS.value;
    this.current.laborFPS = this.laborFPS.value;
    this.current.laborCES = this.laborCES.value;
    this.current.laborSalary = this.laborSalary.value;
    this.current.laborRole = this.laborRole.value;
    this.current.useSpecialInfo = this.useSpecialInfo.value;
    this.current.specialInfoItems = this.specialInfoObjects;
    this.current.active = this.activeFC.value;
    this.current.deleted = false;
    if (this.isNew){
      this.current.logs = [];
      this.current.logs.push(new LogObject(moment().unix(), this.ADD_EVENT, LogEventKindEnum.CREATE, this.CURRENT_USER));
      this.collaboratorsService.addCollaboratorsTransaction(this.current).subscribe(
        next => {
          this.matDialogRef.close();
        }
      );
    } else {
      const events: string [] = [];
      const title: string = this.EDIT_EVENT + "<br>";
      events.push(title);
      if (this.current.personalFirstName !== this.original.personalFirstName) {
        events.push(`Nombre: ${this.original.personalFirstName} => ${this.current.personalFirstName}` + "<br>");
      }
      if (this.current.personalSecondName !== this.original.personalSecondName) {
        events.push(`Segundo Nombre: ${this.original.personalSecondName} => ${this.current.personalSecondName}` + "<br>");
      }
      if (this.current.personalLastName !== this.original.personalLastName) {
        events.push(`Primer Apellido: ${this.original.personalLastName} => ${this.current.personalLastName}` + "<br>");
      }
      if (this.current.personalSecondLastName !== this.original.personalSecondLastName) {
        events.push(`Segundo Apellido: ${this.original.personalSecondLastName} => ${this.current.personalSecondLastName}` + "<br>");
      }
      if (this.current.personalIdentificationType !== this.original.personalIdentificationType) {
        events
          .push(`Tipo de documento: ${this.original.personalIdentificationType} => ${this.current.personalIdentificationType}` + "<br>");
      }
      if (this.current.personalIdentification !== this.original.personalIdentification) {
        events.push(`Documento: ${this.original.personalIdentification} => ${this.current.personalIdentification}` + "<br>");
      }
      if (this.current.personalIdentificationFrom !== this.original.personalIdentificationFrom) {
        events
          .push(`Lugar de Expedicion (Departamento): ${this.original.personalIdentificationFrom} => ${this.current.personalIdentificationFrom}` + "<br>");
      }
      if (this.current.personalIdentificationFromCity !== this.original.personalIdentificationFromCity) {
        events.push(`Lugar de Expedicion (Ciudad): ${this.original.personalIdentificationFromCity} => ${this.current.personalIdentificationFromCity}` + "<br>");
      }
      if (this.current.personalBloodType !== this.original.personalBloodType) {
        events.push(`Tipo Sanguíneo ABO: ${this.original.personalBloodType} => ${this.current.personalBloodType}` + "<br>");
      }
      if (this.current.personalBloodTypeRH !== this.original.personalBloodTypeRH) {
        events.push(`Tipo Sanguíneo RH: ${this.original.personalBloodTypeRH} => ${this.current.personalBloodTypeRH}` + "<br>");
      }
      if (this.current.personalBirthDateString !== this.original.personalBirthDateString) {
        events.push(`Fecha de Nacimineto: ${this.original.personalBirthDateString} => ${this.current.personalBirthDateString}` + "<br>");
      }
      if (this.current.contactPhone !== this.original.contactPhone) {
        events.push(`Teléfono: ${this.original.contactPhone} => ${this.current.contactPhone}` + "<br>");
      }
      if (this.current.contactEmail !== this.original.contactEmail) {
        events.push(`Correo Electrónico: ${this.original.contactEmail} => ${this.current.contactEmail}` + "<br>");
      }
      if (this.current.contactAddress !== this.original.contactAddress) {
        events.push(`Dirección: ${this.original.contactAddress} => ${this.current.contactAddress}` + "<br>");
      }
      if (this.current.contactCity !== this.original.contactCity) {
        events.push(`Ciudad: ${this.original.contactCity} => ${this.current.contactCity}` + "<br>");
      }
      if (this.current.contactDepartment !== this.original.contactDepartment) {
        events.push(`Departamento: ${this.original.contactDepartment} => ${this.current.contactDepartment}` + "<br>");
      }
      if (this.current.laborCCF !== this.original.laborCCF) {
        events.push(`caja de compensación: ${this.original.laborCCF} => ${this.current.laborCCF}` + "<br>");
      }
      if (this.current.laborARL !== this.original.laborARL) {
        events.push(`ARL: ${this.original.laborARL} => ${this.current.laborARL}` + "<br>");
      }
      if (this.current.laborEPS !== this.original.laborEPS) {
        events.push(`EPS: ${this.original.laborEPS} => ${this.current.laborEPS}` + "<br>");
      }
      if (this.current.laborIPS !== this.original.laborIPS) {
        events.push(`IPS: ${this.original.laborIPS} => ${this.current.laborIPS}` + "<br>");
      }
      if (this.current.laborFPS !== this.original.laborFPS) {
        events.push(`Pensiones: ${this.original.laborFPS} => ${this.current.laborFPS}` + "<br>");
      }
      if (this.current.laborCES !== this.original.laborCES) {
        events.push(`Cesantías: ${this.original.laborCES} => ${this.current.laborCES}` + "<br>");
      }
      if (this.current.laborSalary !== this.original.laborSalary) {
        events.push(`salario: ${this.original.laborSalary} => ${this.current.laborSalary}` + "<br>");
      }
      if (this.current.laborRole !== this.original.laborRole) {
        events.push(`Cargo: ${this.original.laborRole} => ${this.current.laborRole}` + "<br>");
      }
      if (this.current.useSpecialInfo !== this.original.useSpecialInfo) {
        events.push(`Tiene información especial: ${this.original.useSpecialInfo} => ${this.current.useSpecialInfo}` + "<br>");
      }
      if (this.current.specialInfoItems.length !== this.original.specialInfoItems.length) {
        events.push(`Items Especiales (cantidad): ${this.original.specialInfoItems.length} => ${this.current.specialInfoItems.length}` + "<br>");
      }
      if (this.current.active !== this.original.active) {
        events.push(`Activo: ${this.original.active} => ${this.current.active}` + "<br>");
      }
      let eventText = "";
      console.log("events", events);
      if (events.length > 1) {
        for (const linea of events) {
          eventText += linea;
        }
        this.current.logs.push(new LogObject(moment().unix(), eventText, LogEventKindEnum.MODIFY, this.CURRENT_USER));
        this.collaboratorsService.updateCollaboratorTransaction(this.current.uid, this.current).subscribe(
          next => {
            this.matDialogRef.close();
          }
        );
      } else {
        this.matDialogRef.close();
      }
    }
  }

  closeDialog(): void {
    this.matDialogRef.close();
  }

  updateCities(event: any): void {
    console.log("event", event);
    if (event.value.ciudades && event.value.ciudades.length > 0) {
      this.cities = event.value.ciudades;
      this.personalIdentificationFromCity.enable();
    }
  }

  updateContactCities(event: any): void {
    console.log("event", event);
    if (event.value.ciudades && event.value.ciudades.length > 0) {
      this.citiesContact = event.value.ciudades;
      this.personalContactFromCity.enable();
    }
  }

  updateSpecialInfo(data: SpecialInfoObject[]): void {
    console.log("Recibí: ", data);
    if (this.current) {
      this.current.specialInfoItems = data;
    }
  }
}
