import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {from, Observable} from "rxjs";
import {UserInfoObject} from "../../core/shared/models/auth/userInfo.object";
import {QueryObject} from "../../core/shared/models/common/query.object";
import {AngularFireStorage} from "@angular/fire/storage";
import {HttpClient} from "@angular/common/http";
import {AngularFireFunctions} from "@angular/fire/functions";
import {UserKindEnum} from "../../core/shared/models/auth/enums/userKind.enum";
import {UserKindObject} from "../../core/shared/models/auth/UserKind.object";
import {UserKindTranslationEnum} from "../../core/shared/models/auth/enums/userKindTranslation.enum";
import {ProjectInUserObject} from "../../core/shared/models/company/projectInUser.object";
import {UserRoleInProjectObject} from "../../core/shared/models/auth/userRoleInProject.object";
import {resultMemoize} from "@ngrx/store";

@Injectable({
  providedIn: "root"
})
export class UsersService {

  private readonly USERS_COLLECTION = "Usuarios";
  private readonly DOC_TYPES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/public/document-types.json";

  constructor(private angularFirestore: AngularFirestore, private angularFireStorage: AngularFireStorage,
              private httpClient: HttpClient, private angularFireFunctions: AngularFireFunctions) {}

  getDocumentTypes(): Observable<any> {
    console.log("Getting documents");
    const location = this.angularFireStorage.storage.refFromURL(this.DOC_TYPES_LOCATION);
    return from(location.getDownloadURL());
  }

  getDocumentTypesFromURL(url: string): Observable<any> {
    return from(this.httpClient.get(url));
  }

  addUser(item: UserInfoObject): Observable<any> {
    const projects = Object.assign([], item.projects);
    const globalRoles = Object.assign([], item.globalRoles);
    const theUser = Object.assign({}, item);
    return from(this.angularFirestore.collection<UserInfoObject>(this.USERS_COLLECTION).add(theUser));
  }

  editItem(itemId: string, item: UserInfoObject): Observable<any> {
    return  from(this.angularFirestore.collection<UserInfoObject>(this.USERS_COLLECTION).doc(itemId).set(Object.assign({}, item)));
  }

  loadUsers(): Observable<UserInfoObject[]> {
    return this.angularFirestore.collection<UserInfoObject>(this.USERS_COLLECTION).valueChanges({idField: "userID"});
  }

  loadUsersByFilter(queryObject: QueryObject): Observable<UserInfoObject[]> {
    console.log("query", queryObject);
    return this.angularFirestore.collection<UserInfoObject>(this.USERS_COLLECTION, ref => ref
      .where(queryObject.field, "==", queryObject.value)).valueChanges();
  }

  createUserFromFunction(userToCreate: UserInfoObject): Observable<any> {
    const theGlobalRoles = Object.assign([], userToCreate.globalRoles);
    const theProjects = Object.assign([], userToCreate.projects);
    const theLogs = Object.assign([], userToCreate.logs);
    const theOriginalUser = new UserInfoObject(userToCreate);
    const theUser = Object.assign({}, userToCreate);
    theUser.globalRoles = theGlobalRoles;
    theUser.projects = theProjects;
    theUser.logs = theLogs;
    const addUser =  this.angularFireFunctions.httpsCallable("addUserCallable");
    console.log("function", addUser);
    return addUser({user: theUser});
  }

  userKindToTranslate(u: UserKindEnum): UserKindTranslationEnum {
    switch (u) {
      case (UserKindEnum.REJECTED):
        return UserKindTranslationEnum.REJECTED;
      case (UserKindEnum.NOT_VERIFIED):
        return UserKindTranslationEnum.NOT_VERIFIED;
      case (UserKindEnum.NOT_ASSIGNED):
        return UserKindTranslationEnum.NOT_ASSIGNED;
      case (UserKindEnum.PERSON_ACCESS_READER):
        return UserKindTranslationEnum.PERSON_ACCESS_READER;
      case (UserKindEnum.PERSON_ACCESS_OPERATOR):
        return UserKindTranslationEnum.PERSON_ACCESS_OPERATOR;
      case (UserKindEnum.PERSON_ACCESS_WRITER):
        return UserKindTranslationEnum.PERSON_ACCESS_WRITER;
      case (UserKindEnum.PERSON_ACCESS_ADMIN):
        return UserKindTranslationEnum.PERSON_ACCESS_ADMIN;
      case (UserKindEnum.VEHICLE_ACCESS_READER):
        return UserKindTranslationEnum.VEHICLE_ACCESS_READER;
      case (UserKindEnum.VEHICLE_ACCESS_OPERATOR):
        return UserKindTranslationEnum.VEHICLE_ACCESS_OPERATOR;
      case (UserKindEnum.VEHICLE_ACCESS_WRITER):
        return UserKindTranslationEnum.VEHICLE_ACCESS_WRITER;
      case (UserKindEnum.VEHICLE_ACCESS_ADMIN):
        return UserKindTranslationEnum.VEHICLE_ACCESS_ADMIN;
      case (UserKindEnum.INVENTORY_READER):
        return UserKindTranslationEnum.INVENTORY_READER;
      case (UserKindEnum.INVENTORY_OPERATOR):
        return UserKindTranslationEnum.INVENTORY_OPERATOR;
      case (UserKindEnum.INVENTORY_REQUEST):
        return UserKindTranslationEnum.INVENTORY_REQUEST;
      case (UserKindEnum.INVENTORY_WRITER):
        return UserKindTranslationEnum.INVENTORY_WRITER;
      case (UserKindEnum.INVENTORY_ADMIN):
        return UserKindTranslationEnum.INVENTORY_ADMIN;
      case (UserKindEnum.ADMIN_READER):
        return UserKindTranslationEnum.ADMIN_READER;
      case (UserKindEnum.ADMIN_WRITER):
        return UserKindTranslationEnum.ADMIN_WRITER;
      case (UserKindEnum.ADMIN_ADMIN):
        return UserKindTranslationEnum.ADMIN_ADMIN;
      case (UserKindEnum.SUPERADMIN_READER):
        return UserKindTranslationEnum.SUPERADMIN_READER;
      case (UserKindEnum.SUPERADMIN_WRITER):
        return UserKindTranslationEnum.SUPERADMIN_WRITER;
      case (UserKindEnum.SUPERADMIN_ADMIN):
        return UserKindTranslationEnum.SUPERADMIN_ADMIN;
      default:
        return UserKindTranslationEnum.REJECTED;
    }
  }

  userKindTranslateToKind(u: UserKindTranslationEnum): UserKindEnum {
    switch (u) {
      case (UserKindTranslationEnum.REJECTED):
        return UserKindEnum.REJECTED;
      case (UserKindTranslationEnum.NOT_VERIFIED):
        return UserKindEnum.NOT_VERIFIED;
      case (UserKindTranslationEnum.NOT_ASSIGNED):
        return UserKindEnum.NOT_ASSIGNED;
      case (UserKindTranslationEnum.PERSON_ACCESS_READER):
        return UserKindEnum.PERSON_ACCESS_READER;
      case (UserKindTranslationEnum.PERSON_ACCESS_OPERATOR):
        return UserKindEnum.PERSON_ACCESS_OPERATOR;
      case (UserKindTranslationEnum.PERSON_ACCESS_WRITER):
        return UserKindEnum.PERSON_ACCESS_WRITER;
      case (UserKindTranslationEnum.PERSON_ACCESS_ADMIN):
        return UserKindEnum.PERSON_ACCESS_ADMIN;
      case (UserKindTranslationEnum.VEHICLE_ACCESS_READER):
        return UserKindEnum.VEHICLE_ACCESS_READER;
      case (UserKindTranslationEnum.VEHICLE_ACCESS_OPERATOR):
        return UserKindEnum.VEHICLE_ACCESS_OPERATOR;
      case (UserKindTranslationEnum.VEHICLE_ACCESS_WRITER):
        return UserKindEnum.VEHICLE_ACCESS_WRITER;
      case (UserKindTranslationEnum.VEHICLE_ACCESS_ADMIN):
        return UserKindEnum.VEHICLE_ACCESS_ADMIN;
      case (UserKindTranslationEnum.INVENTORY_READER):
        return UserKindEnum.INVENTORY_READER;
      case (UserKindTranslationEnum.INVENTORY_OPERATOR):
        return UserKindEnum.INVENTORY_OPERATOR;
      case (UserKindTranslationEnum.INVENTORY_REQUEST):
        return UserKindEnum.INVENTORY_REQUEST;
      case (UserKindTranslationEnum.INVENTORY_WRITER):
        return UserKindEnum.INVENTORY_WRITER;
      case (UserKindTranslationEnum.INVENTORY_ADMIN):
        return UserKindEnum.INVENTORY_ADMIN;
      case (UserKindTranslationEnum.ADMIN_READER):
        return UserKindEnum.ADMIN_READER;
      case (UserKindTranslationEnum.ADMIN_WRITER):
        return UserKindEnum.ADMIN_WRITER;
      case (UserKindTranslationEnum.ADMIN_ADMIN):
        return UserKindEnum.ADMIN_ADMIN;
      case (UserKindTranslationEnum.SUPERADMIN_READER):
        return UserKindEnum.SUPERADMIN_READER;
      case (UserKindTranslationEnum.SUPERADMIN_WRITER):
        return UserKindEnum.SUPERADMIN_WRITER;
      case (UserKindTranslationEnum.SUPERADMIN_ADMIN):
        return UserKindEnum.SUPERADMIN_ADMIN;
      default:
        return UserKindEnum.REJECTED;
    }
  }

  userKindEnumsToObjects(uke: UserKindEnum[]): UserKindObject[] {
    const result: UserKindObject[] = [];
    if (uke.length > 0) {
      for (const uk of uke) {
        switch (uk) {
          case UserKindEnum.REJECTED:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.REJECTED));
            break;
          case UserKindEnum.NOT_VERIFIED:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.NOT_VERIFIED));
            break;
          case UserKindEnum.NOT_ASSIGNED:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.NOT_ASSIGNED));
            break;
          case UserKindEnum.PERSON_ACCESS_READER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.PERSON_ACCESS_READER));
            break;
          case UserKindEnum.PERSON_ACCESS_OPERATOR:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.PERSON_ACCESS_OPERATOR));
            break;
          case UserKindEnum.PERSON_ACCESS_WRITER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.PERSON_ACCESS_WRITER));
            break;
          case UserKindEnum.PERSON_ACCESS_ADMIN:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.PERSON_ACCESS_ADMIN));
            break;
          case UserKindEnum.VEHICLE_ACCESS_READER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.VEHICLE_ACCESS_READER));
            break;
          case UserKindEnum.VEHICLE_ACCESS_OPERATOR:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.VEHICLE_ACCESS_OPERATOR));
            break;
          case UserKindEnum.VEHICLE_ACCESS_WRITER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.VEHICLE_ACCESS_WRITER));
            break;
          case UserKindEnum.VEHICLE_ACCESS_ADMIN:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.VEHICLE_ACCESS_ADMIN));
            break;
          case UserKindEnum.INVENTORY_READER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_READER));
            break;
          case UserKindEnum.INVENTORY_OPERATOR:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_OPERATOR));
            break;
          case UserKindEnum.INVENTORY_REQUEST:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_REQUEST));
            break;
          case UserKindEnum.INVENTORY_WRITER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_WRITER));
            break;
          case UserKindEnum.INVENTORY_ADMIN:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_ADMIN));
            break;
          case UserKindEnum.ADMIN_READER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.ADMIN_READER));
            break;
          case UserKindEnum.ADMIN_WRITER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.ADMIN_WRITER));
            break;
          case UserKindEnum.ADMIN_ADMIN:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.ADMIN_ADMIN));
            break;
          case UserKindEnum.SUPERADMIN_READER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.SUPERADMIN_READER));
            break;
          case UserKindEnum.SUPERADMIN_WRITER:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.SUPERADMIN_WRITER));
            break;
          case UserKindEnum.SUPERADMIN_ADMIN:
            result.push(new UserKindObject(null, uk, UserKindTranslationEnum.SUPERADMIN_ADMIN));
            break;
          default:
            break;
        }
      }
    }
    return result;
  }

  userKindObjectToUserKindEnum(uko: UserKindObject[]): UserKindEnum[] {
    const result: UserKindEnum[] = [];
    if (uko && uko.length > 0) {
      for (const item of uko) {
        result.push(item.userKindEnum);
      }
    }
    return  result;
  }

  userKindEnumsToSingleObject(uk: number): UserKindObject {
    switch (uk) {
      case UserKindEnum.REJECTED:
        return new UserKindObject(null, uk, UserKindTranslationEnum.REJECTED);
      case UserKindEnum.NOT_VERIFIED:
        return new UserKindObject(null, uk, UserKindTranslationEnum.NOT_VERIFIED);
      case UserKindEnum.NOT_ASSIGNED:
        return new UserKindObject(null, uk, UserKindTranslationEnum.NOT_ASSIGNED);
      case UserKindEnum.PERSON_ACCESS_READER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.PERSON_ACCESS_READER);
      case UserKindEnum.PERSON_ACCESS_OPERATOR:
        return new UserKindObject(null, uk, UserKindTranslationEnum.PERSON_ACCESS_OPERATOR);
      case UserKindEnum.PERSON_ACCESS_WRITER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.PERSON_ACCESS_WRITER);
      case UserKindEnum.PERSON_ACCESS_ADMIN:
        return new UserKindObject(null, uk, UserKindTranslationEnum.PERSON_ACCESS_ADMIN);
      case UserKindEnum.VEHICLE_ACCESS_READER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.VEHICLE_ACCESS_READER);
      case UserKindEnum.VEHICLE_ACCESS_OPERATOR:
        return new UserKindObject(null, uk, UserKindTranslationEnum.VEHICLE_ACCESS_OPERATOR);
      case UserKindEnum.VEHICLE_ACCESS_WRITER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.VEHICLE_ACCESS_WRITER);
      case UserKindEnum.VEHICLE_ACCESS_ADMIN:
        return new UserKindObject(null, uk, UserKindTranslationEnum.VEHICLE_ACCESS_ADMIN);
      case UserKindEnum.INVENTORY_READER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_READER);
      case UserKindEnum.INVENTORY_OPERATOR:
        return new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_OPERATOR);
      case UserKindEnum.INVENTORY_REQUEST:
        return new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_REQUEST);
      case UserKindEnum.INVENTORY_WRITER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_WRITER);
      case UserKindEnum.INVENTORY_ADMIN:
        return new UserKindObject(null, uk, UserKindTranslationEnum.INVENTORY_ADMIN);
      case UserKindEnum.ADMIN_READER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.ADMIN_READER);
      case UserKindEnum.ADMIN_WRITER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.ADMIN_WRITER);
      case UserKindEnum.ADMIN_ADMIN:
        return new UserKindObject(null, uk, UserKindTranslationEnum.ADMIN_ADMIN);
      case UserKindEnum.SUPERADMIN_READER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.SUPERADMIN_READER);
      case UserKindEnum.SUPERADMIN_WRITER:
        return new UserKindObject(null, uk, UserKindTranslationEnum.SUPERADMIN_WRITER);
      case UserKindEnum.SUPERADMIN_ADMIN:
        return new UserKindObject(null, uk, UserKindTranslationEnum.SUPERADMIN_ADMIN);
      default:
        return new UserKindObject(null, uk, UserKindTranslationEnum.REJECTED);
    }
  }

  userRoleComparer(until: number, selected: UserKindObject[], total: UserKindObject[]): UserKindObject[] {
    let counter = 10;
    let floorCounter = 0;
    const floors = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190];
    let found = false;
    if (selected.length > 0) {
      while (counter <= until) {
        if ((counter - 10) < floors[floorCounter]) {
          if (!found) {
            for (const item of selected){
              if (item.value === counter) {
                found = true;
                total.splice(total.findIndex(x => x.value === item.value), 1);
                counter = floors[floorCounter];
              }
            }
          }
          for (const totalItem of total) {
            if (found) {
              if (totalItem.value === counter) {
                total.splice(total.findIndex(x => x.value === totalItem.value), 1);
              }
            }
          }
          counter++;
        } else {
          if (!found) {
            floorCounter ++;
          } else {
            found = false;
          }
        }
      }
    }
    return total;
  }

  projectInUserToUserKRoleInProject(piu: ProjectInUserObject): UserRoleInProjectObject[] {
    const result: UserRoleInProjectObject[] = [];
    if (piu && piu.roles && piu.roles.length > 0) {
      for (const rol of piu.roles) {
        const urpo: UserRoleInProjectObject = new UserRoleInProjectObject();
        urpo.projectName = piu.projectName;
        urpo.projectUID = piu.projectID;
        urpo.role = this.userKindEnumsToSingleObject(rol);
        result.push(urpo);
      }
    }
    return result;
  }

  projectInUserArrayToUserKRoleInProject(pius: ProjectInUserObject[]): UserRoleInProjectObject[] {
    const result: UserRoleInProjectObject[] = [];
    if (pius && pius.length > 0) {
      for ( const piu of pius) {
        if (piu && piu.roles && piu.roles.length > 0) {
          for (const rol of piu.roles) {
            const urpo: UserRoleInProjectObject = new UserRoleInProjectObject();
            urpo.projectName = piu.projectName;
            urpo.projectUID = piu.projectID;
            urpo.role = this.userKindEnumsToSingleObject(rol);
            result.push(urpo);
          }
        }
      }
    }
    return result;
  }

  userRolesInProjectsToProjectsInUser(urpos: UserRoleInProjectObject[]): ProjectInUserObject[] {
    const result: ProjectInUserObject[] = [];
    if (urpos && urpos.length > 0) {
      for (const urpo of urpos) {
        console.log(urpo.projectUID);
        let found = false;
        if (result.length > 0) {
          for (const res of result) {
            if (res.projectID === urpo.projectUID) {
              found = true;
              if (!res.roles){
                res.roles = [];
              }
              res.roles.push(urpo.role.userKindEnum);
              break;
            }
          }
        }
        if (!found) {
          console.log("Not Found", urpo);
          const item: ProjectInUserObject = new ProjectInUserObject();
          item.projectID = urpo.projectUID;
          item.projectName = urpo.projectName;
          item.roles = [];
          item.roles.push(urpo.role.userKindEnum);
          result.push(item);
        }
      }
    }
    return result;
  }

}
