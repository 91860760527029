import {LogObject} from "../common/log.object";

export class CompanyObject {
  uid: string;
  name: string;
  companyID: string; // NIT
  contactEmail: string;
  contactPhone: string;
  businessAddress: string;
  businessCity: string;
  businessDepartment: string;
  limit: number;
  enabled: boolean;
  deleted: boolean;
  logs: LogObject[];

  constructor(c?: CompanyObject) {
    if (c) {
      if (c.uid) {
        this.uid = c.uid;
      }
      this.name = c.name;
      this.companyID = c.companyID;
      this.contactEmail = c.contactEmail;
      this.contactPhone = c.contactPhone;
      this.businessAddress = c.businessAddress;
      this.businessCity = c.businessCity;
      this.businessDepartment = c.businessDepartment;
      this.limit = c.limit;
      this.enabled = c.enabled;
      this.deleted = c.deleted;
      this.logs = c.logs;
    } else {
      this.logs = [];
      this.enabled = true;
      this.deleted = false;
      this.limit = 0;
    }
  }
}
