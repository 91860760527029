<div *ngIf="showNoData" fxLayoutAlign="center center" fxFlex class="first-line-admin">
  <h5 class="card-sub-title-h4">{{txtNoData}}</h5>
</div>
<div *ngIf="!showNoData" fxFlex>
  <table mat-table [dataSource]="tableDataSource" class="table-size">
    <ng-container matColumnDef="{{txtPlate}}">
      <th mat-header-cell *matHeaderCellDef> {{txtPlate | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{item.plate}} </td>
    </ng-container>
    <ng-container matColumnDef="{{txtInfo}}">
      <th mat-header-cell *matHeaderCellDef> {{txtInfo | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{item.reason}} </td>
    </ng-container>
    <ng-container matColumnDef="{{txtAlert}}">
      <th mat-header-cell *matHeaderCellDef> {{txtAlert | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> <mat-icon *ngIf="item.showWarning">warning</mat-icon> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dc"></tr>
    <tr mat-row *matRowDef="let row; columns: dc;"></tr>
  </table>
</div>
