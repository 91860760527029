import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InventoryListComponent } from "./components/inventory-list/inventory-list.component";
import { InventoryAddEditComponent } from "./components/inventory-add-edit/inventory-add-edit.component";
import { InventoryTransactionInComponent } from "./components/inventory-transaction-in/inventory-transaction-in.component";
import { InventoryTransactionOutComponent } from "./components/inventory-transaction-out/inventory-transaction-out.component";
import { InventoryTransactionOtherComponent } from "./components/inventory-transaction-other/inventory-transaction-other.component";
import {CoreModule} from "../core/core.module";



@NgModule({
  declarations: [InventoryListComponent, InventoryAddEditComponent, InventoryTransactionInComponent,
    InventoryTransactionOutComponent, InventoryTransactionOtherComponent],
  imports: [
    CommonModule,
    CoreModule,
  ]
})
// @ts-ignore
export class InventoryModule { }
