<div mat-dialog-title>
  <div fxLayout="row">
    <mat-icon>{{txtItemIcon}}</mat-icon>
    <h3 class="card-title-h3">{{dialogTitle}}</h3>
  </div>
</div>
<form [formGroup]="specialItemGroup" (ngSubmit)="saveItem(specialItemGroup.getRawValue())">
  <div mat-dialog-content class="dialog-size" ngClass.lt-sm="dialog-size-small">
    <div fxLayout="column">
      <div fxLayout="column">
        <mat-form-field appearance="fill" fxFlex="100" class="first-line item">
          <mat-label>{{txtName}}</mat-label>
          <input matInput [formControl]="nameControl" required>
          <mat-error *ngIf="hasError('nameControl', 'required')">
            {{txtNameRequiredError}}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50"  class="item">
          <mat-label>{{txtFrom}}</mat-label>
          <input matInput [formControl]="fromControl" [matDatepicker]="fromPicker">
          <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
          <mat-datepicker #fromPicker startView="multi-year"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50" class="item">
          <mat-label>{{txtTo}}</mat-label>
          <input matInput [formControl]="toControl" required [matDatepicker]="toPicker">
          <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
          <mat-datepicker #toPicker startView="multi-year"></mat-datepicker>
          <mat-error *ngIf="hasError('toControl', 'required')">
          {{txtToRequiredError}}
        </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column">
        <mat-form-field appearance="fill" fxFlex.lt-sm="11" fxFlex="50" class="item">
          <mat-label>{{txtUrl}}</mat-label>
          <input matInput [formControl]="urlControl" required>
          <mat-error *ngIf="hasError('urlControl', 'required')">
            {{txtRequiredUrl}}
          </mat-error>
        </mat-form-field>
        <div fxFlex="50" class="item" ngClass.lt-sm="last-item"  fxLayoutAlign="center center">
          <button class="common-button inner-button" fxFlex  mat-raised-button type="button" (click)="fileUpload.click()">
            <mat-icon>{{txtAddImageIcon}}</mat-icon>{{txtAddImage|uppercase}}
            <input #fileUpload type="file" (change)="onFileSelected($event)" style="display: none">
          </button>
          <button class="common-button inner-button" fxFlex  mat-raised-button type="button"><mat-icon>{{txtPreviewImageIcon}}</mat-icon>{{txtPreviewImage|uppercase}}</button>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center" class="item">
    <button mat-raised-button color="accent" class="button-class" type="button" (click)="closeDialog()">{{txtCancel|uppercase}}</button>
    <button mat-raised-button color="primary" class="button-class" [disabled]="specialItemGroup.invalid" type="submit">{{txtAccept|uppercase}}</button>
  </div>
</form>
