import {Component, OnDestroy, OnInit} from "@angular/core";
import {AuthService} from "../../../../auth/shared/auth.service";
import {ManagementListGroupObject} from "../../../../core/shared/models/management/managementListGroup.object";
import {ManagementItemObject} from "../../../../core/shared/models/management/managementItem.object";
import {AccessQueryObject} from "../../../../core/shared/models/access-general/accessQuery.object";
import {PersonsAccessService} from "../../../shared/persons-access.service";
import {UserInfoObject} from "../../../../core/shared/models/auth/userInfo.object";
import {Subscription} from "rxjs";
import {AccessTurnObject} from "../../../../core/shared/models/access-general/accessTurn.object";

@Component({
  selector: "app-admin-access",
  templateUrl: "./admin-access.component.html",
  styleUrls: ["./admin-access.component.scss"]
})
export class AdminAccessComponent implements OnInit, OnDestroy {

  txtIcon: string;
  cardTitle: string;
  showBar: boolean;
  txtPersonalAccessIcon: string;
  txtPersonalAccessLabel: string;
  txtVehicleAccessIcon: string;
  txtVehicleAccessLabel: string;
  currentUserSubscription: Subscription;
  dataSubscription: Subscription;
  theQueryData: AccessQueryObject;
  currentUser: UserInfoObject;
  theLoadedData: AccessTurnObject[] = [];

  constructor(private authService: AuthService, private personsAccessService: PersonsAccessService) { }

  ngOnInit(): void {
    this.initTexts();
    this.showBar = false;
    this.currentUser = this.authService.getCurrentUserInfo();
    this.currentUserSubscription = this.authService.theCurrentUserInfoSubject.asObservable().subscribe(
      next => this.currentUser = next
    );
  }

  ngOnDestroy(): void {
    if (this.currentUserSubscription) {
      this.currentUserSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  getQueryInfo(data: AccessQueryObject): void {
    this.theQueryData = data;
    let theUID: string;
    console.log("DataToQuery", this.theQueryData);
    // TODO: implement admin with other users
    if (this.theQueryData.userID) {
      theUID = this.theQueryData.userID;
    } else {
      theUID = this.currentUser.userID;
    }
    this.dataSubscription = this.personsAccessService.getTurnsByDateRange(theUID, data.fromDate, data.toDate).subscribe(
      next => {
        this.theLoadedData = next;
        console.log("Data", next);
      },
      error => console.error(error)
    );
  }


  initTexts(): void {
    this.txtIcon = "list_alt";
    this.cardTitle = "Informes de Gestion";
    this.txtPersonalAccessIcon = "follow_the_signs";
    this.txtPersonalAccessLabel = "Acceso Personal";
    this.txtVehicleAccessIcon = "local_shipping";
    this.txtVehicleAccessLabel = "Acceso Vehicular";
  }

}
