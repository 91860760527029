<div mat-dialog-title fxLayout="row">
  <mat-icon>{{txtDialogIcon}}</mat-icon>
  <h3 class="card-title-h3">{{txtDialgoTitle}}</h3>
</div>
<div mat-dialog-content>
  <p class="dialog-content">
    {{txtDialogContent}}
  </p>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-raised-button class="common-button" (click)="close()" type="button">{{txtAccept|uppercase}}</button>
</div>
