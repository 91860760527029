import { Injectable } from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {AngularFireStorage} from "@angular/fire/storage";
import {from, Observable} from "rxjs";
import {VehicleTransactionObject} from "../../core/shared/models/access-vehicle/vehicleTransaction.object";
import {VehicleObject} from "../../core/shared/models/control/vehicle.object";

@Injectable({
  providedIn: "root"
})
export class VehiclesAccessService {

  private readonly DOC_TYPES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/public/document-types.json";
  private readonly COLOMBIA_CITIES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/public/toBePrivate/colombia.json";
  private readonly COLLECTION_NAME = "vehicles_transactions";
  private readonly VEHICLES_COLLECTION_NAME = "Vehicles";

  constructor(private angularFirestore: AngularFirestore, private angularFireStorage: AngularFireStorage) { }

  getDocumentTypes(): Observable<any> {
    const location = this.angularFireStorage.storage.refFromURL(this.DOC_TYPES_LOCATION);
    return from(location.getDownloadURL());
  }

  getColombiaLocations(): Observable<any> {
    const location = this.angularFireStorage.storage.refFromURL(this.COLOMBIA_CITIES_LOCATION);
    return from(location.getDownloadURL());
  }

  addVehicleTransaction(item: VehicleTransactionObject): Observable<any> {
    const theItem: any = Object.assign({}, item);
    return from(this.angularFirestore.collection<VehicleTransactionObject>(this.COLLECTION_NAME).add(theItem));
  }

  updateVehicleTransaction(itemId: string, item: VehicleTransactionObject): Observable<any> {
    const theItem: any = Object.assign({}, item);
    return from(this.angularFirestore.collection<VehicleTransactionObject>(this.COLLECTION_NAME)
      .doc(itemId).set(theItem, {merge: true}));
  }

  loadSingleVehiclesTransactions(plate: string, companyId: string): Observable<VehicleTransactionObject[]> {
    const thePLate: string = plate.toUpperCase();
    return from(this.angularFirestore.collection<VehicleTransactionObject>(this.COLLECTION_NAME, ref => ref
      .where("companyID", "==", companyId)
      .where("vehiclePlate", "==", thePLate)
      .where("isIn", "==", true)
      .limit(1)).valueChanges({idField: "uid"}));
  }

  addNewVehicle(item: VehicleObject): Observable<any> {
    const theItem: any = Object.assign({}, item);
    return from(this.angularFirestore.collection(this.VEHICLES_COLLECTION_NAME).add(theItem));
  }

  editVehicle(itemId: string, item: VehicleObject): Observable<any> {
    const theItem: any = Object.assign({}, item);
    return from(this.angularFirestore.collection(this.VEHICLES_COLLECTION_NAME).doc(itemId).set(theItem, {merge: true}));
  }

  loadVehicleByPlate(plate: string): Observable<VehicleObject[]> {
    const thePlate: string = plate.toUpperCase();
    return this.angularFirestore.collection<VehicleObject>(this.VEHICLES_COLLECTION_NAME, ref => ref
      .where("plate", "==", plate)
      .where("deleted", "==", false)).valueChanges({idField: "uid"});
  }
}
