import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {CoreModule} from "../core/core.module";
import { UsersListComponent } from "./components/users-list/users-list.component";
import { UsersAddEditComponent } from "./components/users-add-edit/users-add-edit.component";
import {UsersService} from "./shared/users.service";
import { UsersDetailsComponent } from "./components/users-details/users-details.component";
import { RolesListComponent } from "./components/inner/roles-list/roles-list.component";
import { RoleAddEditComponent } from "./components/inner/role-add-edit/role-add-edit.component";
import { UsersProjectsComponent } from "./components/inner/users-projects/users-projects.component";
import { UsersProjectsAddEditComponent } from "./components/inner/users-projects-add-edit/users-projects-add-edit.component";



@NgModule({
  declarations: [UsersListComponent, UsersAddEditComponent, UsersDetailsComponent, RolesListComponent,
    RoleAddEditComponent,
    UsersProjectsComponent,
    UsersProjectsAddEditComponent],
  imports: [
    CommonModule,
    CoreModule,
  ],
  providers: [UsersService, UsersDetailsComponent, RoleAddEditComponent, UsersProjectsAddEditComponent]
})
export class UsersModule { }
