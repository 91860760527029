import {RouterModule, Routes} from "@angular/router";
import {CollaboratorsListComponent} from "./collaborators/components/collaborators-list/collaborators-list.component";
import {CollaboratorsAddEditComponent} from "./collaborators/components/collaborators-add-edit/collaborators-add-edit.component";
import {VehiclesAccessListComponent} from "./vehicles-access/components/vehicles-access-list/vehicles-access-list.component";
import {VehiclesAccessAddEditComponent} from "./vehicles-access/components/vehicles-access-add-edit/vehicles-access-add-edit.component";
import {CollaboratorsDetailsComponent} from "./collaborators/components/collaborators-details/collaborators-details.component";
import {VehiclesAccessDetailsComponent} from "./vehicles-access/components/vehicles-access-details/vehicles-access-details.component";
import {PersonsAccessListComponent} from "./persons-access/components/persons-access-list/persons-access-list.component";
import {PersonsAccessAddEditComponent} from "./persons-access/components/persons-access-add-edit/persons-access-add-edit.component";
import {PersonsAccessDetailsComponent} from "./persons-access/components/persons-access-details/persons-access-details.component";
import {TempComponent} from "./auth/components/temp/temp.component";
import {DemoListComponent} from "./demo/components/demo-list/demo-list.component";
import {DemoAddEditComponent} from "./demo/components/demo-add-edit/demo-add-edit.component";
import {MainAreaComponent} from "./dashboard/components/main-area/main-area.component";
import {InventoryListComponent} from "./inventory/components/inventory-list/inventory-list.component";
import {InventoryAddEditComponent} from "./inventory/components/inventory-add-edit/inventory-add-edit.component";
import {InventoryTransactionInComponent} from "./inventory/components/inventory-transaction-in/inventory-transaction-in.component";
import {InventoryTransactionOutComponent} from "./inventory/components/inventory-transaction-out/inventory-transaction-out.component";
import {InventoryTransactionOtherComponent} from "./inventory/components/inventory-transaction-other/inventory-transaction-other.component";
import {UsersListComponent} from "./users/components/users-list/users-list.component";
import {LoginComponent} from "./auth/components/login/login.component";
import {ForgotComponent} from "./auth/components/forgot/forgot.component";
import {CompaniesListComponent} from "./company/components/companies-list/companies-list.component";
import {UserInfoObject} from "./core/shared/models/auth/userInfo.object";
import {UserInfoComponent} from "./auth/components/user-info/user-info.component";
import {PersonsAccessOverviewComponent} from "./persons-access/components/persons-access-overview/persons-access-overview.component";
import {AdminAccessComponent} from "./persons-access/components/admin/admin-access/admin-access.component";
import {AdminAccessListComponent} from "./persons-access/components/admin/admin-access-list/admin-access-list.component";
import {SearchPlatesComponent} from "./common-area/components/search-plates/search-plates.component";

const routes: Routes = [
  {path: "", component: LoginComponent},
  {path: "auth/forgotPassword", component: ForgotComponent},
  {path: "auth/userInfo", component: UserInfoComponent},
  {path: "dashboard", component: MainAreaComponent, children: [
      {path: "company/collaborators", component: CollaboratorsListComponent},
      {path: "company/collaborators/add-edit", component: CollaboratorsAddEditComponent},
      // Personal-access
      {path: "personal/overview", component: PersonsAccessOverviewComponent},
      {path: "personal/report", component: AdminAccessComponent},
      {path: "personal/resume", component: AdminAccessListComponent},
      // Vehicles-access
      {path: "vehicles/overview", component: PersonsAccessOverviewComponent},
      {path: "vehicles/report", component: AdminAccessComponent},
      {path: "vehicles/resume", component: AdminAccessListComponent},
      // Overall-access
      {path: "common-access/overview", component: PersonsAccessOverviewComponent},
      {path: "common-access/report", component: AdminAccessComponent},
      {path: "common-access/resume", component: AdminAccessListComponent},
      // Common
      {path: "common-area/search-plate", component: SearchPlatesComponent},
      // Settings
      {path: "settings/users", component: UsersListComponent},
      // Settings Kooguen
      {path: "settings/admin-companies", component: CompaniesListComponent},
    ]
  },
  {path: "collaborators/add-edit/:id", component: CollaboratorsAddEditComponent},
  {path: "collaborators/detail/:id", component: CollaboratorsDetailsComponent},
  {path: "vehicles-access/list", component: VehiclesAccessListComponent},
  {path: "vehicles-access/add-edit", component: VehiclesAccessAddEditComponent},
  {path: "vehicles-access/add-edit/:id", component: VehiclesAccessAddEditComponent},
  {path: "vehicles-access/detail/:id", component: VehiclesAccessDetailsComponent},
  {path: "personas-access/list", component: PersonsAccessListComponent},
  {path: "personas-access/add-edit", component: PersonsAccessAddEditComponent},
  {path: "personas-access/add-edit/:id", component: PersonsAccessAddEditComponent},
  {path: "personas-access/detail/:id", component: PersonsAccessDetailsComponent},
  {path: "inventory/list", component: InventoryListComponent},
  {path: "inventory/add-edit", component: InventoryAddEditComponent},
  {path: "inventory/add-edit/:id", component: InventoryAddEditComponent},
  {path: "inventory/transactions/in", component: InventoryTransactionInComponent},
  {path: "inventory/transactions/out", component: InventoryTransactionOutComponent},
  {path: "inventory/transactions/other", component: InventoryTransactionOtherComponent},
  {path: "demo/list", component: DemoListComponent},
  {path: "demo/add-edit", component: DemoAddEditComponent},
];

export const AppRoutingProviders: any[] = [];

export const AppRoutes: any = RouterModule.forRoot(routes, { useHash: true });
