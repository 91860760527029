<mat-toolbar color="primary">
  <button *ngIf="showTheMenuButton" (click)="toggleMenu()" mat-icon-button class="toolbar-buttons"><mat-icon>menu</mat-icon></button>
  <button *ngIf="!showTheMenuButton" (click)="toggleMenu()" mat-icon-button class="toolbar-buttons"><mat-icon>arrow_left</mat-icon></button>
  <div class="toolbar-company" fxLayout="column">
    <span class="toolbar_company_inner" [innerHTML]="txtInstitutionName"></span>
  </div>
  <span fxFlex></span>
  <div *ngIf="showUser" class="toolbar-user-info">{{txtUserPlusEmail}}</div>
  <!-- <div *ngIf="showUser" class="toolbar-user-info">{{currentUser.auth.username}} - {{currentUser.personalInformation.name}}</div> -->
  <button class="toolbar-buttons" [matMenuTriggerFor]="menu" mat-icon-button aria-label="Opciones de la cuenta">
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>{{txtProfileIcon}}</mat-icon>
      <span>{{txtProfile}}</span>
    </button>
    <button mat-menu-item (click)="logOut()">
      <mat-icon>{{txtLogoutIcon}}</mat-icon>
      <span>{{txtLogout}}</span>
    </button>
  </mat-menu>
</mat-toolbar>
