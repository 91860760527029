<div class="content-area" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <mat-card class="main-card card-settings" ngClass.lt-sm="main-card-small">
    <mat-card-header fxLayoutAlign="center center">
      <mat-icon>{{txtIcon}}</mat-icon>
      <h3 class="card-title-h3 card-title">{{cardTitle}}</h3>
      <span fxFlex></span>
    </mat-card-header>
    <mat-card-content>
      <div fxFlex fxLayout="column">
        <div fxLayoutAlign="center center">
          <div class="counter-title-main">
            {{txtAccessTitle}}
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column">
          <div fxFlex="50" fxFlex.lt-sm="100" fxLayout="column" fxLayoutAlign="center center">
            <div class="counter-title">
              {{txtPersonsAccess}}
            </div>
            <div class="counter-value">
              {{collaboratorsCounter}}
            </div>
          </div>
          <div fxFlex="50" fxFlex.lt-sm="100"  fxLayout="column" fxLayoutAlign="center center">
            <div class="counter-title">
              {{txtVehiclesAccess}}
            </div>
            <div class="counter-value">
              {{vehiclesCounter}}
            </div>
          </div>
          <div fxFlex="50" fxFlex.lt-sm="100"  fxLayout="column" fxLayoutAlign="center center">
            <div class="counter-title">
              {{txtVisitorsAccess}}
            </div>
            <div class="counter-value">
              {{visitorsCounter}}
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" class="first-line-admin">
          <div class="button-space" fxLayout.lt-sm="column" fxFlex="33" fxFlex.lt-sm="100" fxLayoutAlign="center center"
               fxLayoutAlign.lt-sm="center stretch" (click)="changeColorAndClick($event, 'collaboratorBtn')">
            <button #collaboratorsBtn class="common-button" mat-raised-button type="button"><mat-icon>account_box</mat-icon>
              {{txtCollaboratorsBtn|uppercase}}</button>
          </div>
          <div  class="button-space"  fxLayout.lt-sm="column" fxFlex="34" fxFlex.lt-sm="100" fxLayoutAlign="center center"
               fxLayoutAlign.lt-sm="center stretch"  (click)="changeColorAndClick($event, 'vehicleBtn')">
            <button #vehiclesBtn class="common-button" mat-raised-button type="button"><mat-icon>local_shipping</mat-icon>
              {{txtVehiclesBtn|uppercase}}</button>
          </div>
          <div  class="button-space"  fxLayout.lt-sm="column" fxFlex="33" fxFlex.lt-sm="100" fxLayoutAlign="center center"
               fxLayoutAlign.lt-sm="center stretch"  (click)="changeColorAndClick($event, 'visitBtn')">
            <button #visitorsBtn class="common-button" mat-raised-button type="button"><mat-icon>work</mat-icon>
              {{txtVisitorsBtn|uppercase}}</button>
          </div>
        </div>
        <div fxFlex>
          <mat-tab-group dynamicHeight="true" [selectedIndex]="selectMe" *ngIf="showTabs" class="tabs">
            <mat-tab>
              <app-admin-persons-list [isVisitorList]="false" [transactionData]="collaborators"
                                      [updateMyData]="collaboratorsSubject.asObservable()"></app-admin-persons-list>
            </mat-tab>
            <mat-tab>
              <app-vehicles-admin-access-list [transactionData]="vehicles"
                                              [updateMyData]="vehiclesSubject.asObservable()"></app-vehicles-admin-access-list>
            </mat-tab>
            <mat-tab>
              <app-admin-persons-list [isVisitorList]="true" [transactionData]="visitors"
                                      [updateMyData]="visitorsSubject.asObservable()"></app-admin-persons-list>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>

    </mat-card-content>
    <mat-card-footer *ngIf="showBar">
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
