export enum UserKindEnum {
  REJECTED = 5,
  NOT_VERIFIED = 7,
  NOT_ASSIGNED = 7,
  PERSON_ACCESS_READER = 10,
  PERSON_ACCESS_OPERATOR,
  PERSON_ACCESS_WRITER,
  PERSON_ACCESS_ADMIN,
  VEHICLE_ACCESS_READER = 20,
  VEHICLE_ACCESS_OPERATOR,
  VEHICLE_ACCESS_WRITER,
  VEHICLE_ACCESS_ADMIN,
  INVENTORY_READER = 30,
  INVENTORY_OPERATOR,
  INVENTORY_REQUEST,
  INVENTORY_WRITER,
  INVENTORY_ADMIN,
  ADMIN_READER = 130,
  ADMIN_WRITER,
  ADMIN_ADMIN,
  SUPERADMIN_READER = 150,
  SUPERADMIN_WRITER,
  SUPERADMIN_ADMIN,
}
