import {Component, EventEmitter, HostListener, Input, Output, OnInit} from "@angular/core";
import {SpecialInfoObject} from "../../../../core/shared/models/persons/complementary/specialInfo.object";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CollaboratorSpecialInfoAddEditComponent} from "../collaborator-special-info-add-edit/collaborator-special-info-add-edit.component";

@Component({
  selector: "app-collaborator-special-info",
  templateUrl: "./collaborator-special-info.component.html",
  styleUrls: ["./collaborator-special-info.component.scss"]
})
export class CollaboratorSpecialInfoComponent implements OnInit {

  @Input() itemsToShow: SpecialInfoObject[] = [];
  @Output() itemsToReturn: EventEmitter<SpecialInfoObject[]> = new EventEmitter<SpecialInfoObject[]>();
  tableDataSource: MatTableDataSource<SpecialInfoObject>;
  txtName: string;
  txtFrom: string;
  txtTo: string;
  txtURLString: string;
  txtAdd: string;
  txtNoData: string;
  txtAddIcon: string;
  dc: string[] = [];
  innerWidth: number;
  dialogWidth: number;
  @HostListener("window:resize", ["$event"])
  onresize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = ["Nombre", "Hasta"];
      this.dialogWidth = this.innerWidth - 50;
    } else {
      this.dc = ["Nombre", "desde", "Hasta", "URL"];
      this.dialogWidth = 600;
    }
  }

  constructor(private specialInfoDialog: MatDialog) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = ["Nombre", "Hasta"];
      this.dialogWidth = this.innerWidth - 50;
    } else {
      this.dc = ["Nombre", "desde", "Hasta", "URL"];
      this.dialogWidth = 600;
    }
    this.initTexts();
    this.tableDataSource = new MatTableDataSource<SpecialInfoObject>();
    this.tableDataSource.data = this.itemsToShow;
  }

  initTexts(): void {
    this.txtName = "Nombre";
    this.txtFrom = "Desde";
    this.txtTo = "Hasta";
    this.txtAddIcon = "add";
    this.txtURLString = "URL";
    this.txtAdd = "Agregar Certificado";
    this.txtNoData = "No existen certificados asociados a este usuario en este momento";
  }

  addEditItem(s?: SpecialInfoObject): void {
    console.log(s);
    let index: number;
    let theData: SpecialInfoObject;
    if (s){
      index = this.itemsToShow.findIndex(x => x === s);
      theData = s;
    }
    const dialogRef: MatDialogRef<CollaboratorSpecialInfoAddEditComponent> =
      this.specialInfoDialog.open(CollaboratorSpecialInfoAddEditComponent, {
        closeOnNavigation: false,
        disableClose: true,
        data: theData
      });
    dialogRef.afterClosed().subscribe(
      next => {
        console.log(next);
        if (next) {
          if (index && index > -1) {
            this.itemsToShow[index] = next;
          } else {
            this.itemsToShow.push(next);
            this.tableDataSource.data = this.itemsToShow;
          }
        }
      }
    );
  }

}
