import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserInfoObject} from "../../shared/models/auth/userInfo.object";
import {Subscription} from "rxjs";
import {AccessQueryObject} from "../../shared/models/access-general/accessQuery.object";
import {Moment} from "moment";
import * as moment from "moment";

@Component({
  selector: "app-search-admin",
  templateUrl: "./search-admin.component.html",
  styleUrls: ["./search-admin.component.scss"]
})
export class SearchAdminComponent implements OnInit {

  searchFormGroup: FormGroup;
  fromDateFormControl: FormControl;
  toDateFormControl: FormControl;
  byUserFormControl: FormControl;
  txtFromDate: string;
  txtToDate: string;
  txtByUser: string;
  txtSearch: string;
  queryData: AccessQueryObject;
  minDate: Moment;
  @Input() users: UserInfoObject[] = [];
  @Output() queryTheData: EventEmitter<AccessQueryObject>  = new EventEmitter<AccessQueryObject>();

  constructor() { }

  ngOnInit(): void {
    this.initTexts();
    this.initForm();
    this.minDate = moment("01/01/2020");
    // For demo Purposes
  }

  initForm(): void {
    this.fromDateFormControl = new FormControl("", Validators.required);
    this.toDateFormControl = new FormControl("", Validators.required);
    this.byUserFormControl = new FormControl("");
    this.searchFormGroup = new FormGroup({
      fromDateFormControl: this.fromDateFormControl,
      toDateFormControl: this.toDateFormControl,
      byUserFormControl: this.byUserFormControl
    });
    this.byUserFormControl.disable();
  }

  initTexts(): void {
    this.txtByUser = "Usuario";
    this.txtFromDate = "Desde";
    this.txtToDate = "Hasta";
    this.txtSearch = "Buscar";
  }

  fromDateChanged(selectedDate: any): void {
    console.log(selectedDate.value, "dateChanged");
    this.minDate = selectedDate.value;
  }

  search(): void {
    const fromDate = this.fromDateFormControl.value;
    const toDate = this.toDateFormControl.value;
    let user: string;
    if (this.byUserFormControl.value.toString() && this.byUserFormControl.value.toString().length > 0) {
      user = this.byUserFormControl.value;
    }
    fromDate.hours(0);
    fromDate.minutes(0);
    fromDate.seconds(0);
    toDate.hours(23);
    toDate.minutes(59);
    toDate.seconds(59);
    if (user) {
      this.queryData = new AccessQueryObject(fromDate.unix(), toDate.unix(), user);
    } else {
      this.queryData = new AccessQueryObject(fromDate.unix(), toDate.unix());
    }
    this.queryTheData.emit(this.queryData);
  }

}
