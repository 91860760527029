import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CollaboratorsListComponent } from "./components/collaborators-list/collaborators-list.component";
import { CollaboratorsDetailsComponent } from "./components/collaborators-details/collaborators-details.component";
import { CollaboratorsAddEditComponent } from "./components/collaborators-add-edit/collaborators-add-edit.component";
import {CoreModule} from "../core/core.module";
import { CollaboratorSpecialInfoComponent } from "./components/inner/collaborator-special-info/collaborator-special-info.component";
import { CollaboratorSpecialInfoAddEditComponent } from "./components/inner/collaborator-special-info-add-edit/collaborator-special-info-add-edit.component";



@NgModule({
    declarations: [CollaboratorsListComponent, CollaboratorsDetailsComponent,
        CollaboratorsAddEditComponent, CollaboratorSpecialInfoComponent, CollaboratorSpecialInfoAddEditComponent],
    imports: [
        CommonModule,
        CoreModule,
    ],
    exports: [
        CollaboratorsAddEditComponent
    ],
    providers: [
      CollaboratorsAddEditComponent,
      CollaboratorSpecialInfoAddEditComponent,
    ]
})
export class CollaboratorsModule { }
