import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {CoreModule} from "../core/core.module";
import { MainToolbarComponent } from "./components/main-toolbar/main-toolbar.component";
import { MainMenuComponent } from "./components/main-menu/main-menu.component";
import { MainAreaComponent } from "./components/main-area/main-area.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {FlexLayoutModule} from "@angular/flex-layout";
import {AuthService} from "../auth/shared/auth.service";



@NgModule({
  declarations: [MainToolbarComponent, MainMenuComponent, MainAreaComponent],
  imports: [
    CommonModule,
    CoreModule,
  ]
})
// @ts-ignore
export class DashboardModule { }
