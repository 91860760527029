<div fxLayout="column">
  <table mat-table [dataSource]="tableDataSource" class="table-size" *ngIf="itemsToShow.length > 0">
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef> {{txtName | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{item.name}} </td>
    </ng-container>
    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef> {{txtCompanyName | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{item.companyName}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dc"></tr>
    <tr mat-row (click)="addEditItem(row)" *matRowDef="let row; columns: dc;"></tr>
  </table>
  <div class="no-data-table" *ngIf="itemsToShow.length === 0">
    <h4>{{noDataText}}</h4>
  </div>
  <div>
    <div style="margin-top: 20px">
      <button class="common-button" type="button" (click)="addEditItem()"
              mat-raised-button color="accent">
        {{newShiftText | uppercase}}
      </button>
    </div>
  </div>
</div>




