import { Component, OnInit } from "@angular/core";
import {CoreService} from "../../../core/shared/core.service";
import {Observable, Subscription} from "rxjs";

@Component({
  selector: "app-temp",
  templateUrl: "./temp.component.html",
  styleUrls: ["./temp.component.scss"]
})
export class TempComponent implements OnInit {

  txtCardTitle: string;
  txtCollaborators: string;
  txtPersonalAccess: string;
  txtVehiclesAccess: string;
  txtDemoList: string;
  txtDemoAddEdit: string;
  txtDashboard: string;
  txtInventory: string;
  txtInventoryIn: string;
  txtInventoryOut: string;
  txtInventoryOther: string;
  items: any[] = [];
  itemsObs: Observable<any[]>;
  itemsSub: Subscription;

  constructor(private coreService: CoreService) { }

  ngOnInit(): void {
    this.initTexts();
    this.itemsSub = this.coreService.getColombiaLocations().subscribe(
      next => {
        this.coreService.getColombiaLocationsFromURL(next).subscribe(
          next2 => this.items = next2,
          error2 => console.log(error2)
        );
        this.itemsObs = this.coreService.getColombiaLocationsFromURL(next).pipe();
      } ,
      error => console.error(error)
    );
  }

  initTexts(): void {
    this.txtCardTitle = "Seleccione una opción";
    this.txtCollaborators = "Colaboradores";
    this.txtPersonalAccess = "Acceso Personal";
    this.txtVehiclesAccess = "Acceso Vehicular";
    this.txtDemoList = "Demo Lista";
    this.txtDemoAddEdit = "Demo Agregar - Editar";
    this.txtDashboard = "Panel de Control";
    this.txtInventory = "Inventario";
    this.txtInventoryIn = "Inventario - Ingresos";
    this.txtInventoryOut = "Inventario - Salidas";
    this.txtInventoryOther = "Inventario - Otros";
  }

}
