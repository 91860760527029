import {Component, ElementRef, Inject, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SpecialInfoObject} from "../../../../core/shared/models/persons/complementary/specialInfo.object";
import * as moment from "moment";

@Component({
  selector: "app-collaborator-special-info-add-edit",
  templateUrl: "./collaborator-special-info-add-edit.component.html",
  styleUrls: ["./collaborator-special-info-add-edit.component.scss"]
})
export class CollaboratorSpecialInfoAddEditComponent implements OnInit {

  txtItemIcon: string;
  txtAccept: string;
  txtCancel: string;
  txtName: string;
  txtNameRequiredError: string;
  txtFrom: string;
  txtTo: string;
  txtToRequiredError: string;
  txtUrl: string;
  txtRequiredUrl: string;
  txtAddImage: string;
  txtAddImageIcon: string;
  txtPreviewImageIcon: string;
  txtPreviewImage: string;
  specialItemGroup: FormGroup;
  nameControl: FormControl;
  fromControl: FormControl;
  toControl: FormControl;
  urlControl: FormControl;

  current: SpecialInfoObject;
  original: SpecialInfoObject;

  dialogTitle: string;

  @ViewChild("fileUpload", {static: false}) fileItem: ElementRef;
  constructor(private specialInfoDialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data: SpecialInfoObject,
              private specialInfoDialogRef: MatDialogRef<CollaboratorSpecialInfoAddEditComponent>) { }

  ngOnInit(): void {
    this.initTexts();
    this.initForm();
    this.initData();
  }

  initForm(): void {
    this.nameControl = new FormControl("", Validators.required);
    this.fromControl = new FormControl("");
    this.toControl = new FormControl("", Validators.required);
    this.urlControl = new FormControl("", Validators.required);
    this.specialItemGroup = new FormGroup({
      nameControl: this.nameControl,
      fromControl: this.fromControl,
      toControl: this.toControl,
      urlControl: this.urlControl
    });
  }

  initData(): void {
    if (this.data) {
      this.nameControl.setValue(this.data.name);
      this.toControl.setValue(moment(this.data.fromDateString, "DD/MM/YYYY"));
      this.fromControl.setValue(moment(this.data.toDateString, "DD/MM/YYYY"));
      this.urlControl.setValue(this.data.imageUrl);
      this.dialogTitle = "Editar Certificado";
    }
  }

  initTexts(): void {
    this.txtItemIcon = "article";
    this.txtAccept = "Aceptar";
    this.txtCancel = "Cancelar";
    this.dialogTitle = "Agregar certificado";
    this.txtName = "Nombre";
    this.txtNameRequiredError = "El nombre es obligatorio";
    this.txtFrom = "Desde";
    this.txtTo = "Hasta";
    this.txtToRequiredError = "La fecha de expiración es necesaria";
    this.txtUrl = "Ruta del certificado";
    this.txtRequiredUrl = "El certificado es obligatorio";
    this.txtAddImage = "Cargar";
    this.txtAddImageIcon = "cloud_upload";
    this.txtPreviewImage = "Ver";
    this.txtPreviewImageIcon = "preview";
  }

  closeDialog(): void {
    this.specialInfoDialogRef.close();
  }

  saveItem(data: any): void {
    console.log("data", data);
    this.current = new SpecialInfoObject();
    this.current.name = data.nameControl;
    this.current.fromDateString = moment(data.fromControl).format("DD/MM/YYYY");
    this.current.toDateString = moment(data.toControl).format("DD/MM/YYYY");
    this.current.imageUrl = data.urlControl;
    console.log("Result", this.current);
    this.specialInfoDialogRef.close(this.current);
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.specialItemGroup.controls[controlName].hasError(errorName);
  }

  onFileSelected(event: any): void {
    console.log("Clicked", event, this.fileItem);
  }
}
