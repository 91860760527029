import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {PersonTransactionObject} from "../../../core/shared/models/access-personel/personTransaction.object";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {VehicleTransactionObject} from "../../../core/shared/models/access-vehicle/vehicleTransaction.object";

@Component({
  selector: "app-vehicles-access-add-edit",
  templateUrl: "./vehicles-access-add-edit.component.html",
  styleUrls: ["./vehicles-access-add-edit.component.scss"]
})
export class VehiclesAccessAddEditComponent implements OnInit {

  txtAccessTitle: string;
  txtAccessSubTitle: string;
  txtDocumentNumber: string;
  txtEnterButton: string;
  txtExitButton: string;
  docNumberFormGroup: FormGroup;
  docNumberFormControl: FormControl;
  collaboratorSubscription: Subscription;
  transactionSubscription: Subscription;
  collaboratorAccessTransaction: VehicleTransactionObject;
  currentUser: UserInfoObject;
  @ViewChild("placas", {static: false}) placas: ElementRef;
  @Output() vehicleEntry: EventEmitter<VehicleTransactionObject> = new EventEmitter<VehicleTransactionObject>();
  @Output() vehicleExit: EventEmitter<VehicleTransactionObject> = new EventEmitter<VehicleTransactionObject>();

  constructor() { }

  ngOnInit(): void {
  }

}
