<div class="login-area" fxLayoutAlign="center center" fxLayout="column">
  <mat-card class="login-card mat-elevation-z5" ngClass.lt-md="login-card-small">
    <form [formGroup]="loginForm" (ngSubmit)="submitLogin(loginForm.getRawValue())">
      <img ngClass.lt-sm="image_size_small"
           src="assets/images/kostruAccessLogo.png" alt="KostruAccessLogoV1"
           class="image_size" />
      <mat-card-header >
        <mat-card-title><h3 class="card-title-h3-login">{{subTitle}}</h3></mat-card-title>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <mat-form-field fxFlex class="FormFieldAll" appearance="fill">
          <mat-label>{{emailText}}</mat-label>
          <input matInput [formControl]="usernameControl" required>
          <mat-error *ngIf="hasError('usernameControl', 'required')">{{requiredEmail}}</mat-error>
          <mat-error *ngIf="hasError('usernameControl', 'email')">{{requiredEmail}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex class="FormFieldAll" appearance="fill">
          <mat-label>{{passwordText}}</mat-label>
          <input matInput type="password" [formControl]="passwordControl" required>
          <mat-error *ngIf="hasError('passwordControl', 'required')">{{requiredPassword}}</mat-error>
          <mat-error *ngIf="hasError('passwordControl', 'minlength')">{{shortPassword}}</mat-error>
          <mat-error *ngIf="hasError('passwordControl', 'maxlength')">{{longPassword}}</mat-error>
        </mat-form-field>
        <div fxFlex class="error_container" fxLayoutAlign="space-around center" *ngIf="loginError">
          <p class="error_text">{{errorLoginText}} </p>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <div fxFlex fxLayout.lt-sm="column">
          <button class="common-button" fxFlex mat-button color="primary" type="button" routerLink="/auth/forgotPassword">{{forgotText | uppercase}}</button>
          <button class="common-button" fxFlex mat-raised-button color="primary" [disabled]="!loginForm.valid" type="submit">{{loginText | uppercase}}</button>
        </div>
      </mat-card-actions>
      <mat-card-footer *ngIf="loggingIn">
        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
      </mat-card-footer>
    </form>
  </mat-card>
</div>
