import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-inventory-add-edit",
  templateUrl: "./inventory-add-edit.component.html",
  styleUrls: ["./inventory-add-edit.component.scss"]
})
export class InventoryAddEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
