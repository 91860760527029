import {Component, Inject, OnInit} from "@angular/core";
import {UsersService} from "../../../shared/users.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UserKindObject} from "../../../../core/shared/models/auth/UserKind.object";
import {UserKindEnum} from "../../../../core/shared/models/auth/enums/userKind.enum";
import {CoreService} from "../../../../core/shared/core.service";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: "app-role-add-edit",
  templateUrl: "./role-add-edit.component.html",
  styleUrls: ["./role-add-edit.component.scss"]
})
export class RoleAddEditComponent implements OnInit {

  txtIcon: string;
  txtDialogTitle: string;
  txtAdd: string;
  txtRole: string;
  selected: UserKindObject;
  theInitialObjects: UserKindObject[] = [];
  theObjects: UserKindObject[] = [];
  theCap = 149;
  constructor(private usersService: UsersService, @Inject(MAT_DIALOG_DATA) private data: { current_roles: UserKindObject[], cap: number },
              private dialogRef: MatDialogRef<RoleAddEditComponent>) { }

  ngOnInit(): void {
    this.initTexts();
    if (!this.data) {
      this.dialogRef.close();
    }
    if (this.data.cap) {
      this.theCap = this.data.cap;
    }
    this.initCappedData();
  }

  initCappedData(): void {
    for (const uke in UserKindEnum) {
      if (!isNaN(Number(uke))) {
        const item = this.usersService.userKindEnumsToSingleObject(Number(uke));
        if (item.value > 9 && item.value <= this.theCap) {
          this.theInitialObjects.push(item);
        }
      }
    }
    console.log("Loading", this.theCap, this.theInitialObjects);
    if (this.theInitialObjects.length > 0) {
      this.theObjects = this.usersService.userRoleComparer(this.theCap, this.data.current_roles, this.theInitialObjects);
      console.log("Loading");
    }
  }

  initTexts(): void {
    this.txtIcon = "supervised_user_circle";
    this.txtDialogTitle = "Agregar Permiso";
    this.txtAdd = "Agregar";
    this.txtRole = "Permiso";
  }

  changedRole(item: MatSelectChange): void {
    this.selected = item.value;
  }

  addRole(): void {
    if (this.selected) {
      this.dialogRef.close(this.selected);
    }
  }
}
