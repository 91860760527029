export class MenuItem {
  section: string;
  name: string;
  link: string;
  icon: string;
  value: number;
  useMaterialIcon: boolean;
  subItems: MenuSubItem[];
  visible: boolean;
  constructor(section: string, name: string, link: string, icon: string, subItems: MenuSubItem[],
              value: number, useMaterialIcon?: boolean, visible?: boolean) {
    this.section = section;
    this.name = name;
    this.link = link;
    this.icon = icon;
    this.subItems = subItems;
    this.value = value;
    if (useMaterialIcon) {
      this.useMaterialIcon = useMaterialIcon;
    }
    if (visible) {
      this.visible = visible;
    } else {
      this.visible = true;
    }
  }
}

export class MenuSubItem {
  name: string;
  link: string;
  icon: string;
  value: number;
  useMaterialIcon: boolean;
  visible: boolean;
  avoidCurrentLink;
  constructor(name: string, link: string, icon: string, value: number, useMaterialIcon?: boolean, visible?: boolean,
              avoidCurrentLink?: boolean) {
    this.name = name;
    this.link = link;
    this.icon = icon;
    this.value = value;
    if (useMaterialIcon) {
      this.useMaterialIcon = useMaterialIcon;
    }
    if (avoidCurrentLink) {
      this.avoidCurrentLink = avoidCurrentLink;
    }
    if (visible) {
      this.visible = visible;
    } else {
      this.visible = true;
    }
  }
}
