import {Component, EventEmitter, OnDestroy, OnInit, Output} from "@angular/core";
import {MenuItem, MenuSubItem} from "../../../core/shared/models/common/menu.object";
import {Router} from "@angular/router";
import {DashboardService} from "../../shared/dashboard.service";
import {AuthService} from "../../../auth/shared/auth.service";
import {Subscription} from "rxjs";
import {auth} from "firebase";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"]
})
export class MainMenuComponent implements OnInit, OnDestroy {

  theMenu: MenuItem[] = [];
  homeItem: MenuItem;
  hoverItem: MenuItem;
  userSubscription: Subscription;
  @Output()itemClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private dashboardService: DashboardService, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.authService.getCurrentUserInfo()) {
      if (this.authService.getCurrentUserInfo().useGlobalRolesForAllProjects) {
        this.dashboardService.buildMenu(this.authService.getCurrentUserInfo().globalRoles);
        this.theMenu = this.dashboardService.MENU;
      } else {
        // TODO: Implementar permisos por Proyecto;
      }
    }
    this.userSubscription = this.authService.theCurrentUserInfoSubject.asObservable().subscribe(
      next => {
        if (next.useGlobalRolesForAllProjects) {
          this.dashboardService.buildMenu(this.authService.getCurrentUserInfo().globalRoles);
          this.theMenu = this.dashboardService.MENU;
        } else {
          // TODO: Implementar permisos por Proyecto;
        }
      });
    this.homeItem = new MenuItem("HM", "Vision General", "dashboard", "home", [], 0, true);
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  goHome(): void {
    this.itemClick.emit();
  }

  clickedSubItem(item: MenuItem, subItem: MenuSubItem): void {
    this.itemClick.emit();
  }

  isItemValid(): boolean {
    return true;
  }

}
