<form [formGroup]="vehicleFormGroup">
  <div fxLayout="column">
    <h3 class="card-title-h3" style="text-align: center">{{txtAccessTitle}}</h3>
    <h5 class="card-sub-title-h4" style="text-align: center">{{txtAccessSubTitle}}</h5>
    <div fxLayoutAlign="center center">
      <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50">
        <mat-label>{{txtDocumentNumber}}</mat-label>
        <input #thePlates matInput [formControl]="plateFormControl" type="text">
        <button type="button" (click)="clearText()" mat-icon-button matSuffix>
          <mat-icon>backspace</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div fxFlex fxLayout="row" fxLayout.lt-md="column">
      <div fxLayoutAlign="end Center" fxLayoutAlign.lt-md="center center" fxFlex class="button-containers">
        <button class="common-button action-button" [disabled]="vehicleFormGroup.invalid" (click)="markIn()"
                mat-raised-button type="button" color="primary">{{txtEnterButton}}</button>
      </div>
      <div fxLayoutAlign="start center"  fxLayoutAlign.lt-md="center center" fxFlex class="button-containers">
        <button class="common-button action-button" [disabled]="vehicleFormGroup.invalid" (click)="markOut()"
                mat-raised-button type="button" color="primary">{{txtExitButton}}</button>
      </div>
    </div>
  </div>
</form>
