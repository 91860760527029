import {LogEventKindEnum} from "./enums/logEventKind.enum";

export class LogObject {
  public dateTimeMoment: number;
  public event: string;
  public eventKind: LogEventKindEnum;
  public madeByUser: string;

  constructor(dtm: number, event: string, kind: LogEventKindEnum, user: string) {
    this.dateTimeMoment = dtm;
    this.event = event;
    this.eventKind = kind;
    this.madeByUser = user;
  }
}
