export class SpecialInfoObject {
  name: string;
  fromDateString: string; // Format "DD/MM/YYYY"
  toDateString: string;  // Format "DD/MM/YYYY"
  imageUrl: string;

  constructor(s?: SpecialInfoObject) {
    if (s) {
      this.name = s.name;
      this.fromDateString = s.fromDateString;
      this.toDateString = s.toDateString;
      this.imageUrl = s.imageUrl;
    }
  }

}
