import {LogObject} from "../common/log.object";

export class ProjectObject {
  uid: string;
  name: string;
  description: string;
  companyId: string;
  companyName: string;
  locationDepartment: string;
  locationCity: string;
  extraInfo: string;
  startDateMoment: string; // DD/MM/YYYY
  endDateMoment: string; // DD/MM/YYYY
  enabled: boolean;
  deleted: boolean;
  logs: LogObject[];

  constructor(p?: ProjectObject) {
    if (p) {
      if (p.uid) {
        this.uid = p.uid;
      }
      this.name = p.name;
      this.description = p.description;
      this.companyId = p.companyId;
      this.companyName = p.companyName;
      this.locationDepartment = p.locationDepartment;
      this.locationCity = p.locationCity;
      this.extraInfo = p.extraInfo;
      this.startDateMoment = p.startDateMoment;
      this.endDateMoment = p.endDateMoment;
      this.enabled = p.enabled;
      this.deleted = p.deleted;
      this.logs = p.logs;
    } else {
      this.enabled = false;
      this.deleted = false;
      this.logs = [];
    }
  }
}
