<div mat-dialog-title>
  <div fxLayout="row">
    <mat-icon>{{txtIcon}}</mat-icon>
    <h3 class="card-title-h3">{{txtDialogTitle}}</h3>
    <span fxFlex></span>
  </div>
</div>
<form [formGroup]="formGroup">
  <div mat-dialog-content class="dialog-size" ngClass.lt-sm="dialog-size-small">
    <mat-form-field appearance="fill" class="item" fxFlex="100">
      <mat-label>{{txtProject}}</mat-label>
      <mat-select [formControl]="projectControl" (selectionChange)="changeProject($event)">
        <mat-option *ngFor="let item of theProjects" [value]="item" >{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-content class="dialog-size" ngClass.lt-sm="dialog-size-small">
    <mat-form-field appearance="fill" class="item" fxFlex="100">
      <mat-label>{{txtRole}}</mat-label>
      <mat-select [formControl]="permissionControl" (selectionChange)="changedRole($event)">
        <mat-option *ngFor="let item of theObjects" [value]="item" >{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center" class="item">
    <button mat-raised-button type="button" (click)="addRole()" [disabled]="formGroup.invalid" color="primary">{{txtAdd}}</button>
  </div>
</form>
