import {Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {CollaboratorObject} from "../../../core/shared/models/persons/collaborator.object";
import * as moment from "moment";

@Component({
  selector: "app-demo-list",
  templateUrl: "./demo-list.component.html",
  styleUrls: ["./demo-list.component.scss"]
})
export class DemoListComponent implements OnInit, OnDestroy {

  logoPath: string;
  cardTitle: string;
  newShiftText: string;
  tableDataSource: MatTableDataSource<CollaboratorObject>;
  itemsToShow: CollaboratorObject[] = [];
  dc: string [];
  txtFirstName: string;
  txtSecondName: string;
  txtLastName: string;
  txtSecondLastName: string;
  txtIdentification: string;
  counterText: string;
  noDataText: string;
  innerWidth: number;
  dialogWidth: number;
  shiftSubscriptions: Subscription;
  showBar: boolean;
  // Se usa un listener para saber qué columnas mostrar
  @HostListener("window:resize", ["$event"])
  onresize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = ["subLocation", "date", "counter"];
      this.dialogWidth = this.innerWidth - 50;
    } else {
      this.dc = ["location", "subLocation", "date", "from", "to", "counter"];
      this.dialogWidth = 600;
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = ["firstName", "lastName", "identification"];
      this.dialogWidth = this.innerWidth - 50;
    } else {
      this.dc = ["firstName", "secondName", "lastName", "secondLastName", "identification"];
      this.dialogWidth = 600;
    }
    this.logoPath = "assets/images/KooguenSqaure.png";
    this.cardTitle = "DemoItems";
    this.newShiftText = "Nuevo Item Demo";
    this.tableDataSource = new MatTableDataSource<CollaboratorObject>();
    this.itemsToShow = this.buildDemoData();
    this.tableDataSource.data = this.itemsToShow;
    this.txtLastName = "Apellido";
    this.txtFirstName = "Nombre";
    this.txtSecondName = "Segundo N.";
    this.txtSecondLastName = "Segundo A.";
    this.txtIdentification = "Identificación";
    this.counterText = "T. Asignados";
    this.noDataText = "No hay Datos en este momento!";
    this.loadShiftsFromDB();
  }

  ngOnDestroy(): void {
    this.shiftSubscriptions.unsubscribe();
  }

  getDateFromUnix(theUnixTime: number): string {
    if (theUnixTime > 0) {
      return moment.unix(theUnixTime).format("HH:mm:SS");
    }
    return "N/A";
  }

  selectAction(theShiftObject: any): void {
    /*
    const actionDialogRef = this.actionDialog.open(TurnsActionsDialogComponent, {
      width: "300px",
      disableClose: true,
      autoFocus: true,
    });
    actionDialogRef.afterClosed().subscribe(
      next => {
        if (next) {
          const selection: TurnManageSelectionEnum = next;
          if (selection === TurnManageSelectionEnum.MANAGE) {
            this.turnsAdminService.currentSessionShift = theShiftObject;
            this.router.navigate(["dashboard/admin/turnsSessions"]);
          } else if (selection === TurnManageSelectionEnum.EDIT) {
            this.addEditTS(theShiftObject);
          }
        }
      }
    );
     */
  }

  addEditTS(theShiftObject?: any): void {
    console.log("Must add or edit if exists", theShiftObject);
    /*
    let editing = false;
    let theData = {
      shiftObject: null,
      isBig: this.innerWidth >= 800
    };
    if (theShiftObject) {
      editing = true;
      theData = {
        shiftObject: theShiftObject,
        isBig: this.innerWidth >= 800
      };
    }
    const dialogRef = this.tsAddEditMatDialog.open(TurnSessionAddEditNewComponent, {
      width: `${this.dialogWidth}px`,
      data: theData,
      disableClose: true,
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe(
      next => {
        if (next) {
          console.log(next);
          const theShift: ShiftObject = new ShiftObject(next);
          if (editing) {
            const update: Update<ShiftObject> = {
              id: theShift.uid,
              changes: theShift
            };
            this.store.dispatch(updateShift({shift: update}));
          } else {
            this.store.dispatch(addShiftManager({item: theShift}));
          }
        }
      }
    );
     */
  }

  loadShiftsFromDB(): void {
    this.showBar = true;
    // No sirve debe incorporar el servicio y el metodo que llama la lista
    /*
    //this.shiftSubscriptions = this.store.pipe(select(selectShifts)).subscribe(
      next => {
        if (next)
          this.itemsToShow = next;
        this.tableDataSource.data = this.itemsToShow;
        this.showBar = false;
      }, error => {
        console.error("Error:", error);
        this.showBar = false;
      }
    );
     */

  }

  buildDemoData(): CollaboratorObject[] {
    const data: CollaboratorObject[] = [];
    let i = 10;
    while (i > 0) {
      const item = new CollaboratorObject();
      item.personalFirstName = `Nombre ${i}`;
      item.personalSecondName = `Segundo ${i}`;
      item.personalLastName = `Apellido ${i}`;
      item.personalSecondLastName = `Seg Apellido ${i}`;
      item.personalIdentification = `ID ${i}`;
      data.push(item);
      i--;
    }
    return data;
  }

}
