<div>
  <table mat-table multiTemplateDataRows [dataSource]="dataTurns" class="the-table">
    <ng-container matColumnDef="{{txtFrom}}">
      <th mat-header-cell *matHeaderCellDef> {{txtFrom | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{showDate(item.startDateTime)}} </td>
    </ng-container>
    <ng-container matColumnDef="{{txtTo}}">
      <th mat-header-cell *matHeaderCellDef> {{txtTo | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{showDate(item.endDateTime)}} </td>
    </ng-container>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="dc.length">
          <div (click)="showDetails(element)" class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div fxLayout="row" fxLayout.lt-sm="column" fxFlex="" fxLayoutAlign="space-around center">
              <div class="example-element-diagram">
                <div class="example-element-symbol">Personal</div>
                <div fxLayout="row">
                  <div class="example-element-in-out">Entrada: {{element.personInTurn}} </div>
                  <div class="example-element-in-out"> Salida: {{element.personOutTurn}} </div>
                </div>
              </div>
              <div class="example-element-diagram">
                <div class="example-element-symbol">Vehicular</div>
                <div fxLayout="row">
                  <div class="example-element-in-out">Entrada: {{element.vehiclesInTurn}} </div>
                  <div class="example-element-in-out"> Salida: {{element.vehiclesOutTurn}} </div>
                </div>
              </div>
            </div>
          </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dc"></tr>
    <tr mat-row *matRowDef="let element; columns: dc;"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>
