import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PersonsAccessListComponent } from "./components/persons-access-list/persons-access-list.component";
import { PersonsAccessAddEditComponent } from "./components/persons-access-add-edit/persons-access-add-edit.component";
import { PersonsAccessDetailsComponent } from "./components/persons-access-details/persons-access-details.component";
import {CoreModule} from "../core/core.module";
import { PersonsAccessOverviewComponent } from "./components/persons-access-overview/persons-access-overview.component";
import { OperationComponent } from "./components/operation/operation.component";
import { PersonsTurnManagerComponent } from "./components/persons-turn-manager/persons-turn-manager.component";
import { ConfirmAndVehicleComponent } from "./components/inner/confirm-and-vehicle/confirm-and-vehicle.component";
import { NotFoundDialogComponent } from "./components/dialogs/not-found-dialog/not-found-dialog.component";
import {VehiclesAccessModule} from "../vehicles-access/vehicles-access.module";
import { SearchAdminAccessComponent } from "./components/admin/search-admin-access/search-admin-access.component";
import { AdminAccessComponent } from "./components/admin/admin-access/admin-access.component";
import { AdminTurnsListComponent } from "./components/admin/admin-turns-list/admin-turns-list.component";
import { AdminAccessListComponent } from './components/admin/admin-access-list/admin-access-list.component';
import { AdminPersonsListComponent } from './components/admin/admin-persons-list/admin-persons-list.component';
import { AdminVehiclesListComponent } from './components/admin/admin-vehicles-list/admin-vehicles-list.component';



@NgModule({
  declarations: [PersonsAccessListComponent, PersonsAccessAddEditComponent, PersonsAccessDetailsComponent,
    PersonsAccessOverviewComponent, OperationComponent, PersonsTurnManagerComponent, ConfirmAndVehicleComponent,
    NotFoundDialogComponent, SearchAdminAccessComponent, AdminAccessComponent, AdminTurnsListComponent, AdminAccessListComponent, AdminPersonsListComponent, AdminVehiclesListComponent],
  imports: [
    CommonModule,
    CoreModule,
    VehiclesAccessModule,
  ],
  providers: [ConfirmAndVehicleComponent, NotFoundDialogComponent]
})
// @ts-ignore
export class PersonsAccessModule { }
