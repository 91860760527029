import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CompaniesListComponent } from "./components/companies-list/companies-list.component";
import { CompanyAddEditComponent } from "./components/company-add-edit/company-add-edit.component";
import {CoreModule} from "../core/core.module";
import { ProjectsListComponent } from "./components/projects-list/projects-list.component";
import { ProjectAddEditComponent } from "./components/project-add-edit/project-add-edit.component";



@NgModule({
  declarations: [CompaniesListComponent, CompanyAddEditComponent, ProjectsListComponent, ProjectAddEditComponent],
  imports: [
    CommonModule,
    CoreModule,
  ]
})
// @ts-ignore
export class CompanyModule { }
