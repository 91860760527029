<div mat-dialog-title>
  <mat-icon>{{txtDialogIcon}}</mat-icon>
  <h3 class="card-title-h3">{{txtDialogTitle}}</h3>
</div>
<div mat-dialog-content>
  <div fxLayout="column">

  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button class="common-button" type="button" mat-raised-button color="primary">{{txtClose}}</button>
</div>
