import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DemoListComponent } from "./components/demo-list/demo-list.component";
import { DemoAddEditComponent } from "./components/demo-add-edit/demo-add-edit.component";
import {CoreModule} from "../core/core.module";
import { DemoDialogComponent } from "./components/demo-dialog/demo-dialog.component";



@NgModule({
  declarations: [DemoListComponent, DemoAddEditComponent, DemoDialogComponent],
  imports: [
    CommonModule,
    CoreModule
  ],
  providers: [DemoDialogComponent]
})
// @ts-ignore
export class DemoModule { }
