<div mat-dialog-title>
  <div fxLayout="row">
    <mat-icon>{{txtItemIcon}}</mat-icon>
    <h3 class="card-title-h3">{{dialogTitle}}</h3>
  </div>
</div>
<form [formGroup]="collaboratorsForm" (ngSubmit)="save()">
  <div mat-dialog-content class="dialog-size" ngClass.lt-sm="dialog-size-small">
    <mat-tab-group dynamicHeight="true">
        <mat-tab label="{{txtPersonalInfo|uppercase}}">
          <div fxLayout="column">
            <div fxLayout="row" fxLayout.lt-sm="column">
              <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50" class="first-line item">
                <mat-label>{{txtFirstName}}</mat-label>
                <input matInput [formControl]="personalFirstName" required>
                <mat-error *ngIf="hasError('personalFirstName', 'required')">
                  {{txtRequiredFirstName}}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50" class="first-line item" ngClass.lt-sm="first-line-small">
                <mat-label>{{txtSecondName}}</mat-label>
                <input matInput [formControl]="personalSecondName">
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column">
              <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50"  class="item">
                <mat-label>{{txtLastName}}</mat-label>
                <input matInput [formControl]="personalLastName" required>
                <mat-error *ngIf="hasError('personalLastName', 'required')">
                  {{txtRequiredLastName}}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" fxFlex.lt-sm="100" fxFlex="50" class="item">
                <mat-label>{{txtSecondLastName}}</mat-label>
                <input matInput [formControl]="personalSecondLastName">
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column">
              <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
                <mat-label>{{txtDocumentType}}</mat-label>
                <mat-select [formControl]="personalIdentificationType" matInput required>
                  <mat-option *ngFor="let doc of docs" [value]="doc.codigo">{{doc.codigo}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('personalIdentificationType', 'required')">
                  {{txtRequiredTypeIdentify}}
                </mat-error>
              </mat-form-field>
              <mat-form-field  appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
                <mat-label>{{txtDocumentNumber}}</mat-label>
                <input [formControl]="personalIdentification" matInput required>
                <mat-error *ngIf="hasError('personalIdentification', 'required')">
                  {{txtRequiredIdentify}}
                </mat-error>
                <mat-error *ngIf="hasError('personalIdentification', 'minlength')">
                  {{txtDocNumberShort}}
                </mat-error>
                <mat-error *ngIf="hasError('personalIdentification', 'maxlength')">
                  {{txtDocNumberLong}}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column">
              <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
                <mat-label>{{txtDocumentLocationOne}}</mat-label>
                <mat-select [formControl]="personalIdentificationFrom" matInput required (selectionChange)="updateCities($event)">
                  <mat-option *ngFor="let item of items" [value]="item">{{item.departamento}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('personalIdentificationFrom', 'required')">
                  {{txtRequiredFromIdentify}}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
                <mat-label>{{txtDocumentLocationTwo}}</mat-label>
                <mat-select [formControl]="personalIdentificationFromCity" matInput required>
                  <mat-option *ngFor="let city of cities" [value]="city">{{city}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('personalIdentificationFrom', 'required')">
                  {{txtRequiredFromIdentify}}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayout.lt-sm="column">
              <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
                <mat-label>{{txtDocumentExpeditionDate}}</mat-label>
                <input [formControl]="personalIdentificationExpeditionDate" matInput [matDatepicker]="picker" matInput required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="multi-year"></mat-datepicker>
                <mat-error *ngIf="hasError('personalIdentificationFrom', 'required')">
                  {{txtRequiredFromIdentify}}
                </mat-error>
              </mat-form-field>
            </div>
        </div>
      </mat-tab>
      <mat-tab label="{{txtContactInfo|uppercase}}">
        <div fxLayout="column">
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item first-line" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtPhone}}</mat-label>
              <input [formControl]="contactPhone" matInput required>
              <mat-error *ngIf="hasError('contactPhone', 'required')">{{txtRequiredPhone}}</mat-error>
              <mat-error *ngIf="hasError('contactPhone', 'minlength')">{{txtValidPhoneMin}}</mat-error>
              <mat-error *ngIf="hasError('contactPhone', 'maxlength')">{{txtValidPhoneMax}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" ngClass.lt-sm="first-line-small" class="item first-line" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtEmail}}</mat-label>
              <input type="Email" [formControl]="contactEmail" matInput required>
              <mat-error *ngIf="hasError('contactEmail', 'required')">{{txtRequiredEmail}}</mat-error>
              <mat-error *ngIf="hasError('contactEmail', 'pattern')">{{txtValidEmail}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtAddress}}</mat-label>
              <input [formControl]="contactAddress" matInput required>
              <mat-error *ngIf="hasError('contactAddress', 'required')">{{txtRequiredAddress}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtBirthDate}}</mat-label>
              <input [formControl]="personalBirthDateString" matInput [matDatepicker]="birthPicker" matInput required>
              <mat-datepicker-toggle matSuffix [for]="birthPicker"></mat-datepicker-toggle>
              <mat-datepicker #birthPicker startView="multi-year"></mat-datepicker>
              <mat-error *ngIf="hasError('personalBirthDateString', 'required')">{{txtRequiredBirthDate}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtDocumentLocationOne}}</mat-label>
              <mat-select [formControl]="personalContactFrom" matInput required (selectionChange)="updateContactCities($event)">
                <mat-option *ngFor="let item of items" [value]="item">{{item.departamento}}</mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('personalIdentificationFrom', 'required')">
                {{txtRequiredFromIdentify}}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtDocumentLocationTwo}}</mat-label>
              <mat-select [formControl]="personalContactFromCity" matInput required>
                <mat-option *ngFor="let city of citiesContact" [value]="city">{{city}}</mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('personalIdentificationFrom', 'required')">
                {{txtRequiredFromIdentify}}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="50" fxFlex="25">
              <mat-label>{{txtBloodTypeOne}}</mat-label>
              <mat-select [formControl]="personalBloodType">
                <mat-option *ngFor="let bt of bloodTypes" [value]="bt">{{bt}}</mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('personalBloodType', 'required')">{{txtRequiredBlood}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="50" fxFlex="25">
              <mat-label>{{txtBloodTypeTwo}}</mat-label>
              <mat-select [formControl]="personalBloodTypeTwo">
                <mat-option *ngFor="let rf of rhFactor" [value]="rf">{{rf}}</mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('personalBloodType', 'required')">{{txtRequiredBlood}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{txtLaborInfo|uppercase}}">
        <div>
          <div>
            <mat-form-field appearance="fill" class="item first-line" fxFlex="100">
              <mat-label>{{txtRole}}</mat-label>
              <input [formControl]="laborRole"  matInput required>
              <mat-error *ngIf="hasError('laborRole', 'required')">{{txtRequiredLaborRole}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtSalary}}</mat-label>
              <input type="number" [formControl]="laborSalary"  matInput required>
              <mat-error *ngIf="hasError('laborSalary', 'required')">{{txtRequiredLaborSalary}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtFPS}}</mat-label>
              <input [formControl]="laborFPS" matInput required>
              <mat-error *ngIf="hasError('laborFPS', 'required')">{{txtRequiredLaborFPS}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtEPS}}</mat-label>
              <input [formControl]="laborEPS" matInput required>
              <mat-error *ngIf="hasError('laborEPS', 'required')">{{txtRequiredLaborEPS}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtIPS}}</mat-label>
              <input [formControl]="laborIPS" matInput required>
              <mat-error *ngIf="hasError('laborIPS', 'required')">{{txtRequiredLaborIPS}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtARL}}</mat-label>
              <input [formControl]="laborARL" matInput required>
              <mat-error *ngIf="hasError('laborARL', 'required')">{{txtRequiredLaborARL}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtCES}}</mat-label>
              <input [formControl]="laborCES" matInput required>
              <mat-error *ngIf="hasError('laborCES', 'required')">{{txtRequiredLaborCES}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtCCF}}</mat-label>
              <input [formControl]="laborCCF" matInput required>
              <mat-error *ngIf="hasError('laborCCF', 'required')">{{txtRequiredLaborCCF}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="{{txtSpecialInfo|uppercase}}">
        <app-collaborator-special-info (itemsToReturn)="updateSpecialInfo($event)" [itemsToShow]="specialInfoObjects"></app-collaborator-special-info>
      </mat-tab>
    </mat-tab-group>
    <section>
      <mat-slide-toggle [formControl]="activeFC">{{txtActiveCollaborator}}</mat-slide-toggle>
    </section>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center" class="item">
    <button mat-raised-button color="accent" class="button-class" type="button" (click)="closeDialog()">{{txtCancel|uppercase}}</button>
    <button mat-raised-button color="primary" class="button-class" type="submit" [disabled]="collaboratorsForm.invalid">{{txtAccept|uppercase}}</button>
  </div>
</form>

