import {LogObject} from "../common/log.object";
import {ProjectInUserObject} from "../company/projectInUser.object";
import {UserKindEnum} from "./enums/userKind.enum";

export class UserInfoObject {
  userID: string;
  userName: string;
  userEmail: string;
  userDocumentNumber: string;
  userDocumentType: string;
  corporateId: string;
  corporateName: string;
  projects: ProjectInUserObject[];
  globalRoles: UserKindEnum[];
  corporateToken: string;
  enabled: boolean;
  validated: boolean;
  deleted: boolean;
  useGlobalRolesForAllProjects: boolean;
  logs: LogObject[];

  constructor(u?: UserInfoObject) {
    this.userName = "";
    this.userEmail = "";
    this.userDocumentNumber = "";
    this.userDocumentType = "";
    this.corporateId = "";
    this.corporateName = "";
    this.projects = [];
    this.globalRoles = [];
    this.logs = [];
    this.deleted = false;
    this.useGlobalRolesForAllProjects = true;
    this.userID = "";
    this.corporateToken = "";
    this.enabled = true;
    this.validated = false;
    if (u) {
      if (u.userID) {
        this.userID = u.userID;
      }
      this.userName = u.userName;
      this.userEmail = u.userEmail;
      this.userDocumentNumber = u.userDocumentNumber;
      this.userDocumentType = u.userDocumentType;
      this.corporateId = u.corporateId;
      this.corporateName = u.corporateName;
      this.projects = u.projects;
      this.corporateToken = u.corporateToken;
      this.globalRoles = u.globalRoles;
      this.enabled = u.enabled;
      this.validated = u.validated;
      this.logs = u.logs;
      this.useGlobalRolesForAllProjects = u.useGlobalRolesForAllProjects;
    }
  }
}
