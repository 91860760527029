<div class="content-area" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <mat-card class="main-card card-settings" ngClass.lt-sm="main-card-small">
    <mat-card-header fxLayoutAlign="center center">
      <mat-icon>{{txtIcon}}</mat-icon>
      <h3 class="card-title-h3 card-title">{{cardTitle}}</h3>
      <span fxFlex></span>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <div fxLayoutAlign="center center" class="first-line-admin" fxFlex>
          <h5 class="card-sub-title-h4">{{txtSearchInfo}}</h5>
        </div>
        <div>
          <form [formGroup]="searchFormGroup" (ngSubmit)="doSearch()">
            <div fxLayout="row" fxLayout.lt-sm="column" class="first-line-admin">
              <div fxFlex="25" fxFlex.lt-sm="100">
                <mat-form-field appearance="fill" fxFlex class="search-box-item" ngClass.lt-sm="search-box-item-small">
                  <mat-label>{{txtPlate}}</mat-label>
                  <input [formControl]="plateFormControl" matInput required>
                  <mat-error>{{txtPlatesError}}</mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="25" fxFlex.lt-sm="100" fxLayoutAlign="center start" class="button-containers" fxLayoutAlign.lt-sm="end center">
                <button [disabled]="searchFormGroup.invalid" class="common-button" mat-raised-button color="accent" type="submit">{{txtSearch|uppercase}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div *ngIf="showSearch">
        <div *ngIf="showNoData" fxLayoutAlign="center center" fxFlex class="first-line-admin">
          <h5 class="card-sub-title-h4">{{txtNoData}}</h5>
        </div>
        <div *ngIf="!showNoData" fxFlex>
          <table mat-table [dataSource]="tableDataSource" class="table-size">
            <ng-container matColumnDef="{{txtPlate}}">
              <th mat-header-cell *matHeaderCellDef> {{txtPlate | uppercase}} </th>
              <td mat-cell *matCellDef="let item"> {{item.plate}} </td>
            </ng-container>
            <ng-container matColumnDef="{{txtInfo}}">
              <th mat-header-cell *matHeaderCellDef> {{txtInfo | uppercase}} </th>
              <td mat-cell *matCellDef="let item"> {{item.reason}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="dc"></tr>
            <tr mat-row *matRowDef="let row; columns: dc;"></tr>
          </table>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer *ngIf="showBar">
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
