
<form [formGroup]="vehicleForm">
<div mat-dialog-title fxLayout="row">
  <mat-icon>{{txtIcon}}</mat-icon>
  <h3 class="card-title-h3">{{txtTitle}}</h3>
</div>
  <div mat-dialog-content  class="main-content">
    <div fxLayout="column">
      <div class="centered">
        {{txtConfirmPretext}}
      </div>
      <div class="detail-text centered">
        {{txtName}}
      </div>
      <div class="centered">
        {{txtConfirmPretextId}}
      </div>
      <div class="detail-text centered">
        {{txtDocID}}
      </div>
    </div>
    <div fxLayoutAlign="center center">
      <section [ngClass]="{'hidden': isExit}">
        <mat-slide-toggle (toggleChange)="toggleVehicle()" checked="true" color="primary">{{txtHasVehicle}}</mat-slide-toggle>
      </section>
    </div>
    <div *ngIf="hasVehicle">
      <div fxLayout="column">
        <mat-form-field appearance="fill">
          <mat-label>{{txtVehicleKind}}</mat-label>
          <mat-select [formControl]="kindControl" required>
            <mat-option *ngFor="let item of enumData | keyvalue" [value]="item.value">{{item.value}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{txtPlateOrIdentifier}}</mat-label>
          <input [formControl]="valueControl" matInput required>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="common-button" type="button" (click)="closeMe()" mat-raised-button>{{txtCancel|uppercase}}</button>
    <button class="common-button" type="button" (click)="confirm()" [disabled]="(vehicleForm.invalid && hasVehicle)" mat-raised-button color="accent">{{txtAccept|uppercase}}</button>
  </div>
</form>
