import {UserKindEnum} from "../auth/enums/userKind.enum";

export class ProjectInUserObject {
  projectID: string;
  projectName: string;
  roles: UserKindEnum[];

  constructor(p?: ProjectInUserObject) {
    if (p) {
      this.projectID = p.projectID;
      this.projectName = p.projectName;
      this.roles = p.roles;
    } else {
      this.roles = [];
    }
  }
}
