import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {VehicleTransactionObject} from "../../../core/shared/models/access-vehicle/vehicleTransaction.object";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {AuthService} from "../../../auth/shared/auth.service";
import {VehiclesAccessService} from "../../shared/vehicles-access.service";
import {VehicleObject} from "../../../core/shared/models/control/vehicle.object";
import {MatDialog} from "@angular/material/dialog";
import {CommonDialogDataObject} from "../../../core/shared/models/common/commonDialogData.object";
import {ConfirmDialogComponent} from "../../../core/components/dialogs/confirm-dialog/confirm-dialog.component";
import {ConfirmAndReasonComponent} from "../dialog/confirm-and-reason/confirm-and-reason.component";
import * as moment from "moment";
import {Moment} from "moment";

@Component({
  selector: "app-vehicles-access-details",
  templateUrl: "./vehicles-access-details.component.html",
  styleUrls: ["./vehicles-access-details.component.scss"]
})
export class VehiclesAccessDetailsComponent implements OnInit, OnDestroy {

  txtAccessTitle: string;
  txtAccessSubTitle: string;
  txtDocumentNumber: string;
  txtEnterButton: string;
  txtExitButton: string;
  vehicleFormGroup: FormGroup;
  plateFormControl: FormControl;
  vehicleSubscription: Subscription;
  transactionSubscription: Subscription;
  vehicleAccessTransaction: VehicleTransactionObject;
  currentUser: UserInfoObject;
  @ViewChild("thePlates", {static: false}) thePlates: ElementRef;
  @Output() vehicleEntry: EventEmitter<VehicleTransactionObject> = new EventEmitter<VehicleTransactionObject>();
  @Output() vehicleExit: EventEmitter<VehicleTransactionObject> = new EventEmitter<VehicleTransactionObject>();

  constructor(private authService: AuthService, private vehiclesAccessService: VehiclesAccessService,
              private errorDialog: MatDialog, private confirmDialog: MatDialog, private warningDialog: MatDialog) { }

  ngOnInit(): void {
    this.initTexts();
    this.initForm();
  }

  ngOnDestroy(): void {
    if (this.vehicleSubscription) {
      this.vehicleSubscription.unsubscribe();
    }
    if (this.transactionSubscription) {
      this.transactionSubscription.unsubscribe();
    }
  }

  initTexts(): void {
    this.txtDocumentNumber = "Placa del vehiculo";
    this.txtAccessTitle = "Gestión de control de acceso vehicular";
    this.txtAccessSubTitle = "Escriba correctamente la placa del vehículo para agilizar el acceso, cada documento será validado en tiempo real!";
    this.txtEnterButton = "Marcar Entrada";
    this.txtExitButton = "Marcar Salida";
  }

  initForm(): void {
    this.plateFormControl = new FormControl("", [Validators.required, Validators.minLength(5), Validators.maxLength(8)]);
    this.vehicleFormGroup = new FormGroup({
      plateFormControl: this.plateFormControl
    });
  }

  clearText(): void {
    this.plateFormControl.setValue(null);
  }

  markIn(): void {
    this.getVehicleIn(this.plateFormControl.value.toString());
  }

  markOut(): void {
    this.getVehicleOut(this.plateFormControl.value.toString());
  }

  getVehicleIn(plate: string): void {
    const thePlate = plate.replace(/\s+/g, "").toUpperCase();
    this.currentUser = this.authService.getCurrentUserInfo();
    let initTransaction = false;
    this.vehicleSubscription = this.vehiclesAccessService.loadVehicleByPlate(thePlate)
      .subscribe(
        next => {
          if (next) {
            if (next.length > 0) {
              if (!initTransaction) {
                initTransaction = true;
                console.log("Loading vehicle");
                const theVehicle = new VehicleObject(next[0]);
                this.confirmVehicle(theVehicle, true, false);
              } else {
                this.vehicleSubscription.unsubscribe();
              }

            } else {
              if (!initTransaction) {
                initTransaction = true;
                console.log("Loading vehicle NO");
                const theVehicle = new VehicleObject();
                theVehicle.plate = thePlate;
                this.confirmVehicle(theVehicle, true, true);
              } else {
                this.vehicleSubscription.unsubscribe();
              }
            }
          }

        }
      );
  }

  getVehicleOut(plate: string): void {
    if (!this.currentUser) {
      this.currentUser = this.authService.getCurrentUserInfo();
    }
    const thePlate = plate.replace(/\s+/g, "").toUpperCase();
    this.transactionSubscription = this.vehiclesAccessService
      .loadSingleVehiclesTransactions(thePlate, this.currentUser.corporateId).subscribe(
        next => {
          if (next) {
            if (next.length > 0) {
              // const dialogRef = this.confirmDialog.open()
              const vehicle = new VehicleObject();
              vehicle.plate = next[0].vehiclePlate;
              this.confirmVehicle(vehicle, false, false, next[0]);
            } else {
              this.showNotFoundExitDialog();
            }
          } else {
            this.showNotFoundExitDialog();
          }
        }, error => console.error(error)
      );
  }

  confirmVehicle(vehicle: VehicleObject, isEntering: boolean, isNew?: boolean, exitTransaction?: VehicleTransactionObject): void {
    let showNewVehicleWarning: boolean;
    let showVoidVehicleWarning: boolean;
    const theData = { isEnter: isEntering, plate: vehicle.plate};
    console.log("TheData for dialog", theData, vehicle);
    const confirmDialog = this.confirmDialog.open(ConfirmAndReasonComponent, {
      data: theData,
      maxWidth: "290px"
    });
    confirmDialog.afterClosed().subscribe(
      next => {
        if (next) {
          showNewVehicleWarning = false;
          // Save Transaction;
          if (isEntering) {
            console.log("vehicle Entering", vehicle);
            const transaction: VehicleTransactionObject = new VehicleTransactionObject();
            transaction.isIn = true;
            transaction.inUserName = this.currentUser.userEmail;
            transaction.inUserUID = this.currentUser.userID;
            transaction.inDateTime = moment().unix();
            transaction.vehiclePlate = vehicle.plate;
            transaction.companyID = this.currentUser.corporateId;
            transaction.reason = next.reason;
            transaction.deleted = false;
            transaction.showWarning = false;
            if (isNew) {
              if (!next.emergency) {
                showNewVehicleWarning = true;
              }
            } else {
              showVoidVehicleWarning = false;
              console.log("Emergency", next.emergency);
              if (!next.emergency) {
                if (vehicle.mandatoryPolicyExpirationDateString && vehicle.mandatoryPolicyExpirationDateString.length > 0) {
                  const expDate: Moment = moment(vehicle.mandatoryPolicyExpirationDateString, "DD/MM/YYYY");
                  if (expDate.unix() < moment().unix()) {
                    showVoidVehicleWarning = true;
                  }
                } else {
                  showNewVehicleWarning = true;
                }
                if (vehicle.mandatoryEnvironmentPolicyExpiratonDateString &&
                  vehicle.mandatoryEnvironmentPolicyExpiratonDateString.length > 0) {
                  const expDate: Moment = moment(vehicle.mandatoryEnvironmentPolicyExpiratonDateString, "DD/MM/YYYY");
                  if (expDate.unix() < moment().unix()) {
                    showVoidVehicleWarning = true;
                  }
                } else {
                  showNewVehicleWarning = true;
                }
              }
            }
            if (next.other) {
              transaction.reason += " - " + next.extraInfo;
            }
            if (showNewVehicleWarning || showVoidVehicleWarning) {
              transaction.showWarning = true;
              // TODO: Implementar alertas para el responsamble de los seguros;
              const warningData: CommonDialogDataObject = new CommonDialogDataObject();
              if (showVoidVehicleWarning) {
                warningData.title = "Alerta de Ingreso";
                warningData.content = "El vehiculo tiene documentos vencidos, por favor informelo a la presona responsable";
              } else if (showNewVehicleWarning) {
                warningData.title = "Alerta de Ingreso";
                warningData.content = "El vehiculo ingresa por primera vez y debe presentar documentos, por favor informelo a la presona responsable";
              }
              this.warningDialog.open(ConfirmDialogComponent, {
                data: warningData
              });
            }
            console.log(transaction);
            this.vehiclesAccessService.addVehicleTransaction(transaction).subscribe(
              next1 => {
                if (isNew) {
                  console.log("Salvado", next1);
                  const newVehicle: VehicleObject = new VehicleObject();
                  newVehicle.deleted = false;
                  newVehicle.verified = false;
                  newVehicle.plate = vehicle.plate;
                  newVehicle.mandatoryPolicy = "";
                  newVehicle.mandatoryEnvironmentPolicy = "";
                  newVehicle.mandatoryPolicyExpirationDateString = "01/01/2019";
                  newVehicle.mandatoryPolicy = "01/01/2019";
                  this.vehiclesAccessService.addNewVehicle(newVehicle).subscribe(
                    next2 => {
                      this.vehicleEntry.emit(transaction);
                    }, error => console.error(error)
                  );
                } else {
                  this.vehicleEntry.emit(transaction);
                  this.thePlates.nativeElement.focus();
                  this.plateFormControl.setValue("");
                }
              }, error => console.error(error)
            );
            this.thePlates.nativeElement.focus();
            this.plateFormControl.setValue(null);
          } else {
            const theExitTransaction: VehicleTransactionObject = new VehicleTransactionObject(exitTransaction);
            theExitTransaction.isIn = false;
            theExitTransaction.outUserName = this.currentUser.userEmail;
            theExitTransaction.outUserUID = this.currentUser.userID;
            theExitTransaction.outDateTime = moment().unix();
            theExitTransaction.deleted = false;
            console.log("The Exit t", theExitTransaction);
            this.vehiclesAccessService.updateVehicleTransaction(theExitTransaction.uid, theExitTransaction).subscribe(
              next2 => {
                this.vehicleExit.emit(theExitTransaction);
                this.thePlates.nativeElement.focus();
                this.plateFormControl.setValue("");
              },
              error => console.error(error)
            );
          }
        }
        if (this.transactionSubscription){
          this.transactionSubscription.unsubscribe();
        }
      }
    );
  }

  showNotFoundExitDialog(): void {
    const theData: CommonDialogDataObject = new CommonDialogDataObject();
    theData.content = "Las placas ingresadas no coinciden con las de algún vehículo que haya ingresado, por favor" +
      " verifiquelo o cominiquese con los responsables del ingreso de este vehículo!";
    theData.title = "Placa sin coincidencia";
    theData.icon = "warning";
    const dialog = this.errorDialog.open(ConfirmDialogComponent, {data: theData, maxWidth: "290px"});
    dialog.afterClosed().subscribe(
      next => {
        this.thePlates.nativeElement.focus();
        this.plateFormControl.setValue("");
      }
    );
  }
}
