import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PersonTransactionObject} from "../../../../core/shared/models/access-personel/personTransaction.object";
import {PersonalVehicleKindEnum} from "../../../../core/shared/models/control/enums/personalVehicleKind.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: "app-confirm-and-vehicle",
  templateUrl: "./confirm-and-vehicle.component.html",
  styleUrls: ["./confirm-and-vehicle.component.scss"]
})
export class ConfirmAndVehicleComponent implements OnInit {

  txtIcon: string;
  txtTitle: string;
  txtAccept: string;
  txtCancel: string;
  txtConfirmPretext: string;
  txtConfirmPretextId: string;
  txtDocID: string;
  txtName: string;
  txtHasVehicle: string;
  txtVehicleKind: string;
  txtPlateOrIdentifier: string;
  hasVehicle: boolean;
  isExit: boolean;
  enumData: any;
  selectedKind: PersonalVehicleKindEnum;
  vehicleForm: FormGroup;
  kindControl: FormControl;
  valueControl: FormControl;

  constructor(private dialogRef: MatDialogRef<ConfirmAndVehicleComponent>,
              @Inject(MAT_DIALOG_DATA)private data: { isIn: boolean, transaction: PersonTransactionObject }) { }

  ngOnInit(): void {
    this.initTexts();
    this.initForm();
    this.isExit = !this.data.isIn;
    console.log("enter", this.isExit, this.data.isIn, this.data.transaction);
    this.enumData = PersonalVehicleKindEnum;
    if (!this.data.isIn) {
      this.hasVehicle = false;
    }
  }

  initTexts(): void {
    this.hasVehicle = true;
    this.txtIcon = "follow_the_signs";
    this.txtAccept = "Confirmar";
    this.txtCancel = "Cancelar";
    this.txtConfirmPretextId = `Documentos de identidad:`;
    if (this.data.isIn) {
      this.txtTitle = "Confirmar Ingreso";
      this.txtConfirmPretext = `Confirma el ingreso de:`;
    } else {
      this.txtTitle = "Confirmar Salida";
      this.txtConfirmPretext = `Confirma la salida de:`;
    }
    this.txtName = this.data.transaction.personName;
    this.txtDocID = this.data.transaction.personID;
    this.txtHasVehicle = "Viene en vehiculo?";
    this.txtVehicleKind = "Tipo de vehículo";
    this.txtPlateOrIdentifier = "Placa o identificador";
  }

  initForm(): void {
    this.kindControl = new FormControl("", Validators.required);
    this.valueControl = new FormControl("", Validators.required);
    this.vehicleForm = new FormGroup({
      valueControl: this.valueControl,
      kindControl: this.kindControl
    });
  }

  closeMe(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    if (this.data.isIn) {
      if (this.hasVehicle) {
        const result = {
          hasVehicle: this.hasVehicle,
          kind: this.kindControl.value,
          plates: this.valueControl.value
        };
        this.dialogRef.close(result);
      } else {
        const result = {
          hasVehicle: false
        };
        this.dialogRef.close(result);
      }
    } else {
      this.dialogRef.close(true);
    }

  }

  toggleVehicle(): void {
    this.hasVehicle = !this.hasVehicle;
  }
}
