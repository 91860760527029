<div>
  <form [formGroup]="searchFormGroup" (ngSubmit)="search()">
    <div fxLayout="row" fxLayout.lt-sm="column">
      <mat-form-field fxFlex="25" fxFlex.lt-sm="100" appearance="fill" class="search-space" ngClass.lt-sm="first-line-admin">
        <mat-label>{{txtFromDate}}</mat-label>
        <input [formControl]="fromDateFormControl" (dateChange)="fromDateChanged($event)" [matDatepicker]="fromPicker" matInput required>
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="25" fxFlex.lt-sm="100" appearance="fill" class="search-space">
        <mat-label>{{txtToDate}}</mat-label>
        <input [formControl]="toDateFormControl" [min]="minDate" [matDatepicker]="toPicker" matInput required>
        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="25" fxFlex.lt-sm="100" appearance="fill" class="search-space">
        <mat-label>{{txtByUser}}</mat-label>
        <mat-select [formControl]="byUserFormControl">
          <mat-option *ngFor="let item of users" [value]="item.userEmail">
            {{item.userEmail}} - {{item.userName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div fxFlex fxLayout="column"class="first-line-button" ngClass.lt-sm="first-line-button-small" fxLayoutAlign="start stretch">
        <button  mat-raised-button type="button" (click)="search()" [disabled]="searchFormGroup.invalid" color="primary" >{{txtSearch}}</button>
      </div>
    </div>
  </form>
</div>
