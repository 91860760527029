import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {AngularFireModule} from "@angular/fire";
import {environment} from "../../environments/environment";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {RouterModule} from "@angular/router";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatMenuModule} from "@angular/material/menu";
import {MatTableModule} from "@angular/material/table";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AngularFireStorageModule} from "@angular/fire/storage";
import {MatTooltipModule} from "@angular/material/tooltip";
import {AngularFireAnalyticsModule} from "@angular/fire/analytics";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatRadioModule} from "@angular/material/radio";
import {MatSliderModule} from "@angular/material/slider";
import {MatDialogModule} from "@angular/material/dialog";
import {HttpClientModule} from "@angular/common/http";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {IconsService} from "./shared/icons.service";
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule} from "@angular/material/core";
import { SearchBoxComponent } from "./components/search-box/search-box.component";
import {MatTabsModule} from "@angular/material/tabs";
import { ConfirmDialogComponent } from "./components/dialogs/confirm-dialog/confirm-dialog.component";
import { LogsListComponent } from "./components/logs/logs-list/logs-list.component";
import { LogDetailComponent } from "./components/logs/log-detail/log-detail.component";
import { GenericDialogComponent } from "./components/dialogs/generic-dialog/generic-dialog.component";
import {AngularFireFunctionsModule} from "@angular/fire/functions";
import { SearchAdminComponent } from "./components/search-admin/search-admin.component";
import {MatCardModule} from "@angular/material/card";



@NgModule({
  declarations: [SearchBoxComponent, ConfirmDialogComponent, LogsListComponent, LogDetailComponent,
    GenericDialogComponent,
    SearchAdminComponent],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatDialogModule,
    MatDatepickerModule,
    MatCardModule,
  ],
  exports: [
    SearchBoxComponent,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatTableModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatTooltipModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatRippleModule,
    MatTabsModule,
    SearchAdminComponent,
  ],
  providers: [IconsService, SearchBoxComponent, ConfirmDialogComponent, LogsListComponent, GenericDialogComponent,
    {provide: MAT_DATE_LOCALE, useValue: "es-CO"},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: [ "L", "LL", "LLL", "LLLL", "LT", "LTS" ] },
        display: {
          dateInput: "L",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        }
      },
    },
  ]
})
// @ts-ignore
export class CoreModule { }
