<div fxLayout="column">
  <table *ngIf="dataItems.length > 0" [dataSource]="tableDataSource" class="table-size" mat-table>
    <ng-container matColumnDef="{{txtPermission}}">
      <th  *matHeaderCellDef mat-header-cell>{{txtPermission|uppercase}}</th>
      <td *matCellDef="let item" mat-cell> {{item.name}}</td>
    </ng-container>
    <ng-container matColumnDef="{{txtAction}}">
      <th  *matHeaderCellDef mat-header-cell>{{txtAction|uppercase}}</th>
      <td *matCellDef="let item" mat-cell>
        <button mat-icon-button (click)=" deleteItem(item)"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dc"></tr>
    <tr mat-row *matRowDef="let row; columns: dc;"></tr>
  </table>
  <div class="no-data-table" *ngIf="dataItems.length === 0">
    {{txtNoRoles}}
  </div>
  <div style="padding: 10px">
    <button class="common-button" type="button" color="accent" mat-raised-button (click)="addNewRole()">{{txtAddNew|uppercase}}</button>
  </div>
</div>
