<div mat-dialog-title>
  <div fxLayout="row">
    <mat-icon>{{txtIcon}}</mat-icon>
    <h3 class="card-title-h3">{{txtDialogTitle}}</h3>
    <span fxFlex></span>
  </div>
</div>
<div mat-dialog-content class="dialog-size" ngClass.lt-sm="dialog-size-small">
  <mat-form-field appearance="fill" class="item" fxFlex="100">
    <mat-label>{{txtRole}}</mat-label>
    <mat-select (selectionChange)="changedRole($event)">
      <mat-option *ngFor="let item of theObjects" [value]="item" >{{item.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayoutAlign="end center" class="item">
  <button mat-raised-button type="button" (click)="addRole()" color="primary">{{txtAdd}}</button>
</div>
