import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-log-detail",
  templateUrl: "./log-detail.component.html",
  styleUrls: ["./log-detail.component.scss"]
})
export class LogDetailComponent implements OnInit {

  txtDialogIcon: string;
  txtDialogTitle: string;
  txtClose: string;

  constructor() { }

  ngOnInit(): void {
  }

}
