import {Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subject, Subscription} from "rxjs";
import {CollaboratorObject} from "../../../core/shared/models/persons/collaborator.object";
import {MatTableDataSource} from "@angular/material/table";
import {CollaboratorsService} from "../../shared/collaborators.service";
import {Router} from "@angular/router";
import {SearchObject} from "../../../core/shared/models/common/search.object";
import {QueryObject} from "../../../core/shared/models/common/query.object";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CollaboratorsAddEditComponent} from "../collaborators-add-edit/collaborators-add-edit.component";

@Component({
  selector: "app-collaborators-list",
  templateUrl: "./collaborators-list.component.html",
  styleUrls: ["./collaborators-list.component.scss"]
})
export class CollaboratorsListComponent implements OnInit, OnDestroy {

  searchText;

  cardTitle: string;
  tableDataSource: MatTableDataSource<CollaboratorObject>;
  itemsToShow: CollaboratorObject[] = [];
  dc: string [];
  txtIcon: string;
  txtNewCollaborator: string;
  txtFirstName: string;
  txtSecondName: string;
  txtLastName: string;
  txtSecondLastName: string;
  txtIdentification: string;
  txtActions: string;
  counterText: string;
  noDataText: string;
  innerWidth: number;
  dialogWidth: number;
  querySubscription: Subscription;
  showBar: boolean;
  dcSubject: Subject<string[]> = new Subject<string[]>();

  @HostListener("window:resize", ["$event"])
  onresize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = ["Documento de identidad", "Nombre", "Apellido"];
      this.dialogWidth = this.innerWidth - 50;
      this.dcSubject.next(this.dc);
    } else {
      this.dc = ["Documento de identidad", "Nombre", "Segundo Nombre", "Apellido", "Segundo Apellido"];
      this.dialogWidth = 600;
      this.dcSubject.next(this.dc);
    }
  }

  constructor(private collaboratorsService: CollaboratorsService, private router: Router,
              private addEditDialog: MatDialog) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = ["Documento de identidad", "Nombre", "Apellido"];
      this.dialogWidth = this.innerWidth - 50;
      this.dcSubject.next(this.dc);

    } else {
      this.dc = ["Documento de identidad", "Nombre", "Segundo Nombre", "Apellido", "Segundo Apellido"];
      this.dialogWidth = 600;
      this.dcSubject.next(this.dc);
    }
    this.cardTitle = "Lista de Colaboradores";
    this.txtNewCollaborator = "Nuevo";
    this.tableDataSource = new MatTableDataSource<CollaboratorObject>();
    this.tableDataSource.data = this.itemsToShow;
    this.txtLastName = "Apellido";
    this.txtFirstName = "Nombre";
    this.txtSecondName = "Segundo Nombre";
    this.txtSecondLastName = "Segundo Apellido";
    this.txtIdentification = "Identificación";
    this.counterText = "T. Asignados";
    this.noDataText = "No hay Datos en este momento!";
    this.txtIcon = "contacts";
  }

  ngOnDestroy(): void {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  addEditItem(item?: CollaboratorObject): void {
    let theItem: CollaboratorObject;
    if (item) {
      theItem = item;
    }
    const dialogRef: MatDialogRef<CollaboratorsAddEditComponent> =
      this.addEditDialog.open(CollaboratorsAddEditComponent, {
        panelClass: "dialogWidth",
        autoFocus: true,
        disableClose: true,
        closeOnNavigation: false,
        maxWidth: "100vw",
        data: theItem
      });
  }

  queryDatabase(seachObject: SearchObject): void {
    this.showBar = true;
    const queryObject = new QueryObject();
    switch (seachObject.field) {
      case "Documento de identidad":
        queryObject.field = "personalIdentification";
        break;
      case "Nombre":
        queryObject.field = "personalFirstName";
        break;
      case "Segundo Nombre":
        queryObject.field = "personalSecondName";
        break;
      case "Apellido":
        queryObject.field = "personalLastName";
        break;
      case "Segundo Apellido":
        queryObject.field = "personalSecondLastName";
        break;
      default:
        queryObject.field = null;
        break;
    }
    queryObject.value = seachObject.value;
    if (queryObject.field) {
      this.collaboratorsService.loadCollaboratorsByFilter(queryObject).subscribe(
        next => {
          console.log(next);
          this.itemsToShow = next;
          this.tableDataSource.data = this.itemsToShow;
          this.showBar = false;
        },
        error => console.error(error),
        () => this.showBar = false
      );
    }
  }

}
