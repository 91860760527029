export class VisitorAccessObject {
  name: string;
  documentNumber: string;
  reason: string;
  constructor(v?: VisitorAccessObject) {
    if (v) {
      this.name = v.name;
      this.documentNumber = v.documentNumber;
      this.reason = v.reason;
    }
  }
}
