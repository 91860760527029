import {PersonalAccessCollaboratorObject} from "../access-personel/personal-access-collaborator.object";
import {VehicleAccessTransactionDetailObject} from "../access-vehicle/vehicle-access-transaction-detail.object";

export class AccessTurnObject {
  uid: string;
  userID: string;
  userName: string;
  startDateTime: number;
  endDateTime: number;
  personInTurn: number; // 0;
  personOutTurn: number; // 0;
  vehiclesInTurn: number; // 0;
  vehiclesOutTurn: number; // 0;
  lastXInCollaborators: PersonalAccessCollaboratorObject[]; // []
  lastXOutCollaborators: PersonalAccessCollaboratorObject[]; // []
  lastXInVehicles: VehicleAccessTransactionDetailObject[]; // []
  lastXOutVehicles: VehicleAccessTransactionDetailObject[]; // []
  isOpen: boolean;
  constructor(a?: AccessTurnObject) {
    if (a) {
      if (a.uid) {
        this.uid = a.uid;
      }
      this.userID = a.userID;
      this.userName = a.userName;
      this.startDateTime = a.startDateTime;
      this.endDateTime = a.endDateTime;
      this.personInTurn = a.personInTurn;
      this.personOutTurn = a.personOutTurn;
      this.vehiclesInTurn = a.vehiclesInTurn;
      this.vehiclesOutTurn = a.vehiclesOutTurn;
      this.lastXInCollaborators = a.lastXInCollaborators;
      this.lastXOutCollaborators = a.lastXOutCollaborators;
      this.isOpen = a.isOpen;
    } else {
      this.lastXOutCollaborators = [];
      this.lastXInCollaborators = [];
    }
  }
}
