import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-persons-access-overview",
  templateUrl: "./persons-access-overview.component.html",
  styleUrls: ["./persons-access-overview.component.scss"]
})
export class PersonsAccessOverviewComponent implements OnInit {

  txtIcon: string;
  cardTitle: string;
  showBar: boolean;

  constructor() { }

  ngOnInit(): void {
    this.showBar = false;
    this.initTexts();
  }

  initTexts(): void {
    this.txtIcon = "pan_tool";
    this.cardTitle = "Control de Acceso";
  }

}
