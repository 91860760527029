import {ManagementListKindEnum} from "./enums/managementListKind.enum";
import {ManagementListStatusEnum} from "./enums/managementListStatus.enum";
import {LogObject} from "../common/log.object";
import {ManagementListGroupObject} from "./managementListGroup.object";
import {ManagementListSectionKindEnum} from "./enums/managementListSectionKind.enum";
import {ManagementByUserObject} from "./managementByUser.object";

export class ManagementListObject {
  uid: string;
  name: string;
  dateOfCreation: number;
  dateOfFinish: number;
  items: ManagementListGroupObject[];
  listSectionKind: ManagementListSectionKindEnum;
  settingUid: string;
  isForGroup: boolean;
  usersIdsOnGroup: ManagementByUserObject[];
  kind: ManagementListKindEnum;
  status: ManagementListStatusEnum;
  comment: string;
  useFiles: boolean;
  files: string[];
  completedListMoment: number;
  enabled: boolean;
  deleted: boolean;
  version: string;
  logs: LogObject[];

  constructor(managementList?: ManagementListObject) {
    if (managementList) {
      if (managementList.uid) {
        this.uid = managementList.uid;
      }
      this.name = managementList.name;
      this.dateOfCreation = managementList.dateOfCreation;
      this.dateOfFinish = managementList.dateOfFinish;
      this.items = managementList.items;
      this.listSectionKind = managementList.listSectionKind;
      this.settingUid = managementList.settingUid;
      this.isForGroup = managementList.isForGroup;
      this.usersIdsOnGroup = managementList.usersIdsOnGroup;
      this.kind = managementList.kind;
      this.status = managementList.status;
      this.comment = managementList.comment;
      this.useFiles = managementList.useFiles;
      this.files = managementList.files;
      this.completedListMoment = managementList.completedListMoment;
      this.enabled = managementList.enabled;
      this.deleted = managementList.deleted;
      this.logs = managementList.logs;
    } else {
      this.items = [];
      this.usersIdsOnGroup = [];
      this.status = ManagementListStatusEnum.CREATED;
      this.files = [];
      this.enabled = true;
      this.deleted = false;
      this.logs = [];
    }
  }
}
