import { Injectable } from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {AngularFireStorage} from "@angular/fire/storage";
import {CompanyObject} from "../../core/shared/models/company/companyObject";
import {HttpClient} from "@angular/common/http";
import {from, Observable} from "rxjs";
import {ProjectObject} from "../../core/shared/models/company/project.object";

@Injectable({
  providedIn: "root"
})
export class CompanyService {

  private readonly COMPANY_NAME = "Company";
  private readonly PROJECT_NAME = "Project";
  private readonly COLOMBIA_CITIES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/public/toBePrivate/colombia.json";

  constructor(private angularFirestore: AngularFirestore, private angularFireStorage: AngularFireStorage,
              private httpClient: HttpClient) { }


  getColombiaLocations(): Observable<any> {
    const location = this.angularFireStorage.storage.refFromURL(this.COLOMBIA_CITIES_LOCATION);
    return from(location.getDownloadURL());
  }

  getColombiaLocationsFromURL(url: string): Observable<any> {
    return from(this.httpClient.get(url));
  }


  addCompany(item: CompanyObject): Observable<any>{
      item.logs = item.logs.map(obj => Object.assign({}, obj));
      return from(this.angularFirestore.collection<CompanyObject>(this.COMPANY_NAME).add(Object.assign({}, item)));
  }

  addProject(item: ProjectObject): Observable<any>{
      item.logs = item.logs.map(obj => Object.assign({}, obj));
      return from(this.angularFirestore.collection<ProjectObject>(this.PROJECT_NAME).add(Object.assign({}, item)));
  }

  loadAdminCompanies(): Observable<any>{
    return this.angularFirestore.collection<CompanyObject>(this.COMPANY_NAME, ref => ref
      .where("deleted", "==", false)).valueChanges({idField: "uid" });
  }

  loadSpecificCompany(companyId: string): Observable<any> {
    return this.angularFirestore.collection<CompanyObject>(this.COMPANY_NAME, ref => ref
      .where("uid", "==", companyId)
      .where("deleted", "==", false)
      .limit(1)).valueChanges({idField: "uid"});
  }

  loadProjectFromCompany(companyID: string): Observable<any>{
    return this.angularFirestore.collection<ProjectObject>(this.PROJECT_NAME, ref => ref
      .where("deleted", "==", false)
      .where("companyId", "==", companyID)).valueChanges({idField: "uid" });
  }

  loadSpecificProject(projectId: string): Observable<any> {
    return this.angularFirestore.collection<ProjectObject>(this.PROJECT_NAME, ref => ref
      .where("uid", "==", projectId)
      .limit(1)).valueChanges({idField: "uid"});
  }

  updateCompany(itemId: string, item: CompanyObject): Observable<any> {
    item.logs = item.logs.map(obj => Object.assign({}, obj));
    return from(this.angularFirestore.collection<CompanyObject>(this.COMPANY_NAME)
      .doc(itemId).set(Object.assign({}, item), {merge: true}));
  }

  updateProject(itemId: string, item: ProjectObject): Observable<any> {
    item.logs = item.logs.map(obj => Object.assign({}, obj));
    return from(this.angularFirestore.collection<ProjectObject>(this.PROJECT_NAME)
      .doc(itemId).set(Object.assign({}, item), {merge: true}));
  }
}
