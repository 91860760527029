import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {from, Observable} from "rxjs";
import * as moment from "moment";
import {AngularFireStorage} from "@angular/fire/storage";
import {HttpClient} from "@angular/common/http";
import {UserKindTranslationEnum} from "./models/auth/enums/userKindTranslation.enum";
import {UserKindEnum} from "./models/auth/enums/userKind.enum";
import {UserKindObject} from "./models/auth/UserKind.object";
import {ManagementListObject} from "./models/management/managementList.object";

@Injectable({
  providedIn: "root"
})
export class CoreService {

  private readonly DOC_TYPES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/private/common-files/document-types.json";
  private readonly COLOMBIA_CITIES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/private/common-files/colombia.json";

  constructor(private angularFirestore: AngularFirestore, private angularFireStorage: AngularFireStorage,
              private httpClient: HttpClient) { }

  getEnumKeys<E>(e: E): (keyof E)[] {
    return Object.keys(e) as (keyof E)[];
  }

  getDocumentTypes(): Observable<any> {
    const location = this.angularFireStorage.storage.refFromURL(this.DOC_TYPES_LOCATION);
    return from(location.getDownloadURL());
  }

  getColombiaLocations(): Observable<any> {
    const location = this.angularFireStorage.storage.refFromURL(this.COLOMBIA_CITIES_LOCATION);
    return from(location.getDownloadURL());
  }

  getDocumentTypesFromURL(url: string): Observable<any> {
    return from(this.httpClient.get(url));
  }

  getColombiaLocationsFromURL(url: string): Observable<any> {
    return from(this.httpClient.get(url));
  }

  addItem<T>(item: T, collectionName: string): Observable<any> {
    return from(this.angularFirestore.collection(collectionName).add(item));
  }

  updateItem<T>(itemId: string, item: T, collectionName: string): Observable<any> {
    return from(this.angularFirestore.collection(collectionName).doc(itemId).set(item, {merge: true}));
  }

  loadItems<T>(collectionName: string): Observable<any> {
    return this.angularFirestore.collection<T>(collectionName).valueChanges({idField: "uid"});
  }

  generateUniqueID(): string {
    return moment().format("x");
  }

  loadManagementLists(): Observable<ManagementListObject[]> {
    return this.angularFirestore.collection<ManagementListObject>("ManagementList", ref => ref
      .limit(1))
      .valueChanges({idField: "uid"});
  }
}
