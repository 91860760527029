import {LogObject} from "./log.object";

export class LogDialogDataObject {
  logs: LogObject[];
  title: string;
  constructor(logs: LogObject[], title: string) {
    this.logs = logs;
    this.title = title;
  }
}
