import {Component, Inject, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VehicleAccessReasonsEnum} from "../../../../core/shared/models/control/enums/vehicleAccessReasons.enum";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: "app-confirm-and-reason",
  templateUrl: "./confirm-and-reason.component.html",
  styleUrls: ["./confirm-and-reason.component.scss"]
})
export class ConfirmAndReasonComponent implements OnInit {
  vehicleForm: FormGroup;
  reasonControl: FormControl;
  textReasonControl: FormControl;

  txtIcon: string;
  txtTitle: string;
  txtConfirmPretext: string;
  txtPlate: string;
  txtCancel: string;
  txtAccept: string;
  txtReason: string;
  enumData: any;
  isExit: boolean;
  isOther: boolean;
  isEmergency: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private data: {isEnter: boolean, plate: string},
              private dialogRef: MatDialogRef<ConfirmAndReasonComponent>) { }

  ngOnInit(): void {
    this.enumData = VehicleAccessReasonsEnum;
    this.isOther = false;
    this.initForm();
    this.initTexts();
    console.log("isExit", this.data, this.data.isEnter);
  }

  initTexts(): void {
    this.txtIcon = "";
    this.txtTitle = "";
    this.txtConfirmPretext = "";
    this.txtIcon = "local_shipping";
    if (this.data.isEnter){
      this.txtTitle = "Confimar Ingreso";
      this.txtConfirmPretext = "Confirma la entrada del Vehiculo con placas:";
    } else {
      this.txtTitle = "Confimar Salida";
      this.txtConfirmPretext = "Confirma la salida del vehiculo con placas:";
    }
    this.txtCancel = "Cancelar";
    this.txtAccept = "Confirmar";
    this.txtReason = "Motivo de la visita";
    this.txtPlate = this.data.plate;
    this.isExit = !this.data.isEnter;
  }

  initForm(): void {
    this.reasonControl = new FormControl("", Validators.required);
    this.textReasonControl = new FormControl("", [Validators.required, Validators.minLength(8)]);
    this.vehicleForm = new FormGroup({
      reasonControl: this.reasonControl,
      textReasonControl: this.textReasonControl
    });
  }

  closeMe(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    let theExtraInfo = "";
    if (this.isOther) {
      theExtraInfo = this.textReasonControl.value;
    }
    let result: any;
    if (!this.isExit) {
      result = {reason: this.reasonControl.value, other: this.isOther, extraInfo: theExtraInfo, emergency: this.isEmergency};
    } else {
      result = true;
    }
    this.dialogRef.close(result);
  }

  processOtherValue($change: MatSelectChange): void {
    this.isOther = $change.value === "OTRO";
    this.isEmergency = $change.value === "EMERGENCIA";
  }

}
