import {Component, OnDestroy, OnInit} from "@angular/core";
import {CompanyObject} from "../../../core/shared/models/company/companyObject";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Observable, Subscription} from "rxjs";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {User} from "firebase";
import {DemoDialogComponent} from "../demo-dialog/demo-dialog.component";
import {PersonalVehicleKindEnum} from "../../../core/shared/models/control/enums/personalVehicleKind.enum";
import {InventoryValueKindEnum} from "../../../core/shared/models/inventory/enums/inventoryValueKind.enum";

@Component({
  selector: "app-demo-add-edit",
  templateUrl: "./demo-add-edit.component.html",
  styleUrls: ["./demo-add-edit.component.scss"]
})
export class DemoAddEditComponent implements OnInit, OnDestroy {

  subTitle = "Bienvenidos - Información Adicional";
  title = "Gestión integral de Fondos de Empleados";
  userForm: FormGroup;
  username: FormControl;
  fullName: FormControl;
  docType: FormControl;
  docNumber: FormControl;
  company: FormControl;
  companyID: FormControl;
  emailText = "Correo electrónico";
  fullNameText = "Nombre Completo";
  documentType = "Tipo de documento";
  documentNumber = "Número de documento";
  companyName = "Empresa a la que pertenece";
  companyIdentificacion = "VHUR";
  requiredEmail = "Correo electrónico requerido";
  requiredFullName = "Nombre completo requerido";
  requiredDoctype = "El tipo de documento es obligatorio";
  requiredDocNumber = "Ingrese el # de su documento de identidad";
  requiredCompany = "Seleccione la empresa a la que pertenece";
  requiredCompanyId = "Ingrese el VHUR";
  showDataWarningText = "Debe ingresar los datos de usuario para acceder a la aplicación!";
  showValidateWarningText = "Debe validar su correo electrónico para acceder a la aplicación!";
  docNumberShort = "El No. de documento es muy corto";
  docNumberLong = "El No. de documento es muy largo";
  shortFullName = "El Nombre es muy corto";
  longFullName = "El Nombre es muy corto";
  docTypeOptions = [];
  cancelButton = "Cancelar";
  sendButton = "Actualizar Información";
  currentUserInfo: UserInfoObject;
  showDataWarning: boolean;
  showEmailWarning: boolean;
  companies: Observable<CompanyObject[]>;
  itemDeArray: any;

  currentUserSub: Subscription;
  loadUserSub: Subscription;
  saveUpdateSub: Subscription;
  theUser: User;

  constructor(private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.itemDeArray = InventoryValueKindEnum;
    this.showDataWarning = false;
    this.showEmailWarning = false;
    this.username = new FormControl("", [Validators.required, Validators.email]);
    this.fullName = new FormControl("", [Validators.required, Validators.minLength(10),
      Validators.maxLength(50)]);
    this.docNumber = new FormControl("", [Validators.required, Validators.minLength(6),
      Validators.maxLength(25)]);
    this.docType = new FormControl("", Validators.required);
    this.company = new FormControl("", Validators.required);
    this.companyID = new FormControl("", Validators.required);
    this.userForm = new FormGroup(
      {
        username: this.username,
        fullName: this.fullName,
        docNumber: this.docNumber,
        docType: this.docType,
        company: this.company,
        companyID: this.companyID
      }
    );
    this.docTypeOptions = [
      { name: "Cedula de ciudadanía", value: "C.C." },
      { name: "Cedula de extranjería", value: "C.E." },
      { name: "Permiso especial de trabajo", value: "P.E.T." },
    ];
    // this.companies = this.authService.getCompaniesForUserInfo().pipe(); //no existe para este ejemplo
    this.loadUser();
  }

  ngOnDestroy(): void {
    if (this.currentUserSub) {
      this.currentUserSub.unsubscribe();
    }
    if (this.loadUserSub) {
      this.loadUserSub.unsubscribe();
    }
  }

  loadUser(): void {
    // No Carga informacion Solo como ejemplo
    /*
    this.currentUserSub = this.authService.angularFireAuth.authState.subscribe(
      next => {
        if (next) {
          this.theUser = next;
          this.currentUserInfo = new UserInfoObject();
          this.currentUserInfo.userID = next.uid;
          if (next.email) {
            this.username.setValue(next.email);
            this.username.disable();
          }
          if (next.displayName) {
            this.fullName.setValue(next.displayName);
          }
          if (!next.emailVerified) {
            this.showEmailWarning = true;
          }
          // No existe AuthService
          this.loadUserSub = this.authService.getCurrentUser(next.uid).subscribe(
            next2 => {
              if (next2) {
                this.currentUserInfo = next2;
                this.docType.setValue(this.currentUserInfo.userDocumentType);
                this.docNumber.setValue(Number(this.currentUserInfo.userDocumentNumber));
              } else {
                this.showDataWarning = true;
              }
            },
            error => console.error("Error", error),
            () => { console.log("Completed"); }
          );
        } else {
          this.router.navigate([""]);
        }
      }, error => {
        console.error("Algo salió mal", error);
      }
    );
     */
  }

  submitUserInfo(value: any): void {
    if (!this.currentUserInfo) {
      this.currentUserInfo = new UserInfoObject();
    }
    this.currentUserInfo.userID = this.theUser.uid;
    this.currentUserInfo.userDocumentNumber = this.docNumber.value.toString();
    this.currentUserInfo.userDocumentType = this.docType.value;
    this.currentUserInfo.corporateName = this.company.value;
    this.currentUserInfo.corporateId = this.companyID.value;
    this.currentUserInfo.validated = false;
    this.currentUserInfo.enabled = true;
    // this.currentUserInfo.kind = 1;
    console.log(this.currentUserInfo);
    // No guarda Info, solo como ejemplo
    /*
    this.saveUpdateSub = this.authService.saveUpdateUserInfo(this.currentUserInfo).subscribe(
      () => {
        if (this.theUser) {
          from(this.theUser.updateProfile({displayName: this.fullName.value})).subscribe(
            () => {
              if (!this.theUser.emailVerified) {
                from(this.theUser.sendEmailVerification()).subscribe(
                  () => this.showSuccessDialog(this.showEmailWarning)
                  , error => {
                    console.error("Error", error);
                    this.showErrorDialog();
                  }
                );
              } else {
                this.showSuccessDialog(false);
              }
            }, error => {
              console.error("Error", error);
              this.showErrorDialog();
            }
          );
        }
      },
      error => {
        console.error("Error", error);
        this.showErrorDialog();
      }
    );
     */
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.userForm.controls[controlName].hasError(errorName);
  }

  goBack(): void {
    console.log("Going Back");
    // Solo como ejemplo
    /*
    from(this.authService.logout()).subscribe(
      next => this.router.navigate(["/"])
    );
     */
  }

  showSuccessDialog(shouldVerify: boolean): void {
    let verify = "";
    const verifyText = ", No olvide verificar su correo para acceder a la aplicación! ";
    if (shouldVerify) {
      verify = verifyText;
    }
    const dialogData = {
      title: "Actualización exitosa",
      text: "La información se actualizó correctamente" + verify,
      button: "Aceptar",
    };
    const configDialog: MatDialogConfig = new MatDialogConfig<any>();
    configDialog.data = dialogData;
    configDialog.width = "350px";
    const dialogRef = this.dialog.open(DemoDialogComponent, configDialog);
    dialogRef.afterClosed().subscribe(
      next => {
        console.log("Devuelve al inicio");
        // this.authService.angularFireAuth.signOut();
        this.router.navigate([""]);
      }
    );
  }

  showErrorDialog(): void {
    const dialogData = {
      title: "Algo salió mal",
      text: "Ha sucedido un error, por favor intentelo nuevamente",
      button: "Aceptar",
    };
    const configDialog: MatDialogConfig = new MatDialogConfig<any>();
    configDialog.data = dialogData;
    configDialog.width = "350px";
    const dialogRef = this.dialog.open(DemoDialogComponent, configDialog);
    dialogRef.afterClosed().subscribe(
      next => {
        this.router.navigate([""]);
      }
    );
  }

}
