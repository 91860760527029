import {Component, HostListener, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatSidenav} from "@angular/material/sidenav";
import {Subject, Subscription} from "rxjs";
import {IconsService} from "../../../core/shared/icons.service";
import {AuthService} from "../../../auth/shared/auth.service";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";

@Component({
  selector: "app-main-area",
  templateUrl: "./main-area.component.html",
  styleUrls: ["./main-area.component.scss"]
})

export class MainAreaComponent implements OnInit, OnDestroy {

  @ViewChild("mainSidenav", {static: false}) theSidenav: MatSidenav;
  innerWidth: number;
  theSideNavMode: string;
  theToolbarButtonSubject: Subject<boolean> = new Subject<boolean>();
  theCurrentUserSubject: Subject<UserInfoObject> = new Subject<UserInfoObject>();
  theCurrentUserSubscription: Subscription;
  theCurrentUser: UserInfoObject;
  @HostListener("window:resize", ["$event"])
  onresize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 600) {
      this.theSideNavMode = "over";
    } else {
      this.theSideNavMode = "side";
    }
  }
  constructor(private iconsService: IconsService, private authService: AuthService) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.iconsService.registerIcons();
    if (this.innerWidth < 600) {
      this.theSideNavMode = "over";
    } else {
      this.theSideNavMode = "side";
      if (this.authService.getCurrentUserInfo()) {
        this.theCurrentUser = this.authService.getCurrentUserInfo();
        this.theCurrentUserSubject.next(this.theCurrentUser);
      }
    }
    this.theCurrentUserSubscription = this.authService.theCurrentUserInfoSubject.asObservable().subscribe(
      next => {
        this.theCurrentUser = next;
        this.theCurrentUserSubject.next(next);
      },
      error => console.error("No Text User", error)
    );
  }

  ngOnDestroy(): void {
    if (this.theCurrentUserSubscription) {
      this.theCurrentUserSubscription.unsubscribe();
    }
  }

  startClosing(): void {
    this.theToolbarButtonSubject.next(true);
  }

  startOpening(): void {
    this.theToolbarButtonSubject.next(false);
  }

  toggleMenu(): void {
    this.theSidenav.toggle();
  }

  clickedItem(): void {
    if (this.theSideNavMode === "over") {
      this.theSidenav.close();
    }
  }
}
