import { Component, OnInit } from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";
import validate = WebAssembly.validate;
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-users-add-edit",
  templateUrl: "./users-add-edit.component.html",
  styleUrls: ["./users-add-edit.component.scss"]
})
export class UsersAddEditComponent implements OnInit {

  txtItemIcon: string;
  txtDialogTitle: string;
  txtCancel: string;
  txtAccept: string;
  txtTermsAndCondition: string;
  txtAcceptConditions: string;
  txtEmail: string;
  txtRequiredEmail: string;
  txtRequiredTrue: string;
  txtTermsText: string;
  txtPrivacytext: string;
  txtTermsLink: string;
  txtPrivacyLink: string;
  usersFormGroup: FormGroup;
  emailControl: FormControl;
  acceptControl: FormControl;

  constructor(private dialogRef: MatDialogRef<UsersAddEditComponent>) { }

  ngOnInit(): void {
    this.initTexts();
    this.initForm();
  }

  initTexts(): void {
    this.txtDialogTitle = "Crear usuario";
    this.txtItemIcon = "supervised_user_circle";
    this.txtTermsAndCondition = "Los usuarios son creados de acuerdo a nuestros terminos y condiciones y nuestras políticas de privacidad.";
    this.txtAcceptConditions = "Acepto los terminos y condiciones.";
    this.txtTermsLink = "#";
    this.txtPrivacyLink = "#";
    this.txtEmail = "Correo electrónico";
    this.txtRequiredEmail = "¡Debe escribir un correo electrónico!";
    this.txtAccept = "Aceptar";
    this.txtCancel = "Cancelar";
    this.txtTermsText = "Nuestros términos y condiciones.";
    this.txtPrivacytext = "Nuestra política de privacidad.";
  }

  initForm(): void {
    this.emailControl = new FormControl("", [Validators.required, Validators.email]);
    this.acceptControl = new FormControl("", Validators.requiredTrue);
    this.usersFormGroup = new FormGroup({
        emailControl: this.emailControl,
      acceptControl: this.acceptControl
      });
  }

  save(): void {

  }

  closeDialog(): void
  {
    this.dialogRef.close();
  }

  changedAccept(event: MatCheckboxChange): void {
    console.log(event);
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.usersFormGroup.controls[controlName].hasError(errorName);
  }

}
