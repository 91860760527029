import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-inventory-transaction-out",
  templateUrl: "./inventory-transaction-out.component.html",
  styleUrls: ["./inventory-transaction-out.component.scss"]
})
export class InventoryTransactionOutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
