<div mat-dialog-title>
  <mat-icon>{{txtDialogIcon}}</mat-icon>
  <h3 class="card-title-h3">{{txtDialogTitle}}</h3>
</div>
<div mat-dialog-content>
  <table mat-table [dataSource]="tableDataSource" class="table-size">
    <ng-container matColumnDef="fecha">
      <th mat-header-cell *matHeaderCellDef> {{txtDate | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{momentToString(item.dateTimeMoment)}} </td>
    </ng-container>
    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef> {{txtEventKind | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{mapKid(item.eventKind)}} </td>
    </ng-container>
    <ng-container matColumnDef="usuario">
      <th mat-header-cell *matHeaderCellDef> {{txtUser | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{item.madeByUser}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dc"></tr>
    <tr mat-row (click)="showDetails(row)" *matRowDef="let row; columns: dc;"></tr>
  </table>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button type="button" class="common-button" mat-raised-button color="primary">{{txtClose}}</button>
</div>
