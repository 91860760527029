import { Injectable } from "@angular/core";
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from "@angular/fire/firestore";
import {from, Observable, Subject, Subscription} from "rxjs";
import {AngularFireAuth} from "@angular/fire/auth";
import * as firebase from "firebase/app";
import {LoginObject} from "../../core/shared/models/auth/login.object";
import {UserInfoObject} from "../../core/shared/models/auth/userInfo.object";
import {CompanyObject} from "../../core/shared/models/company/companyObject";

@Injectable({
  providedIn: "root"
})
export class AuthService {

  private currentUser: firebase.User;
  private currentUserInfo: UserInfoObject;
  theCurrentUserInfoSubject: Subject<UserInfoObject> = new Subject<UserInfoObject>();

  constructor(public readonly angularFireAuth: AngularFireAuth, public fs: AngularFirestore) {
    this.angularFireAuth.authState.subscribe(
      next => {
        this.currentUser = next;
        console.log("CU:", this.currentUser);
        if (this.currentUser && this.currentUser.uid) {
          this.loadCurrentUserInfo(this.currentUser.uid);
        }
      }
    );
  }

  loadCurrentUserInfoSubscription(): Observable<any> {
    return this.fs.collection<UserInfoObject>("Usuarios").doc(this.currentUser.uid).valueChanges();
  }

  loadCurrentUserInfo(uid: string): void {
    this.fs.collection<UserInfoObject>("Usuarios").doc(uid).valueChanges().subscribe(
      next => {
        console.log("Next Service", next);
        const user: UserInfoObject = new UserInfoObject(next as UserInfoObject);
        user.userID = uid;
        this.currentUserInfo = user;
        this.theCurrentUserInfoSubject.next(user);
      },
      error => console.error("Error loading user", error)
    );
  }

  getCurrentUserInfo(): UserInfoObject {
    return this.currentUserInfo;
  }

  getCurrentUserData(): firebase.User|null {
    if (this.currentUser) {
      return this.currentUser;
    }
    return null;
  }

  isUserAuth(): boolean {
    console.log("current user", this.currentUser);
    return this.currentUser !== null;
  }

  logout(): Observable<any> {
    this.currentUser = null;
    return from(this.angularFireAuth.signOut());
  }

  loginWithGoogle(): Observable<firebase.auth.UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    provider.addScope("name");
    provider.addScope("phone");
    return from(this.angularFireAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider()));
  }

  registerEmailPassword(login: LoginObject): Observable<firebase.auth.UserCredential> {
    return from (this.angularFireAuth.createUserWithEmailAndPassword(login.userName, login.password));
  }

  verifyRegisterEmail(): Observable<any> {
    if (this.currentUser) {
      return from (this.currentUser.sendEmailVerification());
    }
    return null;
  }

  loginWithUsernameAndPassword(login: LoginObject): Observable<firebase.auth.UserCredential> {
    return from(this.angularFireAuth.signInWithEmailAndPassword(login.userName, login.password));
  }

  sendPasswordResetEmail(emailAddress: string): Observable<void> {
    return from(this.angularFireAuth.sendPasswordResetEmail(emailAddress));
  }

  getCurrentUser(uid: string): Observable<UserInfoObject> {
    let userInfoDoc: AngularFirestoreDocument<UserInfoObject>;
    userInfoDoc = this.fs.doc<UserInfoObject>(`Usuarios/${uid}`);
    return userInfoDoc.valueChanges();
  }

  getCompaniesForUserInfo(): Observable<CompanyObject[]> {
    let companiesCol: AngularFirestoreCollection<CompanyObject>;
    companiesCol = this.fs.collection<CompanyObject>("Company");
    return  companiesCol.valueChanges();
  }

  saveUpdateUserInfo(userInfo: UserInfoObject): Observable<any> {
    return from(this.fs.doc(`users/${userInfo.userID}`).set(Object.assign({}, userInfo)));
  }
}
