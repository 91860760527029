<div fxLayout="column">
  <div fxLayout="column" fxFlex *ngIf="!isInTurn">
    <div class="first-line-admin">
      <p style="text-align: center">{{txtPleaseInitTurn}}</p>
    </div>
    <div fxLayoutAlign="center center"style="padding: 10px">
      <button mat-raised-button class="common-button custom-button"
              [disabled]="loadingInfo" (click)="startMyTurn()" color="accent"
              type="button">{{txtInitTurn|uppercase}}</button>
    </div>
  </div>
  <div *ngIf="isInTurn" fxLayout="row" fxLayout.lt-sm="column">
    <div fxLayoutAlign="center center" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayout.lt-sm="column"
         fxLayoutAlign.lt-sm="center stretch" class="my-tabs-buttons">
      <button #personal mat-raised-button type="button" color="primary" class="common-button" (click)="selectMe('personal')">
        <mat-icon>{{txtPersonalAccessIcon}}</mat-icon>{{txtPersonalAccessLabel|uppercase}}
      </button>
    </div>
    <div fxLayoutAlign="center center" fxFlex="50" fxFlex.lt-sm="100" fxLayout="row" fxLayout.lt-sm="column"
         fxLayoutAlign.lt-sm="center stretch" class="my-tabs-buttons">
      <button #vehicles mat-raised-button type="button" class="common-button" (click)="selectMe('vehicles')">
        <mat-icon>{{txtVehicleAccessIcon}}</mat-icon>{{txtVehicleAccessLabel|uppercase}}</button>
    </div>
  </div>
  <mat-tab-group #theTabGroup *ngIf="isInTurn" class="tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>{{txtPersonalAccessIcon}}</mat-icon>
        - {{txtPersonalAccessLabel|uppercase}}
      </ng-template>
      <app-persons-access-details (personalEntry)="updatePersonalTransaction(true, $event)"
                                  (personalExit)="updatePersonalTransaction(false, $event)"
                                  *ngIf="isInTurn"></app-persons-access-details>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>{{txtVehicleAccessIcon}}</mat-icon>
        - {{txtVehicleAccessLabel|uppercase}}
      </ng-template>
      <app-vehicles-access-details (vehicleEntry)="updateVehicleTransaction(true, $event)"
                                   (vehicleExit)="updateVehicleTransaction(false, $event)"></app-vehicles-access-details>
    </mat-tab>
  </mat-tab-group>
  <div fxLayout="row" fxLayout.lt-md="column" *ngIf="isInTurn">
    <mat-divider class="first-line-admin"></mat-divider>
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex class="first-line-admin">
      <div fxLayoutAlign="center center" class="card-title-h3">
        {{txtCurrentShift}}
      </div>
      <div fxLayoutAlign="center center" class="time-text">
        {{txtCurrentTime}}
      </div>
      <div fxLayoutAlign="center center" class="date-text">
        {{txtCurrentDate}}
      </div>
    </div>
    <div fxLayout="column" fxFlex="66" fxFlex.lt-sm="100">
      <div fxLayout="column">
        <div fxLayoutAlign="center center">
          <h3 class="card-title-h3">
            {{txtPersonalAccessLabel}}
          </h3>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column">
          <div fxLayout="column" fxFlex>
            <div fxLayoutAlign="center center">
              {{txtCurrentShiftPeopleIN}}
            </div>
            <div fxLayoutAlign="center center" class="time-text">
              {{txtCurrentShiftCountPeopleIN}}
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div fxLayoutAlign="center center">
              {{txtCurrentShiftPeopleOUT}}
            </div>
            <div fxLayoutAlign="center center" class="time-text">
              {{txtCurrentShiftCountPeopleOUT}}
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="66">
        <div fxLayoutAlign="center center">
          <h3 class="card-title-h3">
            {{txtVehicleAccessLabel}}
          </h3>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column">
          <div fxLayout="column" fxFlex>
            <div fxLayoutAlign="center center">
              {{txtCurrentShiftVehiclesIN}}
            </div>
            <div fxLayoutAlign="center center" class="time-text">
              {{txtCurrentShiftCountVehiclesIN}}
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div fxLayoutAlign="center center">
              {{txtCurrentShiftVehiclesOUT}}
            </div>
            <div fxLayoutAlign="center center" class="time-text">
              {{txtCurrentShiftCountVehiclesOUT}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div fxLayout="column" fxFlex *ngIf="isInTurn">
    <div class="first-line-admin" >
      <p style="text-align: center">{{txtPleaseCloseYourTurn}}</p>
    </div>
    <div fxLayoutAlign="center center">
      <button mat-raised-button class="common-button custom-button" (click)="endMyTurn()" color="accent" type="button">{{txtEndTurn|uppercase}}</button>
    </div>
  </div>
</div>
