import { Component, OnInit } from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {VehicleForListObject} from "../../../core/shared/models/access-vehicle/vehicle-for-list.object";
import {CommonAreaService} from "../../shared/common-area.service";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: "app-search-plates",
  templateUrl: "./search-plates.component.html",
  styleUrls: ["./search-plates.component.scss"]
})
export class SearchPlatesComponent implements OnInit {

  txtIcon: string;
  cardTitle: string;
  txtPlate: string;
  txtSearch: string;
  txtInfo: string;
  txtPlatesError: string;
  plateFormControl: FormControl;
  searchFormGroup: FormGroup;
  showBar: boolean;
  showNoData: boolean;
  showSearch: boolean;
  txtNoData: string;
  txtSearchInfo: string;
  data: VehicleForListObject[] = [];
  tableDataSource: MatTableDataSource<VehicleForListObject>;
  dc: string[] = [];

  constructor(private commonAreaService: CommonAreaService) { }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<VehicleForListObject>();
    this.initTexts();
    this.initForm();
    this.showBar = false;
    this.showNoData = true;
    this.showSearch = false;
  }

  initTexts(): void {
    this.cardTitle = "Buscar vehiculo";
    this.txtIcon = "search";
    this.txtPlate = "Placas";
    this.txtSearch = "Buscar";
    this.txtInfo = "Info.";
    this.txtPlatesError = "Debe ingresar los datos de las placas";
    this.txtNoData = "Buscando Placas";
    this.txtSearchInfo = "Ingrese las placas del vehiculo sin espacions o guiones";
    this.dc = [this.txtPlate, this.txtInfo];
  }

  initForm(): void {
    this.plateFormControl = new FormControl("", [Validators.required, Validators.minLength(5)]);
    this.searchFormGroup = new FormGroup({plateFormControl: this.plateFormControl});
  }

  doSearch(): void {
    const thePlate = this.plateFormControl.value.toString().replace(/\s+/g, "").toUpperCase();
    this.showSearch = true;
    this.commonAreaService.loadVehicleByPlate(thePlate).subscribe(
      next => {
        if (next && next.length > 0) {
          for (const item of next) {
            const newItem: VehicleForListObject = new VehicleForListObject();
            newItem.plate = item.vehiclePlate;
            newItem.reason = item.reason;
            this.data.push(newItem);
          }
          if (this.data && this.data.length > 0) {
            this.tableDataSource.data = this.data;
            this.showNoData = false;
          } else {
            this.showNoData = true;
            this.txtNoData = "No se encontró ningún vehiculo con las placas ingresadas, por favor intentelo nuevamente";
          }
        } else {
          this.commonAreaService.getInActiveTransactionswithVehicles().subscribe(
            next2 => {
              if (next2 && next2.length > 0) {
                for (const person of next2) {
                  if (person.vehicleInfo && person.vehicleInfo.plate && person.vehicleInfo.plate.length > 0) {
                    if (person.vehicleInfo.plate === thePlate) {
                      const newItem: VehicleForListObject = new VehicleForListObject();
                      newItem.plate = person.vehicleInfo.plate;
                      if (person.isVisitor) {
                        newItem.reason = "De Visitante: " + person.personName + " - " + person.visitor.reason;
                      } else {
                        newItem.reason = "Cargo: " + person.personPosition;
                      }
                      this.data.push(newItem);
                    }
                  }
                }
                if (this.data && this.data.length > 0) {
                  this.tableDataSource.data = this.data;
                  this.showNoData = false;
                } else {
                  this.showNoData = true;
                  this.txtNoData = "No se encontró ningún vehiculo con las placas ingresadas, por favor intentelo nuevamente";
                }
              }
            }
          );
        }
      }, error => console.error(error)
    );
  }

}
