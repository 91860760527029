import { Injectable } from "@angular/core";
import {MenuItem, MenuSubItem} from "../../core/shared/models/common/menu.object";
import {UserKindEnum} from "../../core/shared/models/auth/enums/userKind.enum";

@Injectable({
  providedIn: "root"
})
export class DashboardService {

  MENU: MenuItem[] = [];

  constructor() { }

  buildMenu(userRoles: UserKindEnum[]): void {
    const tempMenus: MenuItem[] = [];
    const subCollaboatorsItem: MenuSubItem = new MenuSubItem("Colaboradores", "collaborators", "contacts", 5, true);
    const companyItem: MenuItem = new MenuItem("COL", "Empresa", "company", "corporate_fare", [
      subCollaboatorsItem
    ], 130, true);
    const personalOverviewSubItem: MenuSubItem = new MenuSubItem("Gestión de Personal", "overview", "assignment_turned_in", 21, true);
    const personalReportSubItem: MenuSubItem = new MenuSubItem("Informes de Gestión", "report", "list_alt", 20, true);
    const personalResumeSubItem: MenuSubItem = new MenuSubItem("Resumen de Actividad", "resume", "sync_alt", 20, true);
    const personelAccessItem: MenuItem = new MenuItem("PER", "Acceso Personal", "personal",
      "follow_the_signs", [ personalOverviewSubItem, personalReportSubItem, personalResumeSubItem], 20, true);
    const vehicleOverviewSubItem: MenuSubItem = new MenuSubItem("Gestión de Vehículos", "overview", "assignment_turned_in", 21, true);
    const vehicleReportSubItem: MenuSubItem = new MenuSubItem("Informes de Gestión", "report", "list_alt", 20, true);
    const vehicleResumeSubItem: MenuSubItem = new MenuSubItem("Resumen de Actividad", "resume", "sync_alt", 20, true);
    const vehiclesAccessItem: MenuItem = new MenuItem("VEH", "Acceso Vehículos", "vehicles", "local_shipping",
      [vehicleOverviewSubItem, vehicleReportSubItem, vehicleResumeSubItem], 30, true);
    const accessOverviewSubItem: MenuSubItem = new MenuSubItem("Gestión de Acceso", "overview", "assignment_turned_in", 21, true);
    const accessReportSubItem: MenuSubItem = new MenuSubItem("Informes de Gestión", "report", "list_alt", 20, true);
    const accessResumeSubItem: MenuSubItem = new MenuSubItem("Resumen de Actividad", "resume", "sync_alt", 20, true);
    const accessAccessItem: MenuItem = new MenuItem("ACC", "Control de Acceso", "common-access", "pan_tool",
      [accessOverviewSubItem, accessReportSubItem, accessResumeSubItem], 30, true);
    // Common Area
    const subSearchItem: MenuSubItem = new MenuSubItem("Buscar vehículo", "search-plate", "search", 30, true);
    const commonAreaItem: MenuItem = new MenuItem("COM", "Ayudas", "common-area", "help_center",
      [ subSearchItem ], 30, true);
    // Settings
    const subUsersItem: MenuSubItem = new MenuSubItem("Usuarios", "users", "supervised_user_circle", 130, true);
    const subCompaniesAdminItem: MenuSubItem = new MenuSubItem("Admin - Empresas", "admin-companies", "domain", 150, true);
    const settingsItem: MenuItem = new MenuItem("SET", "Configuración", "settings", "settings",
      [ subUsersItem, subCompaniesAdminItem ], 130, true);
    tempMenus.push(personelAccessItem, vehiclesAccessItem, accessAccessItem, commonAreaItem, companyItem, settingsItem);
    const menuData: MenuItem[] = [];
    for (const item of tempMenus) {
      if (this.validateItems(item, userRoles)) {
        menuData.push(this.validateItems(item, userRoles));
      }
    }
    this.MENU = this.verifyUnifiedAccess(menuData);
  }

  verifyUnifiedAccess(theItems: MenuItem[]): MenuItem[] {
    let personalAccess = false;
    let vehicleAccess = false;
    let items: MenuItem[] = [];
    for (const item of theItems) {
      if (item.section === "PER") {
        personalAccess = true;
      }
      if (item.section === "VEH") {
        vehicleAccess = true;
      }
    }
    if (personalAccess && vehicleAccess) {
      for (const item of theItems) {
        if (item.section === "PER") {
          // console.log("PER");
        } else if (item.section === "VEH") {
          // console.log("VEH");
        } else {
          items.push(item);
        }
      }
    } else  {
      items = theItems;
    }
    return items;
  }

  validateItems(item: MenuItem, roles: number[]): MenuItem | null {
    let result: MenuItem | null;
    for (const role of roles) {
      const subItems: MenuSubItem[] = [];
      const roleMinus5 = role - 5;
      if (role >= 150) {
        result = new MenuItem(item.section, item.name, item.link, item.icon, [], item.value, item.useMaterialIcon, true);
        if (item.subItems && item.subItems.length > 0) {
          for (const subItem of item.subItems) {
            const theSubItem: MenuSubItem = new MenuSubItem(subItem.name, subItem.link, subItem.icon,
              subItem.value, subItem.useMaterialIcon, true);
            result.subItems.push(theSubItem);
          }
        }
      } else {
        if (item.value > roleMinus5 && item.value <= role) {
          result = new MenuItem(item.section, item.name, item.link, item.icon, [], item.value, item.useMaterialIcon, true);
          if (item.subItems && item.subItems.length > 0) {
            for (const subItem of item.subItems) {
              if (subItem.value <= role) {
                const theSubItem: MenuSubItem = new MenuSubItem(subItem.name, subItem.link, subItem.icon,
                  subItem.value, subItem.useMaterialIcon, true);
                result.subItems.push(theSubItem);
              }
            }
          }
        }
      }
    }
    return result;
  }
}
