<div mat-dialog-title>
  <div fxLayout="row">
    <mat-icon>{{txtIcon}}</mat-icon>
    <h3 class="card-title-h3">{{txtDialogTitle}}</h3>
    <span fxFlex></span>
    <button #deleteButton mat-icon-button *ngIf="!isNew" color="warn" type="button" (click)="showDeleteDialog()"><mat-icon>delete</mat-icon></button>
  </div>
</div>
<form [formGroup]="userForm" (ngSubmit)="save()">
  <div mat-dialog-content class="dialog-size" ngClass.lt-sm="dialog-size-small">
    <mat-tab-group dynamicHeight>
      <mat-tab [label]="txtUserData|uppercase">
        <div fxLayout="column">
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item first-line" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtUserName}}</mat-label>
              <input [formControl]="userNameControl" matInput required>
              <mat-error *ngIf="hasError('userNameControl', 'required')">{{txtRequired}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" ngClass.lt-sm="first-line-small" class="item first-line" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtUserEmail}}</mat-label>
              <input type="Email" [formControl]="userEmailControl" matInput required>
              <mat-error *ngIf="hasError('userEmailControl', 'required')">{{txtRequiredEmail}}</mat-error>
              <mat-error *ngIf="hasError('userEmailControl', 'email')">{{txtRequiredEmail}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtDocumentNumber}}</mat-label>
              <input [formControl]="documentNumberControl" matInput required>
              <mat-error *ngIf="hasError('documentNumberControl', 'required')">{{txtRequired}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtDocumentType}}</mat-label>
              <mat-select [formControl]="documentTypeControl" required>
                <mat-option *ngFor="let docType of documents" [value]="docType.codigo">
                  {{docType.nombre}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('documentTypeControl', 'required')">{{txtRequired}}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column">
            <mat-form-field appearance="fill" class="item" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtCompanyName}}</mat-label>
              <mat-select (selectionChange)="changedCompany($event)" [formControl]="companyNameControl" required>
                <mat-option *ngFor="let item of companies" [value]="item.name">{{item.name}}</mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('companyNameControl', 'required')">{{txtRequired}}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="item hidden" fxFlex.lt-sm="100" fxFlex="50">
              <mat-label>{{txtCompanyId}}</mat-label>
              <input matInput required [formControl]="companyIdControl">
              <mat-error *ngIf="hasError('companyIdControl', 'required')">{{txtRequired}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="txtUserRoles|uppercase" *ngIf="!showProjects">
        <app-roles-list [userPermitsInput]="kinds" [assignationCap]="" (userPermits)="getRolesChanges($event)"></app-roles-list>
      </mat-tab>
      <mat-tab [label]="txtUserProjects|uppercase" *ngIf="showProjects && companySelected">
        <app-users-projects [assignationCap]="" [existingProjects]="projectsOfCompany" [projectsLoaded]="projects" (projectsAssigned)="updateAssignedProjects($event)"></app-users-projects>
      </mat-tab>
    </mat-tab-group>
      <div class="item">
        <section>
          <mat-slide-toggle [ngClass]="{hidden: !companySelected}" [formControl]="useRolesForAllControl" color="primary" (change)="setAllPermissionsView($event)">{{txtAllRoles}}</mat-slide-toggle>
        </section>
        <section>
          <mat-slide-toggle [ngClass]="{'hidden': isNew}"  [formControl]="validatedControl" color="primary">{{txtValidated}}</mat-slide-toggle>
        </section>
        <section>
          <mat-slide-toggle [ngClass]="{'hidden': isNew}"  [formControl]="enabledControl" color="primary">{{txtEnabled}}</mat-slide-toggle>
        </section>
      </div>
      <div class="item" fxLayoutAlign="end center" *ngIf="!isNew">
        <button type="button" mat-icon-button color="primary"><mat-icon>{{txtLogsIcon}}</mat-icon></button>
      </div>
      <div class="item">
        <div fxLayout="row" *ngIf="isNew">
          <section class="checkbox-accept">
            <mat-checkbox class="checkbox-accept-text"
                          color="primary"
                          [formControl]="acceptControl"
                          labelPosition="after">
              {{txtAcceptConditions}}
            </mat-checkbox>
          </section>
        </div>
        <div class="terms-and-conditions">
          {{txtTermsAndCondition}}
        </div>
        <div fxLayout.lt-sm="column">
          <button fxFlex.lt-sm="100" disableRipple="true" (click)="gotoUrl(true)" mat-button type="button" color="primary">{{txtTermsText}}</button>
          <button fxFlex.lt-sm="100" disableRipple="true" (click)="gotoUrl(false)" mat-button type="button" color="primary">{{txtPrivacytext}}</button>
        </div>
      </div>
    </div>
  <div mat-dialog-actions fxLayoutAlign="end center" class="item">
    <button mat-raised-button color="accent" class="button-class" type="button" (click)="closeDialog()">{{txtCancel|uppercase}}</button>
    <button mat-raised-button color="primary" class="button-class" type="submit" [disabled]="userForm.invalid">{{txtAccept|uppercase}}</button>
  </div>
</form>
