import { Component, OnInit } from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {VisitorAccessObject} from "../../../../core/shared/models/control/visitorAccess.object";
import {PersonalVehicleKindEnum} from "../../../../core/shared/models/control/enums/personalVehicleKind.enum";
import {PersonalVehicleObject} from "../../../../core/shared/models/control/personalVehicle.object";

@Component({
  selector: "app-not-found-dialog",
  templateUrl: "./not-found-dialog.component.html",
  styleUrls: ["./not-found-dialog.component.scss"]
})
export class NotFoundDialogComponent implements OnInit {

  txtIcon: string;
  txtTitle: string;
  txtAccept: string;
  txtCancel: string;
  txtConfirmPretext: string;
  txtConfirmPretextId: string;
  txtIsVisitor: string;
  txtDocID: string;
  txtName: string;
  txtVehicleKind: string;
  txtVisitPurpose: string;
  txtNotFound: string;
  txtPlateOrIdentifier: string;
  isVisitor: boolean;
  txtHasVehicle: string;
  hasVehicle: boolean;
  visitorResult: { visitor: VisitorAccessObject, hasVehicle: boolean, vehicle: PersonalVehicleObject };
  docNumberControl: FormControl;
  nameControl: FormControl;
  reasonControl: FormControl;
  valueControl: FormControl;
  kindControl: FormControl;
  formGroup: FormGroup;
  enumData: any;

  constructor(private dialogRef: MatDialogRef<NotFoundDialogComponent>) { }

  ngOnInit(): void {
    this.hasVehicle = false;
    this.enumData = PersonalVehicleKindEnum;
    this.initTexts();
    this.initForm();
  }

  initTexts(): void {
    this.txtIcon = "warning";
    this.txtTitle = "Documento Inexistente";
    this.txtAccept = "Aceptar";
    this.txtCancel = "Cancelar";
    this.txtDocID = "Documento de Identidad";
    this.txtName = "Nombre del Visitante";
    this.txtIsVisitor = "Es visitante?";
    this.txtVisitPurpose = "Propòsito de la visita";
    this.txtVehicleKind = "Tipo de Vehiculo - Si aplica";
    this.txtNotFound = "El documento ingresado no existe, por favor reviselo y digitelo nuevamente!" +
      "Si es un visitante por favor active y iligencie la informaciòn siguente!";
    this.txtIcon = "warning";
    this.txtIcon = "warning";
    this.txtIcon = "warning";
    this.txtPlateOrIdentifier = "Placa o Identificación";
    this.txtHasVehicle = "Viene en vehículo?";
  }

  initForm(): void {
    this.docNumberControl = new FormControl("", [Validators.required, Validators.min(999999)]);
    this.nameControl = new FormControl("", [Validators.required, Validators.minLength(5)]);
    this.reasonControl = new FormControl("", [Validators.required, Validators.minLength(8)]);
    this.kindControl = new FormControl("");
    this.valueControl = new FormControl("");
    this.formGroup = new FormGroup({
      docNumberControl: this.docNumberControl,
      nameControl: this.nameControl,
      reasonControl: this.reasonControl,
      kindControl: this.kindControl,
      valueControl: this.valueControl,
    });
  }

  closeMe(): void {
    this.dialogRef.close();
  }



  confirm(): void {
    if (!this.isVisitor) {
      this.dialogRef.close();
    } else {
      const theVisitor = new VisitorAccessObject();
      theVisitor.name = this.nameControl.value;
      theVisitor.documentNumber = this.docNumberControl.value.toString();
      theVisitor.reason = this.reasonControl.value;
      const theVehicle = new PersonalVehicleObject();
      if (this.hasVehicle) {
        theVehicle.vehicleKind = this.kindControl.value;
        theVehicle.plate = this.valueControl.value;
      }
      this.visitorResult = { visitor: theVisitor, hasVehicle: this.hasVehicle , vehicle: theVehicle};
      this.dialogRef.close(this.visitorResult);
    }
  }
  toggled(): void {
    this.isVisitor = !this.isVisitor;
  }

  toggleVehicle(): void {
    this.hasVehicle = !this.hasVehicle;
  }
}
