<form [formGroup]="formGroup">
  <div mat-dialog-title fxLayout="row">
    <mat-icon>{{txtIcon}}</mat-icon>
    <h3 class="card-title-h3">{{txtTitle}}</h3>
  </div>
  <div mat-dialog-content>
    <div>
      <div>{{txtNotFound}}</div>
    </div>
    <div>
      <section class="first-line-admin container-padder">
        <mat-slide-toggle (toggleChange)="toggled()" color="primary" class="toggle-text">{{txtIsVisitor}}</mat-slide-toggle>
      </section>
    </div>
    <!-- Visitors Content -->
    <div *ngIf="isVisitor">
      <div>
        <mat-form-field fxFlex appearance="fill">
          <mat-label>{{txtDocID}}</mat-label>
          <input [formControl]="docNumberControl" matInput required type="number">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex appearance="fill">
          <mat-label>{{txtName}}</mat-label>
          <input [formControl]="nameControl" matInput required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field fxFlex appearance="fill">
          <mat-label>{{txtVisitPurpose}}</mat-label>
          <textarea rows="4" [formControl]="reasonControl" matInput required></textarea>
        </mat-form-field>
      </div>
      <div>
        <section class="first-line-admin container-padder">
          <mat-slide-toggle (toggleChange)="toggleVehicle()" class="toggle-text" checked="false" color="primary">{{txtHasVehicle}}</mat-slide-toggle>
        </section>
      </div>
      <div  [ngClass]="{'hidden': !hasVehicle}">
        <div fxLayout="column">
          <mat-form-field appearance="fill">
            <mat-label>{{txtVehicleKind}}</mat-label>
            <mat-select [formControl]="kindControl">
              <mat-option *ngFor="let item of enumData | keyvalue" [value]="item.value">{{item.value}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{txtPlateOrIdentifier}}</mat-label>
            <input [formControl]="valueControl" matInput>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button class="common-button" type="button" (click)="closeMe()" mat-raised-button>{{txtCancel|uppercase}}</button>
    <button class="common-button" [disabled]="(formGroup.invalid && isVisitor)" type="button" (click)="confirm()" mat-raised-button color="accent">{{txtAccept|uppercase}}</button>
  </div>
</form>
