<div class="content-area" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <mat-card class="main-card card-settings" ngClass.lt-sm="main-card-small">
    <mat-card-header fxLayoutAlign="center center">
      <mat-icon>{{txtIcon}}</mat-icon>
      <h3 class="card-title-h3 card-title">{{cardTitle}}</h3>
      <span fxFlex></span>
    </mat-card-header>
    <mat-card-content>
      <app-persons-turn-manager></app-persons-turn-manager>
    </mat-card-content>
    <mat-card-footer *ngIf="showBar">
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
