import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserEditObject} from "../../../core/shared/models/auth/userEdit.object";
import {UsersService} from "../../shared/users.service";
import {Subscription} from "rxjs";
import {UserKindEnum} from "../../../core/shared/models/auth/enums/userKind.enum";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {AuthService} from "../../../auth/shared/auth.service";
import {CommonDialogObject} from "../../../core/shared/models/common/commonDialog.object";
import {GenericDialogComponent} from "../../../core/components/dialogs/generic-dialog/generic-dialog.component";
import {LogObject} from "../../../core/shared/models/common/log.object";
import * as moment from "moment";
import {LogEventKindEnum} from "../../../core/shared/models/common/enums/logEventKind.enum";
import {UserKindObject} from "../../../core/shared/models/auth/UserKind.object";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {CoreService} from "../../../core/shared/core.service";
import {ProjectInUserObject} from "../../../core/shared/models/company/projectInUser.object";
import {CompanyService} from "../../../company/shared/company.service";
import {CompanyObject} from "../../../core/shared/models/company/companyObject";
import {ProjectObject} from "../../../core/shared/models/company/project.object";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: "app-users-details",
  templateUrl: "./users-details.component.html",
  styleUrls: ["./users-details.component.scss"]
})
export class UsersDetailsComponent implements OnInit, OnDestroy {

  private ADD_EVENT = "Usuario - Nuevo";
  private EDIT_EVENT = "Usuario - Editado";
  private DELETE_EVENT = "Usuario - Eliminado";
  private CURRENT_USER = "Usuario Santo - espiritu@santo.com";

  txtDialogTitle: string;
  txtIcon: string;
  txtAccept: string;
  txtCancel: string;
  txtUserName: string;
  txtUserEmail: string;
  txtDocumentNumber: string;
  txtDocumentType: string;
  txtCompanyId: string;
  txtCompanyName: string;
  txtRequired: string;
  txtLogsIcon: string;
  txtAllRoles: string;
  txtValidated: string;
  txtEnabled: string;
  txtRequiredEmail: string;
  txtTermsAndCondition: string;
  txtAcceptConditions: string;
  txtTermsText: string;
  txtPrivacytext: string;
  txtTermsLink: string;
  txtPrivacyLink: string;
  theRolesTxt: string;
  txtUserData: string;
  txtUserRoles: string;
  txtUserProjects: string;
  theRolesChanges: string;
  theRolesChanged: boolean;
  isSuperAdminUser: boolean;
  isAdminUser: boolean;

  userForm: FormGroup;
  userNameControl: FormControl;
  userEmailControl: FormControl;
  documentNumberControl: FormControl;
  documentTypeControl: FormControl;
  companyNameControl: FormControl;
  useRolesForAllControl: FormControl;
  enabledControl: FormControl;
  validatedControl: FormControl;
  acceptControl: FormControl;
  companyIdControl: FormControl;
  kinds: UserKindEnum[] = [];
  items: any[] = [];
  documents: any[] = [];
  projects: ProjectInUserObject[] = [];
  projectsOfCompany: ProjectObject[] = [];
  companies: CompanyObject[] = [];
  itemsSub: Subscription;
  docsSubs: Subscription;
  isNew: boolean;
  original: UserInfoObject;
  current: UserInfoObject;
  currentUser: UserInfoObject;
  userSubscription: Subscription;
  showProjects: boolean;
  companySelected: boolean;

  constructor(private dialogRef: MatDialogRef<UsersDetailsComponent>,  @Inject(MAT_DIALOG_DATA)private data: UserEditObject,
              private usersService: UsersService, private coreService: CoreService, private authService: AuthService,
              private confirmDeleteDialog: MatDialog, private companyService: CompanyService) { }

  ngOnInit(): void {
    this.companySelected = false;
    console.log(this.authService.getCurrentUserInfo().globalRoles);
    this.initTexts();
    this.initForm();
    console.log(this.currentUser);
    this.currentUser = this.authService.getCurrentUserInfo();
    this.CURRENT_USER = `${this.currentUser.userName} - ${this.currentUser.userEmail}`;
    console.log(this.currentUser);
    if (this.data) {
      this.isNew = !this.data.userInfoObject;
    } else {
      this.isNew = true;
    }
    if (this.isNew) {
      this.current = new UserInfoObject();
      this.kinds = [];
      this.getDocuments();
    } else {
      this.current = new UserInfoObject(this.data.userInfoObject);
      this.original = new UserInfoObject(this.data.userInfoObject);
      this.kinds = this.current.globalRoles;
      this.projects = this.current.projects;
      console.log("going to get documents");
      this.getDocuments();
    }
  }

  ngOnDestroy(): void {
    if (this.itemsSub) {
      this.itemsSub.unsubscribe();
    }
    if (this.docsSubs) {
      this.docsSubs.unsubscribe();
    }
  }

  initTexts(): void {
    this.txtIcon = "supervised_user_circle";
    this.txtDialogTitle = "Editar Información del Usuario";
    this.txtAccept = "Aceptar";
    this.txtCancel = "Cancelar";
    this.txtUserEmail = "Correo Electrónico - Usuario";
    this.txtUserName = "Nombre";
    this.txtRequired = "Este campo es obligatorio";
    this.txtRequiredEmail = "¡Debe escribir un correo electrónico!";
    this.txtDocumentNumber = "Número de Documento";
    this.txtDocumentType = "Tipo de Documento";
    this.txtCompanyId = "ID de la empresa";
    this.txtCompanyName = "Empresa";
    this.txtLogsIcon = "fact_check";
    this.txtAllRoles = "Usar Roles para todos los proyectos";
    this.txtValidated = "Usuario Validado";
    this.txtEnabled = "Usuario Activo";
    this.txtTermsAndCondition = "Los usuarios son creados de acuerdo a nuestros terminos y condiciones y nuestras políticas de privacidad.";
    this.txtAcceptConditions = "Acepto los terminos y condiciones.";
    this.txtTermsLink = "https://auth.kostruaccess.kooguen.com/#/terms";
    this.txtPrivacyLink = "https://auth.kostruaccess.kooguen.com/#/privacy";
    this.txtTermsText = "Nuestros términos y condiciones.";
    this.txtPrivacytext = "Nuestra política de privacidad.";
    this.theRolesTxt = "Los roles sólo se pueden agregar una vez creados, por tal motivo debe " +
      "crearlo y luego seleccionarlo de la lista";
    this.txtUserData = "Datos de usuario";
    this.txtUserRoles = "Permisos Generales";
    this.txtUserProjects = "Proyectos";
  }

  initForm(): void {
    this.userEmailControl = new FormControl("", [Validators.required, Validators.email]);
    this.userNameControl = new FormControl("", [Validators.required]);
    this.documentTypeControl = new FormControl("", Validators.required);
    this.documentNumberControl = new FormControl("", Validators.required);
    this.companyNameControl = new FormControl("", Validators.required);
    this.useRolesForAllControl = new FormControl("");
    this.enabledControl = new FormControl("");
    this.validatedControl = new FormControl("");
    this.companyIdControl = new FormControl("");
    this.acceptControl = new FormControl("", Validators.requiredTrue);
    this.userForm = new FormGroup({
      userEmailControl: this.userEmailControl,
      userNameControl: this.userNameControl,
      documentTypeControl: this.documentTypeControl,
      documentNumberControl: this.documentNumberControl,
      companyNameControl: this.companyNameControl,
      useRolesForAllControl: this.useRolesForAllControl,
      enabledControl: this.enabledControl,
      validatedControl: this.validatedControl,
      acceptControl: this.acceptControl,
      companyIdControl: this.companyIdControl
    });
  }

  getDocuments(): void {
    this.itemsSub = this.coreService.getDocumentTypes().subscribe(
      docRefNext => {
        this.docsSubs = this.coreService.getDocumentTypesFromURL(docRefNext).subscribe(
          nextItems => {
            this.documents = nextItems;
            this.mapData();
            this.validateCompany();
          }, error2 => console.error(error2)
        );
      }, error => console.error(error)
    );
  }

  validateCompany(): void {
    if (this.currentUser.useGlobalRolesForAllProjects && this.currentUser.globalRoles.length > 0) {
      this.isAdminUser = false;
      this.isSuperAdminUser = false;
      for (const role of this.currentUser.globalRoles) {
        if (role > UserKindEnum.SUPERADMIN_READER) {
          this.isSuperAdminUser = true;
          this.isAdminUser = false;
          break;
        } else if (role > UserKindEnum.ADMIN_READER) {
          this.isAdminUser = true;
          this.isSuperAdminUser = false;
          break;
        }
      }
    }
    console.log("ValidateUser", this.isSuperAdminUser, this.isAdminUser);
    if (this.isSuperAdminUser) {
      if (this.isNew) {
        this.companyService.loadAdminCompanies().subscribe(
          next => this.companies = next,
          error => console.error("Error cargando empresas: ", error)
        );
      } else {
        const cmp = new CompanyObject();
        cmp.name = this.original.corporateName;
        this.companies.push(cmp);
      }
    } else if (this.isAdminUser) {
      this.companyService.loadProjectFromCompany(this.currentUser.corporateId).subscribe(
        next => this.projectsOfCompany = next,
        error => console.error("Error Cargando proyectos", error)
      );
      const cmp = new CompanyObject();
      cmp.name = this.currentUser.corporateName;
      this.companies.push(cmp);
      this.companyNameControl.setValue(this.currentUser.corporateId);
      this.companyIdControl.setValue(this.currentUser.corporateName);
      this.companyNameControl.disable();
      this.companyIdControl.disable();
      this.companySelected = true;
    }
    console.log("Company Value", this.companyNameControl.value);
  }

  mapData(): void {
    if (this.isNew) {
      this.enabledControl.setValue(true);
      this.validatedControl.setValue(false);
      this.useRolesForAllControl.setValue(true);
      this.enabledControl.disable();
      this.validatedControl.disable();
      this.companySelected = false;
    } else {
      console.log(this.original, "mapping");
      this.userNameControl.setValue(this.original.userName);
      this.userEmailControl.setValue(this.original.userEmail);
      this.documentTypeControl.setValue(this.original.userDocumentType);
      this.documentNumberControl.setValue(this.original.userDocumentNumber);
      this.useRolesForAllControl.setValue(this.original.useGlobalRolesForAllProjects);
      this.showProjects = !this.original.useGlobalRolesForAllProjects;
      this.companyNameControl.setValue(this.original.corporateName);
      this.companyIdControl.setValue(this.original.corporateId);
      this.enabledControl.setValue(this.original.enabled);
      this.validatedControl.setValue(this.original.validated);
      this.acceptControl.setValue(true);
      this.userEmailControl.disable();
      this.validatedControl.disable();
    }
  }

  save(): void {
    if (this.isNew) {
      this.current = new UserInfoObject();
      this.current.corporateName = this.companyNameControl.value;
      this.current.corporateId = this.companies[this.companies.findIndex(x => x.name = this.companyNameControl.value)].uid;
    } else {
      this.current = new UserInfoObject(this.original);
    }
    this.current.userName = this.userNameControl.value;
    this.current.userEmail = this.userEmailControl.value;
    this.current.userDocumentNumber = this.documentNumberControl.value;
    this.current.userDocumentType = this.documentTypeControl.value;
    this.current.useGlobalRolesForAllProjects = this.useRolesForAllControl.value;
    this.current.enabled = this.enabledControl.value;
    this.current.validated = this.validatedControl.value;
    this.current.globalRoles = this.kinds;
    this.current.projects = this.projects;
    if (this.isNew) {
      this.current.logs = [];
      this.current.logs.push(new LogObject(moment().unix(), this.ADD_EVENT, LogEventKindEnum.CREATE, this.CURRENT_USER));
      this.userSubscription = this.usersService.createUserFromFunction(this.current).subscribe(
        next => this.dialogRef.close(),
        error => console.error("error", error)
      );
    } else {
      const events: string [] = [];
      const title: string = this.EDIT_EVENT + "<br>";
      events.push(title);
      if (this.current.userName !== this.original.userName) {
        events.push(`Nombre de Usuario: ${this.original.userName} => ${this.current.userName}` + "<br>");
      }
      if (this.current.userEmail !== this.original.userEmail) {
        events.push(`Correo electrónico: ${this.original.userEmail} => ${this.current.userEmail}` + "<br>");
      }
      if (this.current.userDocumentNumber !== this.original.userDocumentNumber) {
        events.push(`Numero de Documento: ${this.original.userDocumentNumber} => ${this.current.userDocumentNumber}` + "<br>");
      }
      if (this.current.userDocumentType !== this.original.userDocumentType) {
        events.push(`Tipo de Documento: ${this.original.userDocumentType} => ${this.current.userDocumentType}` + "<br>");
      }
      if (this.current.corporateId !== this.original.corporateId) {
        events.push(`Id de la Empresa: ${this.original.corporateId} => ${this.current.corporateId}` + "<br>");
      }
      if (this.current.corporateName !== this.original.corporateName) {
        events.push(`Empresa: ${this.original.corporateName} => ${this.current.corporateName}` + "<br>");
      }
      if (this.current.corporateToken !== this.original.corporateToken) {
        events.push(`Token de la empresa: ${this.original.corporateToken} => ${this.current.corporateToken}` + "<br>");
      }
      if (this.current.enabled !== this.original.enabled) {
        events.push(`Activo: ${this.original.enabled} => ${this.current.enabled}` + "<br>");
      }
      if (this.current.validated !== this.original.validated) {
        events.push(`Validado: ${this.original.validated} => ${this.current.validated}` + "<br>");
      }
      if (this.current.useGlobalRolesForAllProjects !== this.original.useGlobalRolesForAllProjects) {
        events.push(`Roles Globales Activos: ${this.original.useGlobalRolesForAllProjects} => ${this.current.useGlobalRolesForAllProjects}`
          + "<br>");
      }
      let eventText = "";
      if (this.theRolesChanged) {
        events.push(this.theRolesChanges + "<br>");
      }
      if (this.current.projects !== this.original.projects) {
        events.push(`proyectos Asociados: ${this.original.projects.length} => ${this.current.projects.length}` + "<br>");
      }
      if (events.length > 1) {
        for (const linea of events) {
          eventText += linea;
        }
        this.current.logs.push(new LogObject(moment().unix(), eventText, LogEventKindEnum.MODIFY, this.CURRENT_USER));
        this.userSubscription = this.usersService.editItem(this.current.userID, this.current).subscribe(
          res => {
            this.dialogRef.close();
          }
        );
      } else {
        this.dialogRef.close();
      }
    }
  }

  getRolesChanges(newRoles: UserKindEnum[]): void {
    this.kinds = newRoles;
    if (!this.isNew) {
      if (this.original.globalRoles !== newRoles) {
        this.theRolesChanged = true;
        this.theRolesChanges = "Roles Globales: ";
        if (this.original.globalRoles && this.original.globalRoles.length > 0) {
          const theOriginalItems: UserKindObject[] = this.usersService.userKindEnumsToObjects(this.original.globalRoles);
          let i = 0;
          for (const toi of theOriginalItems) {
            i++;
            this.theRolesChanges += toi.name;
            if (i !== theOriginalItems.length) {
              this.theRolesChanges += ", ";
            } else {
              this.theRolesChanges += " ";
            }
          }
        } else {
          this.theRolesChanges += "Sin Roles => ";
        }
        if (newRoles && newRoles.length > 0) {
          const theNewRoles: UserKindObject[] = this.usersService.userKindEnumsToObjects(newRoles);
          let i = 0;
          for (const toi of theNewRoles) {
            i++;
            this.theRolesChanges += toi.name;
            if (i !== theNewRoles.length) {
              this.theRolesChanges += ", ";
            } else {
              this.theRolesChanges += " ";
            }
          }
        } else {
          this.theRolesChanges += "Sin nuevos Roles";
        }
      } else {
        this.theRolesChanged = false;
      }
    }
  }

  showDeleteDialog(): void {
    const theData: CommonDialogObject = {
      title: "Eliminar Usuario!",
      content: `¿Confima que desea eliminar el usuario ${this.current.userName}? ¡Esta opciòn no se puede deshacer!`
    };
    const confirmDialogRef = this.confirmDeleteDialog.open(GenericDialogComponent, {
      width: "310px",
      disableClose: true,
      closeOnNavigation: false,
      data: theData
    });
    confirmDialogRef.afterClosed().subscribe(
      next => {
        if (next) {
          this.delete();
        }
      }
    );
  }

  delete(): void {
    if (this.original) {
      this.original.deleted = true;
      this.original.logs.push(new LogObject(moment().unix(), this.DELETE_EVENT, LogEventKindEnum.DELETE, this.CURRENT_USER));
      this.userSubscription = this.usersService.editItem(this.original.userID, this.original).subscribe(
        res => {
          this.dialogRef.close();
        }
      );
    }
  }

  updateAssignedProjects(items: ProjectInUserObject[]): void {
    this.projects = items;
  }

  gotoUrl(isTerms: boolean): void {
    let url = "";
    if (isTerms){
      url = this.txtTermsLink;
    } else {
      url = this.txtPrivacyLink;
    }
    window.open(url, "_blank");
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.userForm.controls[controlName].hasError(errorName);
  }

  setAllPermissionsView(event: MatSlideToggleChange): void {
    this.showProjects = !event.checked;
  }

  changedCompany(data: MatSelectChange): void {
    console.log("Company CHange", data);
    const id = this.companies[this.companies.findIndex(x => x.name === data.value)].uid;
    this.companyIdControl.setValue(id);
    this.companySelected = true;
    this.companyService.loadProjectFromCompany(id).subscribe(
      next => {
        this.projectsOfCompany = next;
        console.log("projects", next);
        if (this.projectsOfCompany.length < 1) {
          this.useRolesForAllControl.setValue(true);
          this.useRolesForAllControl.disable();
        } else {
          this.useRolesForAllControl.enable();
        }
      },
      error => console.error("Error cargando proyectos", error)
    );
  }

}
