<div mat-dialog-title>
  <div fxLayout="row">
    <mat-icon>{{txtItemIcon}}</mat-icon>
    <h3 class="card-title-h3">{{txtDialogTitle}}</h3>
  </div>
</div>
<form [formGroup]="usersFormGroup" (ngSubmit)="save()">
  <div mat-dialog-content class="dialog-size" ngClass.lt-sm="dialog-size-small">
    <div fxLayout="column">
      <div fxLayout="row">
        <mat-form-field appearance="fill"fxFlex="100" class="first-line item">
          <mat-label>{{txtEmail}}</mat-label>
          <input matInput [formControl]="emailControl" required>
          <mat-error *ngIf="hasError('emailControl', 'required')">
            {{txtRequiredEmail}}
          </mat-error>
          <mat-error *ngIf="hasError('emailControl', 'email')">
            {{txtRequiredEmail}}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <section class="checkbox-accept">
          <mat-checkbox class="checkbox-accept-text"
            [formControl]="acceptControl"
            (change)="changedAccept($event)"
            labelPosition="after">
            {{txtAcceptConditions}}
          </mat-checkbox>
        </section>
      </div>
      <div class="terms-and-conditions">
        {{txtTermsAndCondition}}
      </div>
      <div>
        <button disableRipple="true" mat-button type="button" color="primary">{{txtTermsText}}</button>
        <button disableRipple="true" mat-button type="button" color="primary">{{txtPrivacytext}}</button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end center" class="item">
    <button mat-raised-button color="accent" class="common-button" type="button" (click)="closeDialog()">{{txtCancel|uppercase}}</button>
    <button mat-raised-button color="primary" class="common-button" type="submit" [disabled]="usersFormGroup.invalid">{{txtAccept|uppercase}}</button>
  </div>
</form>
