export enum UserKindTranslationEnum {
  REJECTED = "RECHAZADO",
  NOT_VERIFIED = "SIN VALIDAR",
  NOT_ASSIGNED = "SIN ASIGNAR",
  PERSON_ACCESS_READER = "ACCESO PERSONAL - VER",
  PERSON_ACCESS_OPERATOR = "ACCESO PERSONAL - UTILIZAR",
  PERSON_ACCESS_WRITER = "ACCESO PERSONAL - MODIFICAR",
  PERSON_ACCESS_ADMIN = "ACCESO PERSONAL - ADMINISTRAR",
  VEHICLE_ACCESS_READER = "ACCESO VEHICULAR - VER",
  VEHICLE_ACCESS_OPERATOR  = "ACCESO VEHICULAR - UTILIZAR",
  VEHICLE_ACCESS_WRITER  = "ACCESO VEHICULAR - MODIFICAR",
  VEHICLE_ACCESS_ADMIN  = "ACCESO VEHICULAR - ADMINISTRAR",
  INVENTORY_READER = "INVENTARIO - VER",
  INVENTORY_OPERATOR = "INVENTARIO - UTILIZAR",
  INVENTORY_REQUEST = "INVENTARIO - SOLICITAR",
  INVENTORY_WRITER = "INVENTARIO - MODIFICAR",
  INVENTORY_ADMIN = "INVENTARIO - ADMINISTRAR",
  ADMIN_READER = "ADMINISTRATIVO - VER",
  ADMIN_WRITER = "ADMINISTRATIVO - MODIFICAR",
  ADMIN_ADMIN = "ADMINISTRATIVO - ADMINSTRAR",
  SUPERADMIN_READER = "KOOGUEN - VER",
  SUPERADMIN_WRITER = "KOOGUEN - MODIFICAR",
  SUPERADMIN_ADMIN = "KOOGUEN - ADMINISTRAR",
}
