export class VehicleObject {
  uid: string;
  plate: string;
  mandatoryPolicy: string; // SOAT
  mandatoryPolicyExpirationDateString: string; // Format "DD/MM/YYYY"
  mandatoryEnvironmentPolicy: string; // TecnicoMecanica
  mandatoryEnvironmentPolicyExpiratonDateString: string; // Format "DD/MM/YYYY"
  verified: boolean;
  deleted: boolean;

  constructor(vehicleObject?: VehicleObject) {
    if (vehicleObject) {
      if (vehicleObject.uid) {
        this.uid = vehicleObject.uid;
      }
      this.plate = vehicleObject.plate;
      this.mandatoryEnvironmentPolicy = vehicleObject.mandatoryEnvironmentPolicy;
      this.mandatoryEnvironmentPolicyExpiratonDateString = vehicleObject.mandatoryEnvironmentPolicyExpiratonDateString;
      this.mandatoryPolicy = vehicleObject.mandatoryPolicy;
      this.mandatoryPolicyExpirationDateString = vehicleObject.mandatoryPolicyExpirationDateString;
      this.verified = vehicleObject.verified;
      this.deleted = vehicleObject.deleted;
    } else {
      this.verified = false;
      this.deleted = false;
    }
  }
}
