import {UserKindEnum} from "./enums/userKind.enum";
import {UserKindTranslationEnum} from "./enums/userKindTranslation.enum";

export class UserKindObject {
  userKindEnum: UserKindEnum;
  userKindTranslation: UserKindTranslationEnum;
  name: string;
  value: number;

  constructor(u?: UserKindObject, uke?: UserKindEnum, ukte?: UserKindTranslationEnum) {
    if (u) {
      this.userKindEnum = u.userKindEnum;
      this.userKindTranslation = u.userKindTranslation;
      this.name = u.name;
      this.value = u.value;
    }
    if (uke) {
      this.userKindEnum = uke;
      this.value = uke;
    }
    if (ukte) {
      this.userKindTranslation = ukte;
      this.name = ukte;
    }
  }
}
