import {PersonalVehicleObject} from "../control/personalVehicle.object";
import {VisitorAccessObject} from "../control/visitorAccess.object";

export class PersonTransactionObject {
  uid: string;
  companyID: string;
  personID: string; // solo numero de documento de identidad
  personName: string; // solo numero de documento de identidad
  personPosition: string; // Cargo
  inUserUID: string; // Dummy en este punto debe ser cualquier id - debe actualizarse por el id de la auth de firebase
  inUserName: string; // Nombre del usuario Que marca el ingreso ingresa
  inDateTime: number; // Unix del momento de ingresar (usar moment.js)
  outUserUID: string; // Dummy en este punto debe ser cualquier id - debe actualizarse por el id de la auth de firebase
  outUserName: string; // Nombre del usuario Que marca el ingreso ingresa
  outDateTime: number; // Unix del momento de ingresar (usar moment.js)
  useVehicle: boolean;
  vehicleInfo: PersonalVehicleObject;
  isIn: boolean;
  isVisitor: boolean;
  visitor: VisitorAccessObject;
  deleted: boolean;

  constructor(personTransactionObject?: PersonTransactionObject) {
    if (personTransactionObject) {
      if (personTransactionObject.uid) {
        this.uid = personTransactionObject.uid;
      }
      this.companyID = personTransactionObject.companyID;
      this.personID = personTransactionObject.personID;
      this.personPosition = personTransactionObject.personPosition;
      this.personName = personTransactionObject.personName;
      this.inUserUID = personTransactionObject.inUserUID;
      this.inUserName = personTransactionObject.inUserName;
      this.inDateTime = personTransactionObject.inDateTime;
      this.outUserUID = personTransactionObject.outUserUID;
      this.outUserName = personTransactionObject.outUserName;
      this.outDateTime = personTransactionObject.outDateTime;
      this.useVehicle = personTransactionObject.useVehicle;
      this.vehicleInfo = personTransactionObject.vehicleInfo;
      this.isIn = personTransactionObject.isIn;
      this.deleted = personTransactionObject.deleted;
      this.isVisitor = personTransactionObject.isVisitor;
      this.visitor = personTransactionObject.visitor;
    } else {
      this.isIn = false;
      this.deleted = false;
      this.isVisitor = false;
    }
  }
}
