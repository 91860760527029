import {Component, HostListener, Input, OnInit} from "@angular/core";
import {PersonalAccessCollaboratorObject} from "../../../../core/shared/models/access-personel/personal-access-collaborator.object";
import {MatTableDataSource} from "@angular/material/table";
import {PersonTransactionObject} from "../../../../core/shared/models/access-personel/personTransaction.object";
import {Observable, Subscription} from "rxjs";

@Component({
  selector: "app-admin-persons-list",
  templateUrl: "./admin-persons-list.component.html",
  styleUrls: ["./admin-persons-list.component.scss"]
})
export class AdminPersonsListComponent implements OnInit {

  @Input() isVisitorList = false;
  @Input() transactionData: PersonTransactionObject[] = [];
  @Input() updateMyData: Observable<PersonTransactionObject[]> = new Observable<PersonTransactionObject[]>();
  tableDataSource: MatTableDataSource<PersonTransactionObject>;
  showNoData: boolean;
  txtNoData: string;
  txtIdentification: string;
  txtName: string;
  txtReason: string;
  txtVisitor: string;
  txtPosition: string;
  dc: string[] = [];
  innerWidth: number;
  updateMyDataSubscription: Subscription;
  @HostListener("window:resize", ["$event"])
  onresize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = [this.txtName, this.txtPosition];
      // this.dcSubject.next(this.dc);
    } else {
      this.dc = [this.txtIdentification, this.txtName, this.txtPosition];
      // this.dcSubject.next(this.dc);
    }
  }

  constructor() { }

  ngOnInit(): void {
    this.initTexts();
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      if (this.isVisitorList) {
        this.dc = [this.txtVisitor, this.txtReason];
      } else {
        this.dc = [this.txtName, this.txtPosition];
      }
      // this.dcSubject.next(this.dc);
    } else {
      if (this.isVisitorList) {
        this.dc = [this.txtVisitor, this.txtReason];
      } else {
        this.dc = [this.txtIdentification, this.txtName, this.txtPosition];
      }
      // this.dcSubject.next(this.dc);
    }
    this.tableDataSource = new MatTableDataSource<PersonTransactionObject>();
    this.showNoData = !(this.transactionData.length > 0);
    this.tableDataSource.data = this.transactionData;
    this.updateMyDataSubscription = this.updateMyData.subscribe(
      next => {
        this.transactionData = next;
        this.tableDataSource.data = this.transactionData;
      }
    );
  }

  initTexts(): void {
    if (this.isVisitorList) {
      this.txtNoData = "No hay visitantes en la obra en este momento";
    } else {
      this.txtNoData = "No hay colaboradores en la obra en este momento";
    }
    this.txtPosition = "Cargo";
    this.txtName = "Nombre";
    this.txtIdentification = "No. Identificación";
    this.txtReason = "Motivo";
    this.txtVisitor = "Visitante";
  }


}
