<div class="main-container" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <mat-card class="main-card card-settings" ngClass.lt-sm="main-card-small">
    <mat-card-header fxLayoutAlign="center center">
      <mat-icon>{{txtCardIcon}}</mat-icon>
      <h3 class="card-title-h3 card-title">{{txtCardTitle}}</h3>
      <span fxFlex></span>
    </mat-card-header>
    <mat-card-content>
      <div class="note-text">
        <b>Nota:</b> ¡Solo se muestran los usuarios que han verificado su correo electrónico!
      </div>
      <app-search-box [searchFields]="dc" [searchFieldsObservable]="" (searchData)="queryDatabase($event)"></app-search-box>
      <table mat-table [dataSource]="tableDataSource" class="table-size">
        <ng-container matColumnDef="Correo Electrónico">
          <th mat-header-cell *matHeaderCellDef> {{txtUserEmail | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.userEmail}} </td>
        </ng-container>
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef> {{txtUserName | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.userName}} </td>
        </ng-container>
        <ng-container matColumnDef="Verificado">
          <th mat-header-cell *matHeaderCellDef> {{txtValidated | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{ getBooleanResult(item.validated)}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="dc"></tr>
        <tr mat-row (click)="addEditItem(row)" *matRowDef="let row; columns: dc;"></tr>
      </table>
      <div class="no-data-table" *ngIf="itemsToShow.length === 0">
        <h4>{{txtNoData}}</h4>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button class="common-button" (click)="addEditItem()"
              mat-raised-button color="accent">{{txtNewUser | uppercase}}
      </button>
    </mat-card-actions>
    <mat-card-footer *ngIf="showBar">
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
