import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/firestore";
import {from, Observable} from "rxjs";
import {AngularFireStorage} from "@angular/fire/storage";
import {PersonTransactionObject} from "../../core/shared/models/access-personel/personTransaction.object";
import {CollaboratorObject} from "../../core/shared/models/persons/collaborator.object";
import {AccessTurnObject} from "../../core/shared/models/access-general/accessTurn.object";
import {ManagementListObject} from "../../core/shared/models/management/managementList.object";
import {Moment} from "moment";
import * as moment from "moment";
import {VehicleTransactionObject} from "../../core/shared/models/access-vehicle/vehicleTransaction.object";

@Injectable({
  providedIn: "root"
})
export class PersonsAccessService {

  private readonly DOC_TYPES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/public/document-types.json";
  private readonly COLOMBIA_CITIES_LOCATION = "gs://kostruaccess-c0a5c.appspot.com/public/toBePrivate/colombia.json";
  private readonly COLLECTION_NAME = "personal_transactions";
  private readonly TURN_COLLECTION_NAME = "access_turns";
  private readonly VEHICLES_TRANSACTIONS_COLLECTION_NAME = "vehicles_transactions";
  private readonly COLLABORATORS_COLLECTION_NAME = "Collaborators";

  constructor(private angularFirestore: AngularFirestore, private angularFireStorage: AngularFireStorage) {
  }

  getDocumentTypes(): Observable<any> {
    const location = this.angularFireStorage.storage.refFromURL(this.DOC_TYPES_LOCATION);
    return from(location.getDownloadURL());
  }

  getColombiaLocations(): Observable<any> {
    const location = this.angularFireStorage.storage.refFromURL(this.COLOMBIA_CITIES_LOCATION);
    return from(location.getDownloadURL());
  }

  addPersonalTransaction(item: PersonTransactionObject): Observable<any> {
    const object: any = Object.assign({}, item);
    object.vehicleInfo = Object.assign({}, item.vehicleInfo);
    object.visitor = Object.assign({}, item.visitor);
    console.log("Transaction to Add", object);
    return from(this.angularFirestore.collection<PersonTransactionObject>(this.COLLECTION_NAME).add(object));
  }

  updatePersonalTransaction(itemId: string, item: PersonTransactionObject): Observable<any> {
    const object: any = Object.assign({}, item);
    object.vehicleInfo = Object.assign({}, item.vehicleInfo);
    object.visitor = Object.assign({}, item.visitor);
    return from(this.angularFirestore.collection<PersonTransactionObject>(this.COLLECTION_NAME)
      .doc(itemId).set(object, {merge: true}));
  }

  loadSinglePersonalTransaction(documentID: string, companyID: string): Observable<PersonTransactionObject[]> {
    return this.angularFirestore.collection<PersonTransactionObject>(this.COLLECTION_NAME, ref => ref
      .where("isIn", "==", true)
      .where("personID", "==", documentID)
      .where("companyID", "==", companyID)
      .limit(1)).valueChanges({idField: "uid"});
  }

  loadPersonalTransactions(): Observable<PersonTransactionObject[]> {
    return this.angularFirestore.collection<PersonTransactionObject>(this.COLLECTION_NAME).valueChanges({idField: "uid"});
  }

  loadSingleCollaborator(documentID: string, companyID: string): Observable<CollaboratorObject[]> {
    console.log("Documento ID:", documentID, "companyID", companyID);
    return this.angularFirestore.collection<CollaboratorObject>(this.COLLABORATORS_COLLECTION_NAME, ref => ref
      .where("personalIdentification", "==", documentID)
      .where("companyUID", "==", companyID)
      .where("deleted", "==", false)
      .limit(1)).valueChanges({idField: "uid"});
  }

  addNewTurn(item: AccessTurnObject): Observable<any> {
    const theItem: any = Object.assign({}, item);
    theItem.lastXInCollaborators = item.lastXInCollaborators.map(obj => Object.assign({}, obj));
    theItem.lastXOutCollaborators = item.lastXOutCollaborators.map(obj => Object.assign({}, obj));
    theItem.lastXInVehicles = item.lastXInVehicles.map(obj => Object.assign({}, obj));
    theItem.lastXOutVehicles = item.lastXOutVehicles.map(obj => Object.assign({}, obj));
    return from(this.angularFirestore.collection<AccessTurnObject>(this.TURN_COLLECTION_NAME).add(theItem));
  }

  updateTurn(itemId: string, item: AccessTurnObject): Observable<any> {
    const object: any = Object.assign({}, item);
    object.lastXInCollaborators = item.lastXInCollaborators.map(obj => Object.assign({}, obj));
    object.lastXOutCollaborators = item.lastXOutCollaborators.map(obj => Object.assign({}, obj));
    object.lastXInVehicles = item.lastXInVehicles.map(obj => Object.assign({}, obj));
    object.lastXOutVehicles = item.lastXOutVehicles.map(obj => Object.assign({}, obj));
    console.log("Turn to update", object);
    return from(this.angularFirestore.collection<AccessTurnObject>(this.TURN_COLLECTION_NAME)
      .doc(itemId).set(object, {merge: true}));
  }

  getTurnByUserID(userID: string): Observable<AccessTurnObject[]> {
    return this.angularFirestore.collection<AccessTurnObject>(this.TURN_COLLECTION_NAME, ref => ref
      .where("userID", "==", userID)
      .where("isOpen", "==", true)
      .orderBy("startDateTime", "desc")
      .limit(1)).valueChanges({idField: "uid"});
  }

  getTurnsByDateRange(userID: string, fromDate: number, toDate: number): Observable<AccessTurnObject[]> {
    return this.angularFirestore.collection<AccessTurnObject>(this.TURN_COLLECTION_NAME, ref => ref
      .where("userID", "==", userID)
      .where("endDateTime", ">", fromDate)
      .where("endDateTime", "<", toDate)).valueChanges({idField: "uid"});
  }

  getInActiveTransactions(): Observable<PersonTransactionObject[]> {
    const yesterdayMoment: Moment = moment().subtract(1, "days");
    yesterdayMoment.hours(0);
    yesterdayMoment.minutes(0);
    yesterdayMoment.seconds(0);
    return this.angularFirestore.collection<PersonTransactionObject>(this.COLLECTION_NAME, ref => ref
      .where("isIn", "==", true)
      .where("inDateTime", ">", yesterdayMoment.unix())).valueChanges();
  }

  getInActiveVehicleTransactions(): Observable<VehicleTransactionObject[]> {
    const yesterdayMoment: Moment = moment().subtract(1, "days");
    yesterdayMoment.hours(0);
    yesterdayMoment.minutes(0);
    yesterdayMoment.seconds(0);
    return this.angularFirestore.collection<VehicleTransactionObject>(this.VEHICLES_TRANSACTIONS_COLLECTION_NAME, ref => ref
      .where("isIn", "==", true)
      .where("inDateTime", ">", yesterdayMoment.unix())).valueChanges();
  }

}
