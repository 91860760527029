import {Component, HostListener, OnInit} from "@angular/core";
import {UsersService} from "../../shared/users.service";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {Observable, Subject, Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {QueryObject} from "../../../core/shared/models/common/query.object";
import {SearchObject} from "../../../core/shared/models/common/search.object";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UsersDetailsComponent} from "../users-details/users-details.component";
import {UsersAddEditComponent} from "../users-add-edit/users-add-edit.component";
import {UserEditObject} from "../../../core/shared/models/auth/userEdit.object";

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"]
})
export class UsersListComponent implements OnInit {

  txtCardTitle: string;
  txtCardIcon: string;
  txtUserEmail: string;
  txtUserName: string;
  txtValidated: string;
  txtNewUser: string;
  txtNoData: string;
  innerWidth: number;
  dialogWidth: number;
  itemsToShow: UserInfoObject[] = [];
  dc: string[] = [];
  showBar: boolean;
  tableDataSource: MatTableDataSource<UserInfoObject>;
  querySubscription: Subscription;
  dcSubject: Subject<string[]> = new Subject<string[]>();
  @HostListener("window:resize", ["$event"])
  onresize(event): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = ["Correo Electrónico", "Verificado"];
      this.dialogWidth = this.innerWidth - 50;
      this.dcSubject.next(this.dc);
    } else {
      this.dc = ["Correo Electrónico", "Nombre", "Verificado"];
      this.dialogWidth = 600;
      this.dcSubject.next(this.dc);
    }
  }

  constructor(private usersService: UsersService, private detailDialog: MatDialog,
              private addDialog: MatDialog) { }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<UserInfoObject>();
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 800) {
      this.dc = ["Correo Electrónico", "Verificado"];
      this.dialogWidth = this.innerWidth - 50;
      this.dcSubject.next(this.dc);
    } else {
      this.dc = ["Correo Electrónico", "Nombre", "Verificado"];
      this.dialogWidth = 600;
      this.dcSubject.next(this.dc);
    }
    this.initTexts();
  }

  initTexts(): void {
    this.txtCardIcon = "supervised_user_circle";
    this.txtCardTitle = "Lista de Usuarios Activos";
    this.txtNewUser = "Nuevo";
    this.txtUserEmail = "Correo electrónico";
    this.txtUserName = "Nombre";
    this.txtValidated = "Validado";
  }

  queryDatabase(seachObject: SearchObject): void {
    this.showBar = true;
    const queryObject = new QueryObject();
    switch (seachObject.field) {
      case "Correo Electrónico":
        queryObject.field = "userEmail";
        break;
      case "Nombre":
        queryObject.field = "userName";
        break;
      case "Verificado":
        queryObject.field = "validated";
        break;
      default:
        queryObject.field = null;
        break;
    }
    queryObject.value = seachObject.value;
    if (queryObject.field) {
      this.usersService.loadUsersByFilter(queryObject).subscribe(
        next => {
          console.log(next);
          this.itemsToShow = next;
          this.tableDataSource.data = this.itemsToShow;
          this.showBar = false;
        },
        error => console.error(error),
        () => this.showBar = false
      );
    }
  }

  addEditItem(u?: UserInfoObject): void {
    const theItem: UserEditObject = new UserEditObject();
    if (u) {
      theItem.fromList = true;
      theItem.userInfoObject = u;
      const detailDialgoRef: MatDialogRef<UsersDetailsComponent> =
        this.detailDialog.open(UsersDetailsComponent, {
          panelClass: "dialogWidth",
          autoFocus: true,
          disableClose: true,
          closeOnNavigation: true,
          maxWidth: "100vw",
          data: theItem
        });
    } else {
      theItem.fromList = true;
      theItem.userInfoObject = null;
      const addDialogRef: MatDialogRef<UsersDetailsComponent> =
        this.addDialog.open(UsersDetailsComponent, {
          panelClass: "dialogWidth",
          autoFocus: true,
          disableClose: true,
          closeOnNavigation: true,
          maxWidth: "100vw",
          data: theItem
        });
    }
  }

  getBooleanResult(value: boolean): string {
    return (value ? "SI" : "NO");
  }

}
