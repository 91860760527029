<div fxLayout="column" class="inside-table">
  <table mat-table [dataSource]="tableDataSource" class="table-size">
    <ng-container matColumnDef="Nombre">
      <th mat-header-cell *matHeaderCellDef> {{txtName | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{item.name}} </td>
    </ng-container>
    <ng-container matColumnDef="desde">
      <th mat-header-cell *matHeaderCellDef> {{txtFrom | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{item.fromDateString}} </td>
    </ng-container>
    <ng-container matColumnDef="Hasta">
      <th mat-header-cell *matHeaderCellDef> {{txtTo | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{item.toDateString}} </td>
    </ng-container>
    <ng-container matColumnDef="URL">
      <th mat-header-cell *matHeaderCellDef> {{txtURLString | uppercase}} </th>
      <td mat-cell *matCellDef="let item"> {{item.imageUrl}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dc"></tr>
    <tr mat-row (click)="addEditItem(row)" *matRowDef="let row; columns: dc;"></tr>
  </table>
  <div class="items-location no-data-text" *ngIf="itemsToShow.length === 0">
    {{txtNoData}}
  </div>
  <div class="items-location" fxLayoutAlign="far center">
    <button type="button" class="common-button" mat-raised-button (click)="addEditItem()">
      <mat-icon>{{txtAddIcon}}</mat-icon>{{txtAdd|uppercase}}</button>
  </div>

</div>
