import {Component, Inject, Input, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CommonDialogDataObject} from "../../../shared/models/common/commonDialogData.object";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent implements OnInit {

  txtDialogIcon: string;
  txtDialgoTitle: string;
  txtDialogContent: string;
  txtAccept: string;


  constructor(@Inject(MAT_DIALOG_DATA)private data: CommonDialogDataObject, private thisDialog: MatDialog,
              private thisDialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  ngOnInit(): void {
    if (this.data) {
      this.txtDialgoTitle = this.data.title;
      this.txtDialogContent = this.data.content;
      this.txtDialogIcon = this.data.icon;
    }
    this.txtAccept = "Aceptar";
  }

  close(): void {
    this.thisDialogRef.close();
  }

}
