import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {PersonsAccessService} from "../../shared/persons-access.service";
import {AuthService} from "../../../auth/shared/auth.service";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {Subscription} from "rxjs";
import {AccessTurnObject} from "../../../core/shared/models/access-general/accessTurn.object";
import * as moment from "moment";
import {PersonTransactionObject} from "../../../core/shared/models/access-personel/personTransaction.object";
import {PersonalAccessCollaboratorObject} from "../../../core/shared/models/access-personel/personal-access-collaborator.object";
import {MatDialog} from "@angular/material/dialog";
import {GenericDialogComponent} from "../../../core/components/dialogs/generic-dialog/generic-dialog.component";
import {CommonDialogObject} from "../../../core/shared/models/common/commonDialog.object";
import {CommonDialogDataObject} from "../../../core/shared/models/common/commonDialogData.object";
import {ManagementListObject} from "../../../core/shared/models/management/managementList.object";
import {ManagementListGroupObject} from "../../../core/shared/models/management/managementListGroup.object";
import {VehicleTransactionObject} from "../../../core/shared/models/access-vehicle/vehicleTransaction.object";
import {VehicleAccessTransactionDetailObject} from "../../../core/shared/models/access-vehicle/vehicle-access-transaction-detail.object";
import {MatButton} from "@angular/material/button";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: "app-persons-turn-manager",
  templateUrl: "./persons-turn-manager.component.html",
  styleUrls: ["./persons-turn-manager.component.scss"]
})
export class PersonsTurnManagerComponent implements OnInit, OnDestroy {

  currentUser: UserInfoObject;
  txtInitTurn: string;
  txtEndTurn: string;
  txtPersonalAccessLabel: string;
  txtPersonalAccessIcon: string;
  txtVehicleAccessLabel: string;
  txtVehicleAccessIcon: string;
  txtPleaseInitTurn: string;
  txtPleaseCloseYourTurn: string;
  txtCurrentShift: string;
  txtCurrentTime: string;
  txtCurrentShiftPeopleIN: string;
  txtCurrentShiftCountPeopleIN: string;
  txtCurrentShiftPeopleOUT: string;
  txtCurrentShiftCountPeopleOUT: string;
  txtCurrentShiftVehiclesIN: string;
  txtCurrentShiftCountVehiclesIN: string;
  txtCurrentShiftVehiclesOUT: string;
  txtCurrentShiftCountVehiclesOUT: string;
  txtCurrentDate: string;
  isInTurn: boolean;
  loadingInfo: boolean;
  userSubscription: Subscription;
  currentTurnSubscription: Subscription;
  myTurn: AccessTurnObject;
  @ViewChild("personal", {static: false}) personalButton: MatButton;
  @ViewChild("vehicles", {static: false}) vehiclesButton: MatButton;
  @ViewChild("theTabGroup", {static: false}) theTabGroup: MatTabGroup;

  constructor(private personsAccessService: PersonsAccessService, private authService: AuthService,
              private closeTurnDialog: MatDialog, private openTurnDialog: MatDialog) { }

  ngOnInit(): void {
    this.isInTurn = false;
    this.loadingInfo = true;
    this.initTexts();
    if (!this.authService.getCurrentUserInfo()) {
      this.userSubscription = this.authService.theCurrentUserInfoSubject.asObservable().subscribe(
        next => {
          this.currentUser = next;
          console.log("Nexted", next);
          this.getMyTurn();
        }, error => console.error(error)
      );
    } else {
      this.currentUser = this.authService.getCurrentUserInfo();
      this.getMyTurn();
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription){
      this.userSubscription.unsubscribe();
    }
    if (this.currentTurnSubscription){
      this.currentTurnSubscription.unsubscribe();
    }
  }

  getMyTurn(): void {
    this.currentTurnSubscription = this.personsAccessService.getTurnByUserID(this.currentUser.userID).subscribe(
      next2 => {
        console.log(next2);
        if (next2 && next2.length > 0) {
          this.myTurn = next2[0];
          this.isInTurn = true;
          this.mapTurnData();
          this.loadingInfo = false;
        } else {
          this.isInTurn = false;
          this.loadingInfo = false;
        }
      }, error => console.error(error)
    );
  }

  selectMe(kind: string): void {
    if (kind === "personal") {
      this.personalButton.color = "primary";
      this.vehiclesButton.color = null;
      this.theTabGroup.selectedIndex = 0;
    } else if (kind === "vehicles") {
      this.personalButton.color = null;
      this.vehiclesButton.color = "primary";
      this.theTabGroup.selectedIndex = 1;
    }
  }

  mapTurnData(): void {
    moment.locale("es");
    console.log("My turn", this.myTurn);
    this.txtCurrentTime = moment.unix(this.myTurn.startDateTime).format("hh:mm A");
    this.txtCurrentDate = moment.unix(this.myTurn.startDateTime).format("MMMM DD - YYYY");
    this.txtCurrentShiftCountPeopleIN = `${this.myTurn.personInTurn}`;
    this.txtCurrentShiftCountPeopleOUT = `${this.myTurn.personOutTurn}`;
    this.txtCurrentShiftCountVehiclesIN = `${this.myTurn.vehiclesInTurn}`;
    this.txtCurrentShiftCountVehiclesOUT = `${this.myTurn.vehiclesOutTurn}`;
  }

  initTexts(): void {
    this.txtInitTurn = "Iniciar mi Turno";
    this.txtEndTurn = "Terminar mi Turno";
    this.txtPleaseInitTurn = `Por favor inicie su turno, de  esta forma podrá gestionar el ingreso del personal`;
    this.txtPleaseCloseYourTurn = `Recuerde que debe cerrar su turno al terminar la jornada`;
    this.txtCurrentShift = "El turno actual inició a las:";
    this.txtCurrentTime = "9:15 AM";
    this.txtCurrentShiftPeopleIN = "Han ingresado:";
    this.txtCurrentShiftPeopleOUT = "Han salido:";
    this.txtCurrentShiftCountPeopleOUT = "0";
    this.txtCurrentShiftCountPeopleIN = "0";
    this.txtCurrentShiftVehiclesIN = "Han ingresado:";
    this.txtCurrentShiftVehiclesOUT = "Han salido:";
    this.txtCurrentShiftCountVehiclesIN = "0";
    this.txtCurrentShiftCountVehiclesOUT = "0";
    this.txtPersonalAccessIcon = "follow_the_signs";
    this.txtPersonalAccessLabel = "Acceso Personal";
    this.txtVehicleAccessIcon = "local_shipping";
    this.txtVehicleAccessLabel = "Acceso Vehicular";
    this.txtCurrentDate = "diciembre 12 - 1977";
  }

  startMyTurn(): void {
    const theData = new CommonDialogDataObject();
    theData.title = "Iniciar Turno";
    theData.content = "Desea iniciar su turno?";
    theData.icon = "help";
    const theOpenTurnDialogRef = this.openTurnDialog.open(GenericDialogComponent, {
      data: theData,
      maxWidth: "290px"
    });
    theOpenTurnDialogRef.afterClosed().subscribe(
      next => {
        if (next) {
          this.myTurn = new AccessTurnObject();
          this.myTurn.userID = this.currentUser.userID;
          this.myTurn.userName = this.currentUser.userEmail;
          this.myTurn.personInTurn = 0;
          this.myTurn.personOutTurn = 0;
          this.myTurn.vehiclesInTurn = 0;
          this.myTurn.vehiclesOutTurn = 0;
          this.myTurn.lastXOutVehicles = [];
          this.myTurn.lastXInVehicles = [];
          this.myTurn.lastXOutCollaborators = [];
          this.myTurn.lastXInCollaborators = [];
          this.myTurn.startDateTime = moment().unix();
          this.myTurn.isOpen = true;
          this.personsAccessService.addNewTurn(this.myTurn).subscribe(
            next2 => {
              console.log(next2);
              this.loadMyTurn();
            },
            error => console.error(error)
          );
        }
      }
    );

  }

  updatePersonalTransaction(isEntrance: boolean, item: PersonTransactionObject): void {
    if (!this.myTurn.lastXInCollaborators){
      this.myTurn.lastXInCollaborators = [];
    }
    if (!this.myTurn.lastXOutCollaborators){
      this.myTurn.lastXOutCollaborators = [];
    }
    const paco: PersonalAccessCollaboratorObject = new PersonalAccessCollaboratorObject();
    paco.collaboratorDocumentNumber = item.personID;
    paco.collaboratorName = item.personName;
    if (isEntrance) {
      paco.eventMoment = item.inDateTime;
      this.myTurn.lastXInCollaborators.push(paco);
      this.myTurn.personInTurn += 1;
    } else {
      paco.eventMoment = item.outDateTime;
      this.myTurn.lastXOutCollaborators.push(paco);
      this.myTurn.personOutTurn += 1;
    }
    console.log("My turn", this.myTurn);
    this.personsAccessService.updateTurn(this.myTurn.uid, this.myTurn).subscribe(
      next => console.log("actualizado turno"),
      error => console.error(error)
    );
  }

  updateVehicleTransaction(isEntrance: boolean, item: VehicleTransactionObject): void {
    if (!this.myTurn.lastXInVehicles){
      this.myTurn.lastXInVehicles = [];
    }
    if (!this.myTurn.lastXOutVehicles){
      this.myTurn.lastXOutVehicles = [];
    }
    const vaco: VehicleAccessTransactionDetailObject = new VehicleAccessTransactionDetailObject();
    vaco.vehiclePlate = item.vehiclePlate;
    vaco.reason = item.reason;
    if (isEntrance) {
      vaco.eventMoment = item.inDateTime;
      this.myTurn.lastXInVehicles.push(vaco);
      this.myTurn.vehiclesInTurn += 1;
    } else {
      vaco.eventMoment = item.outDateTime;
      this.myTurn.lastXOutVehicles.push(vaco);
      this.myTurn.vehiclesOutTurn += 1;
    }
    console.log("My turn", this.myTurn);
    this.personsAccessService.updateTurn(this.myTurn.uid, this.myTurn).subscribe(
      next => console.log("actualizado turno"),
      error => console.error(error)
    );
  }


  loadMyTurn(): void {
    this.personsAccessService.getTurnByUserID(this.currentUser.userID).subscribe(
      next => {
        if (next && next.length > 0) {
          this.myTurn = next[0];
          this.mapTurnData();
        }
      },
      error => console.error(error)
    );
  }

  endMyTurn(): void {
    const theData = new CommonDialogDataObject();
    theData.title = "Cerrar Turno";
    theData.content = "Confirma que desea terminar su turno?";
    theData.icon = "help";
    const closeRef = this.closeTurnDialog.open(GenericDialogComponent, {
      maxWidth: "290px",
      data: theData
    });
    closeRef.afterClosed().subscribe(
      next => {
        if (next) {
          console.log("CloseMyTurn", next);
          this.myTurn.isOpen = false;
          this.myTurn.endDateTime = moment().unix();
          this.personsAccessService.updateTurn(this.myTurn.uid, this.myTurn).subscribe(
            next2 => console.log("TurnClosed"),
            error => console.error(error)
          );
        }
      }
    );
  }

  doManagementList(): void {
    const theItem: ManagementListObject = new ManagementListObject();
    theItem.name = "Name";
    theItem.dateOfCreation   = moment().unix();
    theItem.deleted = false;
    theItem.enabled = true;
    theItem.isForGroup = false;
    theItem.items = [];
    const theGroupItem = new ManagementListGroupObject();
    theGroupItem.name = "Sub item";
    theGroupItem.description = "Descripticos";
    theGroupItem.items = [];
    theItem.items.push(theGroupItem);
  }

}
