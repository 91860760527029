import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-demo-dialog",
  templateUrl: "./demo-dialog.component.html",
  styleUrls: ["./demo-dialog.component.scss"]
})
export class DemoDialogComponent implements OnInit {

  displayTitle: string;
  displayText: string;
  displayButton: string;
  displaySecondaryButton: string;
  useSecondary: boolean;

  constructor(public dialogRef: MatDialogRef<DemoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.useSecondary = false;
    this.displayTitle = this.data.title;
    this.displayText = this.data.text;
    this.displayButton = this.data.button;
    if (this.data.secondButton) {
      this.displaySecondaryButton = this.data.secondButton;
      this.useSecondary = true;
    }
  }

  closeDialog(value?: boolean): void {
    if (value) {
      this.dialogRef.close(value);
    }
    else {
      this.dialogRef.close();
    }
  }

}
