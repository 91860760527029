import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {AuthService} from "../../../auth/shared/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: "app-main-toolbar",
  templateUrl: "./main-toolbar.component.html",
  styleUrls: ["./main-toolbar.component.scss"]
})
export class MainToolbarComponent implements OnInit, OnDestroy {

  txtProfile: string;
  txtLogout: string;
  txtProfileIcon: string;
  txtLogoutIcon: string;
  innerWidth: number;
  showUser: boolean;
  showMenuButtonSubscription: Subscription;
  currentUserSubscription: Subscription;
  showTheMenuButton: boolean;
  txtUserPlusEmail: string;

  @Input() txtInstitutionName = "Company Name";
  @Input() txtInstitutionID = "Company ID";
  @Input() currentUser: UserInfoObject;
  @Input() showMenuButtonSubject: Observable<boolean> = new Observable<boolean>();
  @Input() currentUserObservable: Observable<UserInfoObject> = new Observable<UserInfoObject>();
  @Output() toggleMenuEmmiter: EventEmitter<void> = new EventEmitter<void>();

  @HostListener("window:resize", ["$event"])
  onresize(event): void {
    this.innerWidth = window.innerWidth;
    this.showUser = this.innerWidth >= 600;
  }

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.showTheMenuButton = false;
    this.txtLogout = "Cerrar Sesión";
    this.txtProfile = "Mi Perfil";
    this.txtProfileIcon = "account_circle";
    this.txtLogoutIcon = "exit_to_app";
    this.txtUserPlusEmail = "demoUser@demo.com";
    this.innerWidth = window.innerWidth;
    this.showUser = this.innerWidth >= 600;
    this.currentUserSubscription = this.currentUserObservable.subscribe(
      next => {
        this.currentUser = next;
        this.initCurrentUser();
      }
    );
    this.showMenuButtonSubscription = this.showMenuButtonSubject.subscribe(
      next => {
        if (next) {
          this.showTheMenuButton = next;
        } else {
          this.showTheMenuButton = false;
        }
      }
    );
    this.initCurrentUser();
  }

  ngOnDestroy(): void {
    this.showMenuButtonSubscription.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }

  showID(): boolean {
    return this.txtInstitutionID && this.txtInstitutionID.length > 0;
  }

  initCurrentUser(): void {
    console.log("Current user for toolbar", this.currentUser);
    if (this.currentUser) {
      if (this.currentUser.corporateName) {
        this.txtInstitutionName = this.currentUser.corporateName;
      }
      if (this.currentUser.userName && this.currentUser.userEmail) {
        this.txtUserPlusEmail = `${this.currentUser.userName} - ${this.currentUser.userEmail}`;
      }
    }
  }

  toggleMenu(): void {
    this.toggleMenuEmmiter.emit();
  }

  logOut(): void {
    this.authService.logout();
    this.router.navigate(["/"]);
  }

}
