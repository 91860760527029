import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {ProjectObject} from "../../../../core/shared/models/company/project.object";
import {ProjectInUserObject} from "../../../../core/shared/models/company/projectInUser.object";
import {UserKindEnum} from "../../../../core/shared/models/auth/enums/userKind.enum";
import {UserRoleInProjectObject} from "../../../../core/shared/models/auth/userRoleInProject.object";
import {UsersService} from "../../../shared/users.service";
import {MatDialog} from "@angular/material/dialog";
import {UsersProjectsAddEditComponent} from "../users-projects-add-edit/users-projects-add-edit.component";

@Component({
  selector: "app-users-projects",
  templateUrl: "./users-projects.component.html",
  styleUrls: ["./users-projects.component.scss"]
})
export class UsersProjectsComponent implements OnInit {

  tableDataSource: MatTableDataSource<UserRoleInProjectObject>;
  txtPermission: string;
  txtAction: string;
  txtNoRoles: string;
  txtAddNew: string;
  txtProject: string;
  @Input() existingProjects: ProjectObject[] = [];
  @Output() projectsAssigned = new EventEmitter<ProjectInUserObject[]>();
  @Input() projectsLoaded: ProjectInUserObject[] = [];
  @Input() assignationCap = 140;
  dataItems: UserRoleInProjectObject[] = [];
  dc: string[] = [];

  constructor(private usersService: UsersService, private addEditDialog: MatDialog) { }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<UserRoleInProjectObject>();
    this.initTexts();
    if (this.projectsLoaded.length > 0) {
      this.dataItems = this.usersService.projectInUserArrayToUserKRoleInProject(this.projectsLoaded);
    }
    if (this.assignationCap < 0) {
      this.assignationCap = 7;
    }
    this.tableDataSource.data = this.dataItems;
    console.log("Projects", this.existingProjects);
  }

  initTexts(): void {
    this.txtProject = "Proyecto";
    this.txtPermission = "Permiso";
    this.txtAction = "Opciones";
    this.txtNoRoles = "El usuario no tiene roles por proyecto asignados";
    this.txtAddNew = "Agregar rol por proyecto";
    this.dc.push(this.txtProject, this.txtPermission);
  }

  deleteItem(item: UserRoleInProjectObject): void {
    this.dataItems.splice(this.dataItems.findIndex(x => x === item), 1);
    this.projectsAssigned.emit(this.usersService.userRolesInProjectsToProjectsInUser(this.dataItems));
    this.tableDataSource.data = this.dataItems;
  }

  addNewRole(): void {
    const loadingCurrent = this.usersService.userRolesInProjectsToProjectsInUser(this.dataItems);
    console.log("loading dialog", loadingCurrent, this.dataItems);
    const theData = {
      current_roles: loadingCurrent,
      existing_projects: this.existingProjects,
      cap: this.assignationCap
    };
    const dialogRef = this.addEditDialog.open(UsersProjectsAddEditComponent, {
      data: theData
    });
    dialogRef.afterClosed().subscribe(
      next => {
        if (next) {
          this.dataItems.push(next);
          const dataToEmit = this.usersService.userRolesInProjectsToProjectsInUser(this.dataItems);
          console.log("Data to emit", dataToEmit);
          this.projectsAssigned.emit(dataToEmit);
          this.tableDataSource.data = this.dataItems;
        }
      }
    );
  }

}
