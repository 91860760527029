import { Injectable } from "@angular/core";
import {Observable} from "rxjs";
import {AngularFirestore} from "@angular/fire/firestore";
import {VehicleTransactionObject} from "../../core/shared/models/access-vehicle/vehicleTransaction.object";
import {Moment} from "moment";
import * as moment from "moment";
import {PersonTransactionObject} from "../../core/shared/models/access-personel/personTransaction.object";

@Injectable({
  providedIn: "root"
})
export class CommonAreaService {

  private readonly PERSONS_COLLECTION_NAME = "personal_transactions";
  private readonly VEHICLES_COLLECTION_NAME = "vehicles_transactions";

  constructor(private angularFirestore: AngularFirestore) { }

  loadVehicleByPlate(plate: string): Observable<VehicleTransactionObject[]> {
    const yesterdayMoment: Moment = moment().subtract(1, "days");
    yesterdayMoment.hours(0);
    yesterdayMoment.minutes(0);
    yesterdayMoment.seconds(0);
    return this.angularFirestore.collection<VehicleTransactionObject>(this.VEHICLES_COLLECTION_NAME, ref => ref
      .where("isIn", "==", true)
      .where("vehiclePlate", "==", plate)
      .where("inDateTime", ">", yesterdayMoment.unix())).valueChanges();
  }

  getInActiveTransactionswithVehicles(): Observable<PersonTransactionObject[]> {
    const yesterdayMoment: Moment = moment().subtract(1, "days");
    yesterdayMoment.hours(0);
    yesterdayMoment.minutes(0);
    yesterdayMoment.seconds(0);
    return this.angularFirestore.collection<PersonTransactionObject>(this.PERSONS_COLLECTION_NAME, ref => ref
      .where("isIn", "==", true)
      .where("useVehicle", "==", true)
      .where("inDateTime", ">", yesterdayMoment.unix())).valueChanges();
  }
}
