<div class="login-area" ngClass.lt-md="login-area-small" fxLayoutAlign="space-around center" fxLayoutAlign.lt-md="space-around start">
  <mat-card class="login-card mat-elevation-z5" ngClass.lt-md="login-card-small">
    <form [formGroup]="userForm" (ngSubmit)="submitUserInfo(userForm.getRawValue())">
      <img class="image_size" ngClass.lt-md="image_size_small" src="assets/images/kostruAccessLogo.png">
      <mat-card-header >
        <mat-card-title class="header"><h5>{{title}}</h5></mat-card-title>
        <mat-card-subtitle class="subheader"><h6>{{subTitle}}</h6></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content fxLayout="column">
        <mat-form-field fxFlex class="FormFieldAll" appearance="fill">
          <mat-label>{{emailText}}</mat-label>
          <input matInput [formControl]="username" required>
          <mat-error *ngIf="hasError('username', 'required')">{{requiredEmail}}</mat-error>
          <mat-error *ngIf="hasError('username', 'email')">{{requiredEmail}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex class="FormFieldAll" appearance="fill">
          <mat-label>{{fullNameText}}</mat-label>
          <input matInput [formControl]="fullName" required>
          <mat-error *ngIf="hasError('fullName', 'required')">{{requiredFullName}}</mat-error>
          <mat-error *ngIf="hasError('fullName', 'minlength')">{{shortFullName}}</mat-error>
          <mat-error *ngIf="hasError('fullName', 'maxlength')">{{longFullName}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex class="FormFieldAll" appearance="fill">
          <mat-label>{{documentType}}</mat-label>
          <mat-select [formControl]="docType">
            <mat-option *ngFor="let docType of docTypeOptions" [value]="docType.value">{{docType.name}} - ({{docType.value}})</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('docType', 'required')">{{requiredDoctype}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex class="FormFieldAll" appearance="fill">
          <mat-label>{{documentNumber}}</mat-label>
          <input matInput [formControl]="docNumber" type="number" required>
          <mat-error *ngIf="hasError('fullName', 'required')">{{requiredDocNumber}}</mat-error>
          <mat-error *ngIf="hasError('fullName', 'minlength')">{{docNumberShort}}</mat-error>
          <mat-error *ngIf="hasError('fullName', 'maxlength')">{{docNumberLong}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex class="formFieldAll" appearance="fill">
          <mat-label>{{companyName}}</mat-label>
          <mat-select [formControl]="company">
            <mat-option *ngFor="let item of companies | async" [value]="item.name"> {{item.name | uppercase}}</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('company', 'required')">{{requiredCompany}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" class="FormFieldAll">
          <mat-label>{{companyIdentificacion}}</mat-label>
          <input type="number" matInput [formControl]="companyID">
          <mat-error *ngIf="companyID.hasError('required')">{{requiredCompanyId}}</mat-error>
        </mat-form-field>
        <div fxFlex class="FormFieldAll warnings">
          <p *ngIf="showDataWarning"> {{showDataWarningText}}</p>
          <p *ngIf="showEmailWarning"> {{showValidateWarningText}}</p>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-raised-button type="button" (click)="goBack()">{{cancelButton | uppercase}}</button>
        <button mat-raised-button color="primary" [disabled]="!userForm.valid" type="submit">{{sendButton | uppercase}}</button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
