<div class="temp-area" fxLayoutAlign="center center" fxLayout="column">
  <mat-card class="temp-card mat-elevation-z7" ngClass.lt-sm="temp-card-small">
    <img ngClass.lt-md="image_size_small"
         src="assets/images/kostruAccessLogo.png" alt="KostruAccessLogoV1"
         class="temp-image-size" />
    <mat-card-header fxLayoutAlign="center center">
      <h3>{{ txtCardTitle }}</h3>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <button class="temp-button" [routerLink]="['/collaborators/list']" mat-raised-button>{{txtCollaborators}}</button>
        <button class="temp-button" [routerLink]="['/personas-access/list']" mat-raised-button color="primary">{{txtPersonalAccess}}</button>
        <button class="temp-button" [routerLink]="['/vehicles-access/list']" mat-raised-button color="accent">{{txtVehiclesAccess}}</button>
        <button class="temp-button" [routerLink]="['/demo/list']" mat-raised-button color="warn">{{txtDemoList}}</button>
        <button class="temp-button" [routerLink]="['/demo/add-edit']" mat-raised-button>{{txtDemoAddEdit}}</button>
        <button class="temp-button" [routerLink]="['/dashboard']" color="primary" mat-raised-button>{{txtDashboard}}</button>
        <button class="temp-button" [routerLink]="['/inventory/list']" color="accent" mat-raised-button>{{txtInventory}}</button>
        <button class="temp-button" [routerLink]="['/inventory/transactions/in']" color="warn" mat-raised-button>{{txtInventoryIn}}</button>
        <button class="temp-button" [routerLink]="['/inventory/transactions/out']" color="primary" mat-raised-button>{{txtInventoryOut}}</button>
        <button class="temp-button" [routerLink]="['/inventory/transactions/other']" mat-raised-button>{{txtInventoryOther}}</button>
        <mat-form-field>
          <mat-label>Select</mat-label>
          <mat-select>
            <mat-option *ngFor="let item of items" [value]="item.departamento">{{item.departamento}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</div>
