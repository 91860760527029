import {UserKindObject} from "./UserKind.object";

export class UserRoleInProjectObject {
  projectUID: string;
  projectName: string;
  role: UserKindObject;
  constructor(u?: UserRoleInProjectObject) {
    if (u) {
      this.projectName = u.projectName;
      this.projectUID = u.projectUID;
      this.role = u.role;
    }
  }
}
