import {Component, Input, OnInit} from "@angular/core";
import {AccessTurnObject} from "../../../../core/shared/models/access-general/accessTurn.object";
import {animate, state, style, transition, trigger} from "@angular/animations";
import * as moment from "moment";

@Component({
  selector: "app-admin-turns-list",
  templateUrl: "./admin-turns-list.component.html",
  styleUrls: ["./admin-turns-list.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({height: "0px", minHeight: "0"})),
      state("expanded", style({height: "*"})),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class AdminTurnsListComponent implements OnInit {

  txtFrom: string;
  txtTo: string;
  dc: string [] = [];
  @Input() dataTurns: AccessTurnObject[] = [];
  expandedElement: AccessTurnObject | null;

  constructor() { }

  ngOnInit(): void {
    this.initTexts();
  }

  initTexts(): void {
    this.txtFrom = "Desde";
    this.txtTo = "Hasta";
    this.dc = [this.txtFrom, this.txtTo];
  }

  showDate(dateNumber: number): string {
    return moment.unix(dateNumber).format("DD/MM/YYYY - hh:mm:ss A");
  }

  showDetails(item: AccessTurnObject): void {
    console.log("ShowingDetails", item);
  }

}
