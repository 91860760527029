import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../shared/auth.service";
import {filter} from "rxjs/operators";
import {NotFoundKindEnum} from "../../../core/shared/models/auth/enums/notFoundKind.enum";
import {NotFoundEmailDialogDataObject} from "../../../core/shared/models/auth/notFoundEmailDialogData.object";
import {ForgotDialogComponent} from "../dialogs/forgot-dialog/forgot-dialog.component";
import {AuthDialogComponent} from "../dialogs/auth-dialog/auth-dialog.component";
import {Location} from "@angular/common";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"]
})
export class ForgotComponent implements OnInit, OnDestroy {

  subTitle: string;
  title: string;
  forgotForm: FormGroup;
  forgotEmailControl: FormControl;
  forgotTitle: string;
  emailText: string;
  cancelButton: string;
  sendButton: string;
  requiredEmail: string;
  emailNotFound: boolean;
  emailNotFoundText: string;
  submitSubscription: Subscription;

  constructor(private authService: AuthService, private location: Location, private dialog: MatDialog,
              private authDialog: MatDialog, private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.initTexts();
    this.forgotEmailControl = new FormControl("", [Validators.required, Validators.email]);
    this.forgotForm = new FormGroup(
      { forgotEmailControl: this.forgotEmailControl }
    );
    this.emailNotFound = false;
    this.router.queryParams.pipe(filter(params => params.email)).subscribe(
      params => {
        console.log("Params", params);
        this.forgotEmailControl.setValue(params.email);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.submitSubscription) {
      this.submitSubscription.unsubscribe();
    }
  }

  initTexts(): void {
    this.subTitle = "Bienvenidos - ¿Olvidó su contraseña?";
    this.title = "Gestión integral de Fondos de Empleados";
    this.forgotTitle = "Olvidó su contraseña?";
    this.emailText = "Escriba su correo electrónico";
    this.cancelButton = "Cancelar";
    this.sendButton = "Enviar";
    this.requiredEmail = "El correo electrónico es obligatorio";
    this.emailNotFoundText = "El correo electrónico no existe, por favor verifiquelo e intente nuevamente!";
  }

  showNoEmailDialog(kind: NotFoundKindEnum): void {
    const dialogData: NotFoundEmailDialogDataObject = {
      kind,
      cancel: "",
      confirm: "Aceptar"
    };
    const configDialog: MatDialogConfig = new MatDialogConfig<any>();
    configDialog.data = dialogData;
    configDialog.width = "300px";
    const dialogRef = this.dialog.open(ForgotDialogComponent, configDialog);
    dialogRef.afterClosed().subscribe(
      next =>
        console.log("Closed")
    );
  }

  submitForgot(value): void {
    console.log(value.forgotEmail);
    this.submitSubscription = this.authService.sendPasswordResetEmail(value.forgotEmailControl).subscribe(
      next => {
        console.log("Tengo este next", next);
        this.showSentDataDialog();
      }, error => {
        console.error("Tengo este error", error.code);
        if (error.code === "auth/user-not-found") {
          this.forgotForm.controls.forgotEmailControl.setErrors({invalid: true});
          this.showNoEmailDialog(NotFoundKindEnum.NOT_FOUND);
        }
        if (error.code === "auth/invalid-email") {
          this.forgotForm.controls.forgotEmailControl.setErrors({invalid: true});
          this.showNoEmailDialog(NotFoundKindEnum.INVALID);
        }
      }
    );
  }

  showSentDataDialog(): void {
    const dialogData = {
      title: "Envío exitoso",
      text: "La información de recuperación de contraseña se envió satisfactoriamente a su correo," +
        " Por favor revise su bandeja de entrada y siga la información que le enviamos para poder acceder de nuevo al sistema",
      button: "Aceptar"
    };
    const configDialog: MatDialogConfig = new MatDialogConfig<any>();
    configDialog.data = dialogData;
    configDialog.width = "300px";
    const dialogRef = this.dialog.open(AuthDialogComponent, configDialog);
    dialogRef.afterClosed().subscribe(
      next =>
        this.location.back()
    );
  }

  goBack(): void {
    this.location.back();
  }

  public hasError(controlName: string, errorName: string): boolean {
    return this.forgotForm.controls[controlName].hasError(errorName);
  }
}
