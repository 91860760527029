import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {UserKindEnum} from "../../../../core/shared/models/auth/enums/userKind.enum";
import {UserKindObject} from "../../../../core/shared/models/auth/UserKind.object";
import {UsersService} from "../../../shared/users.service";
import {MatDialog} from "@angular/material/dialog";
import {RoleAddEditComponent} from "../role-add-edit/role-add-edit.component";

@Component({
  selector: "app-roles-list",
  templateUrl: "./roles-list.component.html",
  styleUrls: ["./roles-list.component.scss"]
})
export class RolesListComponent implements OnInit {

  tableDataSource: MatTableDataSource<UserKindObject>;
  txtPermission: string;
  txtAction: string;
  txtNoRoles: string;
  txtAddNew: string;
  @Output() userPermits = new EventEmitter<UserKindEnum[]>();
  @Input() userPermitsInput: UserKindEnum[] = [];
  @Input() assignationCap: number;
  dataItems: UserKindObject[] = [];
  dc: string[] = [];

  constructor(private usersService: UsersService, private addRoleDialog: MatDialog) { }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<UserKindObject>();
    this.initTexts();
    if (this.userPermitsInput.length > 0) {
      this.dataItems = this.usersService.userKindEnumsToObjects(this.userPermitsInput);
    }
    if (this.assignationCap < 0) {
      this.assignationCap = 7;
    }
    this.tableDataSource.data = this.dataItems;
  }

  initTexts(): void {
    this.txtPermission = "Permiso";
    this.txtAction = "Opciones";
    this.txtNoRoles = "El usuario no tiene roles asignados";
    this.txtAddNew = "Agregar Rol";
    this.dc.push(this.txtPermission, this.txtAction);
  }

  deleteItem(item): void {
    this.dataItems.splice(this.dataItems.findIndex(x => x === item), 1);
    const items: UserKindEnum[] = [];
    if (this.dataItems.length > 0) {
      for (const it of this.dataItems) {
        items.push(it.userKindEnum);
      }
    }
    this.userPermits.emit(items);
    this.tableDataSource.data = this.dataItems;
  }

  addNewRole(): void {
    const theData = {
      current_roles: this.dataItems,
      cap: this.assignationCap
    };
    const dialogRef = this.addRoleDialog.open(RoleAddEditComponent, {
      data: theData,
    });
    dialogRef.afterClosed().subscribe(
      next => {
        if (next) {
          this.dataItems.push(next);
          this.userPermits.emit(this.usersService.userKindObjectToUserKindEnum(this.dataItems));
          this.tableDataSource.data = this.dataItems;
        }
    });
  }

}
