<div class="main-container" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <mat-card class="main-card card-settings" ngClass.lt-sm="main-card-small">
    <mat-card-header fxLayoutAlign="center center">
      <img width="54px" class="card-title-image" [src]="logoPath"/>
      <h3 class="cardTitleH3 card-title">{{cardTitle}}</h3>
      <span fxFlex></span>
    </mat-card-header>
    <mat-card-content>
      <table mat-table [dataSource]="tableDataSource" class="table-size">
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> {{txtFirstName | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.personalFirstName}} </td>
        </ng-container>
        <ng-container matColumnDef="secondName">
          <th mat-header-cell *matHeaderCellDef> {{txtSecondName | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.personalSecondName}} </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef> {{txtLastName | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.personalLastName}} </td>
        </ng-container>
        <ng-container matColumnDef="secondLastName">
          <th mat-header-cell *matHeaderCellDef> {{txtSecondLastName | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.personalSecondLastName}} </td>
        </ng-container>
        <ng-container matColumnDef="identification">
          <th mat-header-cell *matHeaderCellDef> {{txtIdentification | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.personalIdentification}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="dc"></tr>
        <tr mat-row (click)="selectAction(row)" *matRowDef="let row; columns: dc;"></tr>
      </table>
      <div class="no-data-table" *ngIf="itemsToShow.length === 0">
        <h4>{{noDataText}}</h4>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button class="button-class" mat-raised-button color="primary" (click)="addEditTS()">{{newShiftText | uppercase}}</button>
    </mat-card-actions>
    <mat-card-footer *ngIf="showBar">
      <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
