<div class="main-container" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
  <mat-card class="main-card card-settings" ngClass.lt-sm="main-card-small">
    <mat-card-header fxLayoutAlign="center center">
      <mat-icon>{{txtIcon}}</mat-icon>
      <h3 class="card-title-h3 card-title">{{cardTitle}}</h3>
      <span fxFlex></span>
    </mat-card-header>
    <mat-card-content>
      <table mat-table [dataSource]="tableDataSource" class="table-size">
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef> {{txtName | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.name}} </td>
        </ng-container>
        <ng-container matColumnDef="companyId">
          <th mat-header-cell *matHeaderCellDef> {{txtCompanyId | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.companyID}} </td>
        </ng-container>
        <ng-container matColumnDef="contactEmail">
          <th mat-header-cell *matHeaderCellDef> {{txtContactEmail | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.contactEmail}} </td>
        </ng-container>
        <ng-container matColumnDef="contactPhone">
          <th mat-header-cell *matHeaderCellDef> {{txtContactPhone | uppercase}} </th>
          <td mat-cell *matCellDef="let item"> {{item.contactPhone}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="dc"></tr>
        <tr mat-row (click)="addEditItem(row)"  *matRowDef="let row; columns: dc;"></tr>
      </table>
      <div class="no-data-table" *ngIf="itemsToShow.length === 0">
        <h4>{{noDataText}}</h4>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button class="common-button" (click)="addEditItem()" mat-raised-button color="accent">
        {{newShiftText | uppercase}}
      </button>
    </mat-card-actions>
    <mat-card-footer *ngIf="showBar">
      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
