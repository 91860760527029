export class VehicleAccessTransactionDetailObject {
  vehiclePlate: string;
  reason: string;
  eventMoment: number;
  constructor(vehicleAccessTransactionDetailObject?: VehicleAccessTransactionDetailObject) {
    if (vehicleAccessTransactionDetailObject) {
      this.vehiclePlate = vehicleAccessTransactionDetailObject.vehiclePlate;
      this.reason = vehicleAccessTransactionDetailObject.reason;
      this.eventMoment = vehicleAccessTransactionDetailObject.eventMoment;
    }
  }
}
