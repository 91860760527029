import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SearchObject} from "../../shared/models/common/search.object";

@Component({
  selector: "app-search-box",
  templateUrl: "./search-box.component.html",
  styleUrls: ["./search-box.component.scss"]
})
export class SearchBoxComponent implements OnInit, OnDestroy {

  txtSearch: string;
  txtSearchButton: string;
  txtSearchIcon: string;
  txtSearchValue: string;
  txtErrorField: string;
  txtErrorValue: string;

  @Input() searchFields: string[] = [];
  @Input() searchFieldsObservable: Observable<string[]> = new Observable<string[]>();
  searchFieldSubscription: Subscription;
  searchGroup: FormGroup;
  fieldControl: FormControl;
  valueControl: FormControl;
  @Output() searchData: EventEmitter<SearchObject> = new EventEmitter<SearchObject>();

  constructor() { }

  ngOnInit(): void {
    this.initTexts();
    this.initForms();
    this.searchFieldSubscription = this.searchFieldsObservable.subscribe(
      next => this.searchFields = next
    );
  }

  ngOnDestroy(): void {
    if (this.searchFieldSubscription){
      this.searchFieldSubscription.unsubscribe();
    }
  }

  initTexts(): void {
    this.txtSearch = "Item a buscar";
    this.txtSearchButton = "Buscar";
    this.txtSearchIcon = "search";
    this.txtSearchValue = "Valor";
    this.txtErrorField = "Debe seleccionar un campo a buscar";
    this.txtErrorValue = "Debe escribir un valor";
  }

  initForms(): void {
    this.fieldControl = new FormControl("", Validators.required);
    this.valueControl = new FormControl("", Validators.required);
    this.searchGroup = new FormGroup({
      fieldControl: this.fieldControl,
      valueControl: this.valueControl
    });
  }

  search(items: any): void {
    const searchMe: SearchObject = new SearchObject();
    searchMe.field = items.fieldControl;
    searchMe.value = items.valueControl;
    if (this.searchGroup.valid){
      this.searchData.emit(searchMe);
    }
  }
  changing(ev: any): void {
    console.log("Changing: FV:", this.searchGroup.valid, this.searchGroup.invalid, ev);
  }
}


