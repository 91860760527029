import {VehicleObject} from "../control/vehicle.object";

export class VehicleTransactionObject {
  uid: string;
  companyID: string;
  inUserUID: string; // Dummy en este punto debe ser cualquier id - debe actualizarse por el id de la auth de firebase
  inUserName: string; // Nombre del usuario Que marca el ingreso ingresa
  inDateTime: number; // Unix del momento de ingresar (usar moment.js)
  outUserUID: string; // Dummy en este punto debe ser cualquier id - debe actualizarse por el id de la auth de firebase
  outUserName: string; // Nombre del usuario Que marca el ingreso ingresa
  outDateTime: number; // Unix del momento de ingresar (usar moment.js)
  reason: string;
  showWarning: boolean;
  vehiclePlate: string; // Objeto con los detalles del vehiculo
  isIn: boolean;
  deleted: boolean;
  constructor(v?: VehicleTransactionObject) {
    if (v) {
      if (v.uid) {
        this.uid = v.uid;
      }
      this.companyID = v.companyID;
      this.inUserUID = v.inUserUID;
      this.inUserName = v.inUserName;
      this.inDateTime = v.inDateTime;
      this.outUserName = v.outUserName;
      this.outUserUID = v.outUserUID;
      this.outDateTime = v.outDateTime;
      this.reason = v.reason;
      this.vehiclePlate = v.vehiclePlate;
      this.isIn = v.isIn;
      this.showWarning = v.showWarning;
      this.deleted = v.deleted;
    } else {
      this.showWarning = false;
      this.deleted = false;
    }
  }
}
