import {ProjectObject} from "./project.object";

export class CompanyProjectInfoObject {
  companyId: string;
  companyName: string;
  project: ProjectObject;

  constructor(p?: ProjectObject, cuid?: string, cn?: string) {
    if (cuid) {
      this.companyId = cuid;
    }
    if (cn) {
      this.companyName = cn;
    }
    if (p) {
      this.project = p;
    }
  }
}
