import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {PersonsAccessService} from "../../shared/persons-access.service";
import {PersonTransactionObject} from "../../../core/shared/models/access-personel/personTransaction.object";
import {CollaboratorObject} from "../../../core/shared/models/persons/collaborator.object";
import * as moment from "moment";
import {AuthService} from "../../../auth/shared/auth.service";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmAndVehicleComponent} from "../inner/confirm-and-vehicle/confirm-and-vehicle.component";
import {PersonalVehicleObject} from "../../../core/shared/models/control/personalVehicle.object";
import {CommonDialogDataObject} from "../../../core/shared/models/common/commonDialogData.object";
import {ConfirmDialogComponent} from "../../../core/components/dialogs/confirm-dialog/confirm-dialog.component";
import {NotFoundDialogComponent} from "../dialogs/not-found-dialog/not-found-dialog.component";
import {VisitorAccessObject} from "../../../core/shared/models/control/visitorAccess.object";

@Component({
  selector: "app-persons-access-details",
  templateUrl: "./persons-access-details.component.html",
  styleUrls: ["./persons-access-details.component.scss"]
})
export class PersonsAccessDetailsComponent implements OnInit, OnDestroy {

  txtAccessTitle: string;
  txtAccessSubTitle: string;
  txtDocumentNumber: string;
  txtEnterButton: string;
  txtExitButton: string;
  docNumberFormGroup: FormGroup;
  docNumberFormControl: FormControl;
  collaboratorSubscription: Subscription;
  transactionSubscription: Subscription;
  collaboratorAccessTransaction: PersonTransactionObject;
  currentUser: UserInfoObject;
  @ViewChild("cedula", {static: false}) cedula: ElementRef;
  @Output() personalEntry: EventEmitter<PersonTransactionObject> = new EventEmitter<PersonTransactionObject>();
  @Output() personalExit: EventEmitter<PersonTransactionObject> = new EventEmitter<PersonTransactionObject>();


  constructor(private personsAccessService: PersonsAccessService, private authService: AuthService,
              private confirmDialog: MatDialog, private errorDialog: MatDialog, private notFoundEnterDialog: MatDialog) { }

  ngOnInit(): void {
    this.initTexts();
    this.initForm();
  }

  ngOnDestroy(): void {
    if (this.collaboratorSubscription) {
      this.collaboratorSubscription.unsubscribe();
    }
    if (this.transactionSubscription) {
      this.transactionSubscription.unsubscribe();
    }
  }

  initTexts(): void {
    this.txtDocumentNumber = "Número de documento";
    this.txtAccessTitle = "Gestión de control de acceso";
    this.txtAccessSubTitle = "Escriba correctamente el número de cédula para agilizar el acceso, cada documento será validado en tiempo real!";
    this.txtEnterButton = "Marcar Entrada";
    this.txtExitButton = "Marcar Salida";
  }

  initForm(): void {
    this.docNumberFormControl = new FormControl("", [Validators.required, Validators.min(999999)]);
    this.docNumberFormGroup = new FormGroup({
      docNumberFormControl: this.docNumberFormControl
    });
  }

  clearText(): void {
    this.docNumberFormControl.setValue(null);
  }

  markIn(): void {
    this.getCollaboratorIn(this.docNumberFormControl.value.toString());
  }

  markOut(): void {
    this.getCollaboratorOut(this.docNumberFormControl.value.toString());
  }

  getCollaboratorOut(identification: string): void {
    this.currentUser = this.authService.getCurrentUserInfo();
    this.collaboratorAccessTransaction = null;
    this.transactionSubscription =
      this.personsAccessService.loadSinglePersonalTransaction(identification, this.currentUser.corporateId).subscribe(
      next => {
        if (next) {
          if (next.length > 0) {
            this.collaboratorAccessTransaction = new PersonTransactionObject();
            this.collaboratorAccessTransaction = next[0];
            const dialogRef = this.confirmDialog.open(ConfirmAndVehicleComponent,
              {data: { isIn: false,  transaction: this.collaboratorAccessTransaction}});
            dialogRef.afterClosed().subscribe(
              next2 => {
                if (next2) {
                  this.collaboratorAccessTransaction.outUserName = this.currentUser.userEmail;
                  this.collaboratorAccessTransaction.outUserUID = this.currentUser.userID;
                  this.collaboratorAccessTransaction.outDateTime = moment().unix();
                  this.collaboratorAccessTransaction.isIn = false;
                  this.updateTransaction();
                }
              }
            );
          } else {
            this.showNotFoundExitDialog();
          }
        } else {
          this.showNotFoundExitDialog();
        }
        this.transactionSubscription.unsubscribe();
      }, error => console.error(error)
    );


  }

  getCollaboratorIn(identification: string): void {
    this.currentUser = this.authService.getCurrentUserInfo();
    this.collaboratorAccessTransaction = new PersonTransactionObject();
    this.collaboratorSubscription =
      this.personsAccessService.loadSingleCollaborator(identification, this.currentUser.corporateId).subscribe(
      next => {
        console.log("Collaborator next", next);
        if (next) {
          if (next.length > 0) {
            const coll: CollaboratorObject = next[0];
            let name = coll.personalFirstName;
            if (coll.personalSecondName && coll.personalSecondName){
              name += ` ${coll.personalSecondName}`;
            }
            name += ` ${coll.personalLastName}`;
            if (coll.personalSecondLastName && coll.personalSecondLastName){
              name += ` ${coll.personalSecondLastName}`;
            }
            this.collaboratorAccessTransaction.personID = coll.personalIdentification;
            this.collaboratorAccessTransaction.personPosition = coll.laborRole;
            this.collaboratorAccessTransaction.personName = name;
            this.collaboratorAccessTransaction.inDateTime = moment().unix();
            this.collaboratorAccessTransaction.companyID = this.currentUser.corporateId;
            this.collaboratorAccessTransaction.inUserUID = this.currentUser.userID;
            this.collaboratorAccessTransaction.inUserName = this.currentUser.userEmail;
            this.collaboratorAccessTransaction.isIn = true;
            const dialogRef = this.confirmDialog.open(ConfirmAndVehicleComponent,
              {data: { isIn: true,  transaction: this.collaboratorAccessTransaction}});
            dialogRef.afterClosed().subscribe(
              next1 => {
                if (next1) {
                  this.collaboratorAccessTransaction.useVehicle = next1.hasVehicle;
                  this.collaboratorAccessTransaction.vehicleInfo = new PersonalVehicleObject();
                  if (next1.hasVehicle) {
                    this.collaboratorAccessTransaction.vehicleInfo.vehicleKind = next1.kind;
                    this.collaboratorAccessTransaction.vehicleInfo.plate = next1.plates;
                  }
                  this.personsAccessService.addPersonalTransaction(this.collaboratorAccessTransaction).subscribe(
                    next2 => {
                      this.personalEntry.emit(this.collaboratorAccessTransaction);
                    },
                    error => console.error(error)
                  );
                }
              }, error => console.error(error),
              () => {
                console.log("completed");
                this.docNumberFormControl.setValue("");
                this.cedula.nativeElement.focus();
              }
            );
          } else {
            this.showNotFoundEnterDialog();
          }
        } else {
          this.showNotFoundEnterDialog();
        }
      }
    );
  }

  updateTransaction(): void {
    this.personsAccessService
      .updatePersonalTransaction(this.collaboratorAccessTransaction.uid, this.collaboratorAccessTransaction).subscribe(
      next => {
        console.log("nexted", next);
        this.personalExit.emit(this.collaboratorAccessTransaction);
        this.docNumberFormControl.setValue("");
        this.cedula.nativeElement.focus();
      },
      error => console.error(error),
      () => console.log("completed")
    );
  }

  showNotFoundExitDialog(): void {
    const theData: CommonDialogDataObject = new CommonDialogDataObject();
    theData.content = "El documento ingresado no coincide con el de alguna persona que este trabajando actualmente, por favor" +
      " verifiquelo o cominiquese con los responsables del ingreso de esta persona!";
    theData.title = "Documento sin coincidencia";
    theData.icon = "warning";
    const dialog = this.errorDialog.open(ConfirmDialogComponent, {data: theData, maxWidth: "290px"});
    dialog.afterClosed().subscribe(
      next => {
        this.cedula.nativeElement.focus();
      }
    );
  }

  showNotFoundEnterDialog(): void {
    const dialog = this.notFoundEnterDialog.open(NotFoundDialogComponent, {
      maxWidth: "290px"
    });
    dialog.afterClosed().subscribe(
      next => {
        if (next) {
          this.currentUser = this.authService.getCurrentUserInfo();
          const visitor: VisitorAccessObject = next.visitor;
          const hasVehicle: boolean = next.hasVehicle;
          const vehicle: PersonalVehicleObject = next.vehicle;
          this.collaboratorAccessTransaction.companyID = this.currentUser.corporateId;
          this.collaboratorAccessTransaction.isVisitor = true;
          this.collaboratorAccessTransaction.isIn = true;
          this.collaboratorAccessTransaction.visitor = visitor;
          if (hasVehicle) {
            this.collaboratorAccessTransaction.useVehicle = hasVehicle;
            this.collaboratorAccessTransaction.vehicleInfo = vehicle;
          }
          this.collaboratorAccessTransaction.personName = visitor.name;
          this.collaboratorAccessTransaction.personID = visitor.documentNumber;
          this.collaboratorAccessTransaction.inDateTime = moment().unix();
          this.collaboratorAccessTransaction.inUserUID = this.currentUser.userID;
          this.collaboratorAccessTransaction.inUserName = this.currentUser.userName;
          this.personsAccessService.addPersonalTransaction(this.collaboratorAccessTransaction).subscribe(
            next2 => {
              this.personalEntry.emit(this.collaboratorAccessTransaction);
            }, error => console.error(error),
            () => {
              this.docNumberFormControl.setValue("");
              this.cedula.nativeElement.focus();
            }
          );
        } else {
          this.cedula.nativeElement.focus();
        }
      }, error => console.error(error)
    );
  }

}
