import {SpecialInfoObject} from "./complementary/specialInfo.object";
import {LogObject} from "../common/log.object";

export class CollaboratorObject {
  uid: string;
  companyUID: string;
  personalFirstName: string;
  personalSecondName: string;
  personalLastName: string;
  personalSecondLastName: string;
  personalIdentification: string;
  personalIdentificationType: string;
  personalIdentificationFrom: string;
  personalIdentificationFromCity: string;
  personalIdentificationExpeditionDateString: string; // Format "DD/MM/YYYY"
  personalBloodType: string;
  personalBloodTypeRH: string;
  personalBirthDateString: string; // Format "DD/MM/YYYY"

  contactPhone: string;
  contactEmail: string;
  contactAddress: string;
  contactDepartment: string;
  contactCity: string;
  // COL Only
  laborCCF: string;
  laborARL: string;
  laborEPS: string;
  laborIPS: string;
  laborFPS: string;
  laborCES: string;
  // Labor All
  laborRole: string;
  laborSalary: number;
  // Información Especial
  useSpecialInfo: boolean;
  specialInfoItems: SpecialInfoObject[];
  lastUpdatedBy: string;
  lastUpdated: number;
  deleted: boolean;
  active: boolean;
  logs: LogObject[];

  constructor(c?: CollaboratorObject) {
    if (c) {
      if (c.uid) {
        this.uid = c.uid;
      }
      this.companyUID = c.companyUID;
      this.personalFirstName = c.personalFirstName;
      this.personalSecondName = c.personalSecondName;
      this.personalLastName = c.personalLastName;
      this.personalSecondLastName = c.personalSecondLastName;
      this.personalIdentification = c.personalIdentification;
      this.personalIdentificationType = c.personalIdentificationType;
      this.personalIdentificationFrom = c.personalIdentificationFrom;
      this.personalIdentificationFromCity = c.personalIdentificationFromCity;
      this.personalIdentificationExpeditionDateString = c.personalIdentificationExpeditionDateString;
      this.personalBloodType = c.personalBloodType;
      this.personalBloodTypeRH = c.personalBloodTypeRH;
      this.personalBirthDateString = c.personalBirthDateString;
      this.contactPhone = c.contactPhone;
      this.contactEmail = c.contactEmail;
      this.contactAddress = c.contactAddress;
      this.contactDepartment = c.contactDepartment;
      this.contactCity = c.contactCity;
      this.laborCCF = c.laborCCF;
      this.laborARL = c.laborARL;
      this.laborEPS = c.laborEPS;
      this.laborIPS = c.laborIPS;
      this.laborFPS = c.laborFPS;
      this.laborCES = c.laborCES;
      this.laborRole = c.laborRole;
      this.laborSalary = c.laborSalary;
      this.useSpecialInfo = c.useSpecialInfo;
      this.specialInfoItems = c.specialInfoItems;
      this.logs = c.logs;
      this.deleted = c.deleted;
      this.active = c.active;
    } else {
      this.specialInfoItems = [];
      this.logs = [];
      this.deleted = false;
      this.active = true;
    }
  }
}
