import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../shared/auth.service";
import {Subscription} from "rxjs";
import {ConfirmDialogComponent} from "../../../../core/components/dialogs/confirm-dialog/confirm-dialog.component";
import {CommonDialogDataObject} from "../../../../core/shared/models/common/commonDialogData.object";

@Component({
  selector: "app-verify-reminder-dialog",
  templateUrl: "./verify-reminder-dialog.component.html",
  styleUrls: ["./verify-reminder-dialog.component.scss"]
})
export class VerifyReminderDialogComponent implements OnInit, OnDestroy {

  displayTitle: string;
  displayText: string;
  displayButton: string;
  displaySecondaryButton: string;
  useSecondary: boolean;
  verifySubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<VerifyReminderDialogComponent>, private authService: AuthService,
              private coreDialogRef: MatDialogRef<ConfirmDialogComponent>, private coreDialog: MatDialog) { }

  ngOnInit(): void {
    this.displayTitle = "Verificar Correo electrónico";
    this.displayText = `Recuerde que hemos enviado a su cuenta de correo un email para verificar su existencia, si desea que le reenviemos el correo, por favor haga click acá
    Seguirá viendo este mensaje hasta qhe lo haya verificado.`;
    this.useSecondary = true;
    this.displayButton = "Aceptar";
    this.displaySecondaryButton = "Reenviar";
  }

  ngOnDestroy(): void {
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }

  closeDialog(value?: boolean): void {
    this.dialogRef.close();
  }

  showConfirmDialog(success: boolean): void {
    let content = "";
    if (success) {
      content = "Correo enviado correctamente!";
    } else {
      content = "Error al enviar el correo, por favor intentelo más tarde!";
    }
    const confirmData: CommonDialogDataObject = new CommonDialogDataObject();
    confirmData.icon = "done";
    confirmData.title = "Correo Enviado";
    confirmData.content = content;
    this.coreDialogRef = this.coreDialog.open(ConfirmDialogComponent, {
      data: confirmData
    });
    this.coreDialogRef.afterClosed().subscribe(
      next => this.closeDialog()
    );
  }

  sendVerifyEmail(): void {
    this.verifySubscription = this.authService.verifyRegisterEmail().subscribe(
      next => this.showConfirmDialog(true),
      error => this.showConfirmDialog(false)
    );
  }

}
