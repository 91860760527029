<div fxLayout="column" fxFlex class="main-menu">
  <div class="logo" ngClass.lt-sm="logo-small" fxLayoutAlign="center center">
    <img src="assets/images/kostruAccessLogo.png" height="48">
  </div>
  <div mat-ripple (click)="goHome()" class="button-padding button-size backGround-hover" fxLayoutAlign="start center" *ngIf="homeItem.useMaterialIcon">
    <mat-icon class="menuIcon">{{homeItem.icon}}</mat-icon><span class="menuItemTextSolo menuItemPadding"> {{homeItem.name | uppercase}}</span>
  </div>
  <div (click)="goHome()" class="button-size mat-expansion-panel-header" fxLayoutAlign="start center" *ngIf="!homeItem.useMaterialIcon">
    <span class="menuItemText menuItemPadding"> {{homeItem.name | uppercase}}</span>
  </div>
  <mat-accordion displayMode="flat" multi="true" hideToggle="false">
    <mat-expansion-panel  class="mat-elevation-z0 menuContainer header-prop" *ngFor="let menuItem of theMenu" expanded="true" togglePosition="after">
      <mat-expansion-panel-header class="menuItemText backGround-hover" mat-ripple expandedHeight="48px">
        <div fxLayoutAlign="start center" *ngIf="menuItem.useMaterialIcon">
          <mat-icon class="menuIcon">{{menuItem.icon}}</mat-icon><span class="menuItemText menuItemPadding"> {{menuItem.name | uppercase}}</span>
        </div>
        <div fxLayoutAlign="start center" *ngIf="!menuItem.useMaterialIcon">
          <span class="menuItemText menuItemPadding"> {{menuItem.name | uppercase}}</span>
        </div>
      </mat-expansion-panel-header>
      <mat-nav-list fxFlex dense>
        <ng-template ngFor let-subItem [ngForOf]="menuItem.subItems" class="menuSubItem menuItemPadding">
          <a *ngIf="subItem.useMaterialIcon && !subItem.avoidCurrentLink && subItem.visible" mat-list-item [routerLink]="[menuItem.link, subItem.link]" (click)="clickedSubItem(menuItem, subItem)">
            <mat-icon class="menuIcon">{{subItem.icon}}</mat-icon><h5 class="menuItemPadding"> {{subItem.name | uppercase}}</h5>
          </a>
          <a *ngIf="!subItem.useMaterialIcon && !subItem.avoidCurrentLink && subItem.visible" mat-list-item [routerLink]="[menuItem.link, subItem.link]" (click)="clickedSubItem(menuItem, subItem)">
            <mat-icon class="menuIcon" [svgIcon]="subItem.icon"> </mat-icon><h5 class="menuItemPadding"> {{subItem.name | uppercase}}</h5>
          </a>
          <a *ngIf="subItem.useMaterialIcon && subItem.avoidCurrentLink && subItem.visible" mat-list-item (click)="clickedSubItem(menuItem, subItem)">
            <mat-icon class="menuIcon">{{subItem.icon}}</mat-icon><h5 class="menuItemPadding"> {{subItem.name | uppercase}}</h5>
          </a>
          <a *ngIf="!subItem.useMaterialIcon && subItem.avoidCurrentLink && subItem.visible" mat-list-item (click)="clickedSubItem(menuItem, subItem)">
            <mat-icon class="menuIcon" [svgIcon]="subItem.icon"> </mat-icon><h5 class="menuItemPadding"> {{subItem.name | uppercase}}</h5>
          </a>
        </ng-template>

      </mat-nav-list>
      <mat-divider></mat-divider>
    </mat-expansion-panel>
  </mat-accordion>
  <!--
  <div mat-ripple [routerLink]="" (click)="logout()" class="button-padding button-size backGround-hover" fxLayoutAlign="start center" *ngIf="homeItem.useMaterialIcon">
    <mat-icon class="menuIcon">{{homeItem.icon}}</mat-icon><span class="menuItemTextSolo menuItemPadding"> {{homeItem.name | uppercase}}</span>
  </div>
  <div [routerLink]="" class="button-size mat-expansion-panel-header" fxLayoutAlign="start center" *ngIf="!homeItem.useMaterialIcon">
    <span class="menuItemText menuItemPadding"> {{homeItem.name | uppercase}}</span>
  </div>
  -->
</div>
