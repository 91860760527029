<div fxLayout="row">
  <mat-icon *ngIf="hasIcon">{{txtDialogIcon}}</mat-icon>
  <h3 class="card-title-h3">{{title}}</h3>
</div>
<form (keydown)="keyPressed($event)">
  <mat-dialog-content>
    <div  style="padding: 20px" class="dialog-content">
      {{content}}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end stretch">
    <button mat-raised-button class="common-button" type="button" tabindex="-1" (click)="closeMe()">{{txtCancel|uppercase}}</button>
    <button mat-raised-button class="common-button" color="primary" type="button" (click)="acceptMe()">{{txtAccept|uppercase}}</button>
  </mat-dialog-actions>
</form>
