import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CommonDialogObject} from "../../../shared/models/common/commonDialog.object";
import {CommonDialogDataObject} from "../../../shared/models/common/commonDialogData.object";

@Component({
  selector: "app-generic-dialog",
  templateUrl: "./generic-dialog.component.html",
  styleUrls: ["./generic-dialog.component.scss"]
})
export class GenericDialogComponent implements OnInit {

  title: string;
  content: string;
  txtAccept: string;
  txtCancel: string;
  txtDialogIcon: string;
  hasIcon: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private data: CommonDialogDataObject, private dialogRef: MatDialogRef<GenericDialogComponent>) { }

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title;
      this.content = this.data.content;
      this.hasIcon = false;
      if (this.data.icon && this.data.icon.length > 0) {
        this.txtDialogIcon = this.data.icon;
        this.hasIcon = true;
      }
      this.txtAccept = "Aceptar";
      this.txtCancel = "Cancelar";
    }
  }
  acceptMe(): void {
    this.dialogRef.close(true);
  }

  closeMe(): void {
    this.dialogRef.close();
  }
  keyPressed(keyEvent: KeyboardEvent): void {
    console.log("presionando", keyEvent);
    if (keyEvent.key === "Enter") {
      console.log(keyEvent.key + " Pressed");
      this.dialogRef.close(true);
    }
  }

}
