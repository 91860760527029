import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LogEventKindEnum} from "../../../core/shared/models/common/enums/logEventKind.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CompanyObject} from "../../../core/shared/models/company/companyObject";
import {LogObject} from "../../../core/shared/models/common/log.object";
import {CompanyService} from "../../shared/company.service";
import {Observable, Subscription} from "rxjs";
import * as moment from "moment";
import {GenericDialogComponent} from "../../../core/components/dialogs/generic-dialog/generic-dialog.component";
import {CommonDialogObject} from "../../../core/shared/models/common/commonDialog.object";
import {UserInfoObject} from "../../../core/shared/models/auth/userInfo.object";
import {AuthService} from "../../../auth/shared/auth.service";

@Component({
  selector: "app-company-add-edit",
  templateUrl: "./company-add-edit.component.html",
  styleUrls: ["./company-add-edit.component.scss"]
})
export class CompanyAddEditComponent implements OnInit, OnDestroy {

  private ADD_EVENT = "Empresa - Nueva";
  private EDIT_EVENT = "Empresa - Editada";
  private DELETE_EVENT = "Empresa - Eliminada";
  private CURRENT_USER = "Espiritu Santo - espiritu@santo.com";

  txtOptions: string;
  txtProjects: string;
  txtInfo: string;
  txtCancel: string;
  txtAccept: string;
  txtItemIcon: string;
  dialogTitle: string;
  txtName: string;
  txtCompanyID: string;
  txtContactEmail: string;
  txtContactPhone: string;
  txtBusinessAddress: string;
  txtBusinessCity: string;
  txtBusinessDepartment: string;
  txtLimit: string;
  txtEnabled: string;
  txtDelete: string;
  theNewCompanyText: string;

  txtRequiredName: string;
  txtRequiredCompany: string;
  txtRequiredContactEmail: string;
  txtRequiredContactPhone: string;
  txtRequiredAddress: string;
  txtRequiredCity: string;
  txtRequiredDepartment: string;
  txtRequiredLimit: string;

  companyForm: FormGroup;
  nameControl: FormControl;
  companyIDControl: FormControl;
  contactEmailControl: FormControl;
  contactPhoneControl: FormControl;
  BusinessAddressControl: FormControl;
  BusinessCityControl: FormControl;
  BusinessDepartmentControl: FormControl;
  limitControl: FormControl;
  enabled: FormControl;
  deleted: boolean;
  isNew: boolean;
  current: CompanyObject;
  original: CompanyObject;

  items: any[] = [];
  itemsObs: Observable<any[]>;
  itemsSub: Subscription;
  colombiaSub: Subscription;
  companySub: Subscription;
  cities: any[] = [];
  result: any [] = [];
  nitWidth: number;

  @ViewChild("deleteButton", {static: false}) deleteButton: ElementRef;

  constructor(private service: CompanyService, private confirmDeleteDialog: MatDialog, private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private data, private matDialogRef: MatDialogRef<CompanyAddEditComponent>) {
  }

  ngOnInit(): void {
    this.nitWidth = 30;
    this.isNew = !this.data;
    if (!this.isNew) {
      this.original = this.data;
      this.nitWidth = 50;
    }
    console.log(this.nitWidth);
    this.initForm();
    this.initText();
    this.getLocation();
    const current: UserInfoObject = this.authService.getCurrentUserInfo();
    this.CURRENT_USER = `${current.userName} - ${current.userEmail}`;
  }

  ngOnDestroy(): void {
    if (this.itemsSub) {
      this.itemsSub.unsubscribe();
    }
    if (this.colombiaSub) {
      this.colombiaSub.unsubscribe();
    }
    if (this.companySub) {
      this.companySub.unsubscribe();
    }
  }

  initText(): void {
    if (this.data) {
      this.dialogTitle = "Editar empresa";
    } else {
      this.dialogTitle = "Nueva empresa";
    }
    this.txtProjects = "Proyectos";
    this.txtEnabled = "Habilitar/Deshabilitar Empresa";
    this.txtOptions = "Opciones";
    this.txtInfo = "Datos de la empresa";
    this.txtItemIcon = "corporate_fare";
    this.txtName = "Nombre";
    this.txtCompanyID = "NIT";
    this.txtContactEmail = "Correo electrónico";
    this.txtContactPhone = "Teléfono";
    this.txtBusinessAddress = "Dirección";
    this.txtBusinessCity = "Ciudad";
    this.txtBusinessDepartment = "Departamento";
    this.txtLimit = "Límite";
    this.txtRequiredName = "El nombre es requerido";
    this.txtRequiredCompany = "El NIT es requerido";
    this.txtRequiredContactEmail = "El correo es requerido";
    this.txtRequiredContactPhone = "El teléfono es requerido";
    this.txtRequiredAddress = "La dirección es requerida";
    this.txtRequiredCity = "La ciudad es requerida";
    this.txtRequiredDepartment = "El departamento es requerido";
    this.txtRequiredLimit = "El limite de empleados es requerido";

    this.txtDelete = "Borrar";
    this.txtCancel = "Cancelar";
    this.txtAccept = "Aceptar";

    this.theNewCompanyText = "Los proyectos de las empresas sólo se pueden agregar una vez creadas, por tal motivo debe " +
      "crearla y luego seleccionarla de la lista";
  }

  getLocation(): void {
    this.itemsSub = this.service.getColombiaLocations().subscribe(
      res => {
        this.colombiaSub = this.service.getColombiaLocationsFromURL(res).subscribe(
          res2 => {
            this.items = res2;
            this.getItems();
          },
          error => console.error(error)
        );
      },
      error2 => console.error(error2)
    );

  }

  initForm(): void {
    this.nameControl = new FormControl("", [Validators.required]);
    this.companyIDControl = new FormControl("", [Validators.required]);
    this.contactEmailControl = new FormControl("", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]);
    this.contactPhoneControl = new FormControl("", [Validators.required, Validators.minLength(7),
      Validators.maxLength(15)]);
    this.BusinessAddressControl = new FormControl("", [Validators.required]);
    this.BusinessCityControl = new FormControl("", [Validators.required]);
    this.BusinessDepartmentControl = new FormControl("", [Validators.required]);
    this.limitControl = new FormControl("", [Validators.required]);
    this.enabled = new FormControl("");
    this.companyForm = new FormGroup({
      nameControl: this.nameControl,
      companyIDControl: this.companyIDControl,
      contactEmailControl: this.contactEmailControl,
      contactPhoneControl: this.contactPhoneControl,
      BusinessAddressControl: this.BusinessAddressControl,
      BusinessCityControl: this.BusinessCityControl,
      BusinessDepartmentControl: this.BusinessDepartmentControl,
      limitControl: this.limitControl,
      enabled: this.enabled,
    });
    if (this.isNew) {
      this.BusinessCityControl.disable();
    }
  }

  getItems(): void {
    if (this.original) {
      this.nameControl.setValue(this.original.name);
      this.companyIDControl.setValue(this.original.companyID);
      this.contactEmailControl.setValue(this.original.contactEmail);
      this.contactPhoneControl.setValue(this.original.contactPhone);
      this.BusinessAddressControl.setValue(this.original.businessAddress);
      this.BusinessDepartmentControl.setValue(this.original.businessDepartment);
      if (this.items && this.items.length > 0) {
        const department = this.items.findIndex(x => x.departamento === this.original.businessDepartment);
        this.BusinessDepartmentControl.setValue(this.items[department]);
        this.cities = this.items[department].ciudades;
        this.BusinessCityControl.setValue(this.original.businessCity);
      }
      this.limitControl.setValue(this.original.limit);
      this.enabled.setValue(this.original.enabled);
    }
  }

  showDeleteDialog(): void {
    const theData: CommonDialogObject = {
      title: "Eliminar Empresa!",
      content: `¿Confima que desea eliminar la empresa ${this.current.name}? ¡Esta opciòn no se puede deshacer!`
    };
    const confirmDialogRef = this.confirmDeleteDialog.open(GenericDialogComponent, {
      width: "310px",
      disableClose: true,
      closeOnNavigation: false,
      data: theData
    });
    confirmDialogRef.afterClosed().subscribe(
      next => {
        if (next) {
          this.delete();
        }
      }
    );
  }

  delete(): void {
    if (this.original) {
      this.original.deleted = true;
      this.original.logs.push(new LogObject(moment().unix(), this.DELETE_EVENT, LogEventKindEnum.DELETE, this.CURRENT_USER));
      this.companySub = this.service.updateCompany(this.original.uid, this.original).subscribe(
        res => {
          this.matDialogRef.close();
        }
      );
    }
  }

  save(): void {
    if (this.isNew) {
      this.current = new CompanyObject();
    } else {
      this.current = new CompanyObject(this.original);
    }
    this.current.name = this.nameControl.value;
    this.current.companyID = this.companyIDControl.value;
    this.current.contactEmail = this.contactEmailControl.value;
    this.current.contactPhone = this.contactPhoneControl.value;
    this.current.businessAddress = this.BusinessAddressControl.value;
    this.current.businessCity = this.BusinessCityControl.value;
    this.current.businessDepartment = this.BusinessDepartmentControl.value.departamento;
    this.current.limit = this.limitControl.value;
    this.current.enabled = this.enabled.value;


    if (this.isNew) {
      this.current.logs = [];
      this.current.logs.push(new LogObject(moment().unix(), this.ADD_EVENT, LogEventKindEnum.CREATE, this.CURRENT_USER));
      this.companySub = this.service.addCompany(this.current).subscribe(
        res => {
          this.matDialogRef.close();
        }
      );
    } else {
      const events: string [] = [];
      const title: string = this.EDIT_EVENT + "<br>";
      events.push(title);
      if (this.current.name !== this.original.name) {
        events.push(`Nombre de la empresa: ${this.original.name} => ${this.current.name}` + "<br>");
      }
      if (this.current.companyID !== this.original.companyID) {
        events.push(`NIT: ${this.original.companyID} => ${this.current.companyID}` + "<br>");
      }
      if (this.current.contactEmail !== this.original.contactEmail) {
        events.push(`Correo: ${this.original.contactEmail} => ${this.current.contactEmail}` + "<br>");
      }
      if (this.current.contactPhone !== this.original.contactPhone) {
        events.push(`Teléfono: ${this.original.contactPhone} => ${this.current.contactPhone}` + "<br>");
      }
      if (this.current.businessAddress !== this.original.businessAddress) {
        events.push(`Dirección: ${this.original.businessAddress} => ${this.current.businessAddress}` + "<br>");
      }
      if (this.current.businessCity !== this.original.businessCity) {
        events.push(`Ciudad: ${this.original.businessCity} => ${this.current.businessCity}` + "<br>");
      }
      if (this.current.businessDepartment !== this.original.businessDepartment) {
        events.push(`Departamento: ${this.original.businessDepartment} => ${this.current.businessDepartment}` + "<br>");
      }
      if (this.current.limit !== this.original.limit) {
        events.push(`Limite: ${this.original.limit} => ${this.current.limit}` + "<br>");
      }
      if (this.current.enabled !== this.original.enabled) {
        events.push(`Empresa habilitada: ${this.original.enabled} => ${this.current.enabled}` + "<br>");
      }
      let eventText = "";
      console.log("events", events);
      if (events.length > 1) {
        for (const linea of events) {
          eventText += linea;
        }
        this.current.logs.push(new LogObject(moment().unix(), eventText, LogEventKindEnum.MODIFY, this.CURRENT_USER));
        this.companySub = this.service.updateCompany(this.current.uid, this.current).subscribe(
          res => {
            this.matDialogRef.close();
          }
        );
      } else {
        this.matDialogRef.close();
      }
    }
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.companyForm.controls[controlName].hasError(errorName);
  }

  updateCities(event: any): void {
    if (event.value.ciudades && event.value.ciudades.length > 0) {
      this.cities = event.value.ciudades;
      this.BusinessCityControl.enable();
    }
  }

}
