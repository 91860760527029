import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatButton} from "@angular/material/button";
import {PersonsAccessService} from "../../../shared/persons-access.service";
import {PersonTransactionObject} from "../../../../core/shared/models/access-personel/personTransaction.object";
import {AuthService} from "../../../../auth/shared/auth.service";
import {UserInfoObject} from "../../../../core/shared/models/auth/userInfo.object";
import {Subject, Subscription} from "rxjs";
import {VehicleForListObject} from "../../../../core/shared/models/access-vehicle/vehicle-for-list.object";
import {VehicleTransactionObject} from "../../../../core/shared/models/access-vehicle/vehicleTransaction.object";

@Component({
  selector: "app-admin-access-list",
  templateUrl: "./admin-access-list.component.html",
  styleUrls: ["./admin-access-list.component.scss"]
})
export class AdminAccessListComponent implements OnInit, OnDestroy {

  txtIcon: string;
  cardTitle: string;
  txtAccessTitle: string;
  txtPersonsAccess: string;
  txtVehiclesAccess: string;
  txtVisitorsAccess: string;
  txtCollaboratorsBtn: string;
  txtVehiclesBtn: string;
  txtVisitorsBtn: string;
  showBar: boolean;
  showTabs: boolean;
  selectMe: number;
  collaboratorsCounter = 0;
  vehiclesCounter = 0;
  visitorsCounter = 0;
  currentUser: UserInfoObject;
  totalItemsToShow: PersonTransactionObject[] = [];
  collaborators: PersonTransactionObject[] = [];
  visitors: PersonTransactionObject[] = [];
  vehiclesTransactions: VehicleTransactionObject[] = [];
  vehicles: VehicleForListObject[] = [];
  vehiclesByPersonal: VehicleForListObject[] = [];
  vehiclesAlone: VehicleForListObject[] = [];
  userSubs: Subscription;
  activeDataSub: Subscription;
  vehiclesDataSub: Subscription;
  collaboratorsSubject: Subject<PersonTransactionObject[]> = new Subject<PersonTransactionObject[]>();
  visitorsSubject: Subject<PersonTransactionObject[]> = new Subject<PersonTransactionObject[]>();
  vehiclesSubject: Subject<VehicleForListObject[]> = new Subject<VehicleForListObject[]>();

  @ViewChild("collaboratorsBtn", {static: false}) collaboratorsBtn: MatButton;
  @ViewChild("visitorsBtn", {static: false}) visitorsBtn: MatButton;
  @ViewChild("vehiclesBtn", {static: false}) vehiclesBtn: MatButton;

  constructor(private personsAccessService: PersonsAccessService, private authService: AuthService) { }

  ngOnInit(): void {
    this.selectMe = 0;
    this.showTabs = false;
    this.initTexts();
    this.currentUser = this.authService.getCurrentUserInfo();
    if (this.currentUser) {
      this.loadActiveData();
    }
    this.userSubs = this.authService.theCurrentUserInfoSubject.asObservable().subscribe(
      next => {
        this.currentUser = next;
        this.loadActiveData();
      }
  );
  }

  ngOnDestroy(): void {
    if (this.userSubs) {
      this.userSubs.unsubscribe();
    }
    if (this.activeDataSub) {
      this.activeDataSub.unsubscribe();
    }
  }

  initTexts(): void {
    this.txtIcon = "sync_alt";
    this.cardTitle = "Resumen de control de acceso";
    this.txtAccessTitle = "Estado Actual";
    this.txtPersonsAccess = "Colaboradores en la obra";
    this.txtVehiclesAccess = "Vehículos en la obra";
    this.txtVisitorsAccess = "Visitantes en la obra";
    this.txtCollaboratorsBtn = "Ver Colaboradores";
    this.txtVisitorsBtn = "Ver Visitantes";
    this.txtVehiclesBtn = "Ver Vehículos";
  }

  loadActiveData(): void {
    this.activeDataSub = this.personsAccessService.getInActiveTransactions().subscribe(
      next => {
        this.totalItemsToShow = next;
        this.organizeAccess();
      },
      error => console.error(error)
    );
    this.vehiclesDataSub = this.personsAccessService.getInActiveVehicleTransactions().subscribe(
      next => {
        this.vehiclesTransactions = next;
        this.organizeVehiclesAccess();
      }, error => console.error(error)
    );
  }

  organizeVehiclesAccess(): void {
    this.vehiclesAlone = [];
    if (this.vehiclesTransactions.length > 0) {
      for (const vehicle of this.vehiclesTransactions) {
        const currentVehicle: VehicleForListObject = new VehicleForListObject();
        currentVehicle.plate = vehicle.vehiclePlate;
        currentVehicle.reason = vehicle.reason;
        currentVehicle.showWarning = vehicle.showWarning;
        this.vehiclesAlone.push(currentVehicle);
      }
      this.vehicles  = [];
      this.vehicles.push(...this.vehiclesAlone);
      this.vehicles.push(...this.vehiclesByPersonal);
      this.vehiclesCounter = this.vehicles.length;
      this.vehiclesSubject.next(this.vehicles);
      console.log("CV", this.vehicles);
    }
  }

  organizeAccess(): void {
    this.vehiclesByPersonal = [];
    if (this.totalItemsToShow.length > 0) {
      this.visitors = [];
      this.collaborators = [];
      for (const person of this.totalItemsToShow) {
        if (person.isVisitor) {
          this.visitors.push(person);
          if (person.useVehicle) {
            if (person.vehicleInfo && person.vehicleInfo.plate.length > 0) {
              const currentVehicle: VehicleForListObject = new VehicleForListObject();
              currentVehicle.plate = person.vehicleInfo.plate;
              currentVehicle.reason = "De Visitante: " + person.personName;
              currentVehicle.showWarning = false;
              this.vehiclesByPersonal.push(currentVehicle);
            }
          }
        } else {
          this.collaborators.push(person);
          if (person.useVehicle) {
            if (person.vehicleInfo && person.vehicleInfo.plate.length > 0) {
              const currentVehicle: VehicleForListObject = new VehicleForListObject();
              currentVehicle.plate = person.vehicleInfo.plate;
              currentVehicle.reason = "De " + person.personName + " " + person.personPosition;
              currentVehicle.showWarning = false;
              this.vehiclesByPersonal.push(currentVehicle);
            }
          }
        }
      }
      this.visitorsCounter = this.visitors.length;
      this.collaboratorsCounter = this.collaborators.length;
      this.visitorsSubject.next(this.visitors);
      this.collaboratorsSubject.next(this.collaborators);
      this.vehicles  = [];
      this.vehicles.push(...this.vehiclesAlone);
      this.vehicles.push(...this.vehiclesByPersonal);
      this.vehiclesCounter = this.vehicles.length;
      this.vehiclesSubject.next(this.vehicles);
      console.log("CV", this.vehicles);
    }
  }

  changeColorAndClick($event, kind: string): void {
    switch (kind) {
      default:
        break;
      case "collaboratorBtn":
        this.collaboratorsBtn.color = "primary";
        this.vehiclesBtn.color = null;
        this.visitorsBtn.color = null;
        this.showTabs = true;
        this.selectMe = 0;
        break;
      case "vehicleBtn":
        this.collaboratorsBtn.color = null;
        this.vehiclesBtn.color = "primary";
        this.visitorsBtn.color = null;
        this.showTabs = true;
        this.selectMe = 1;
        break;
      case "visitBtn":
        this.collaboratorsBtn.color = null;
        this.vehiclesBtn.color = null;
        this.visitorsBtn.color = "primary";
        this.showTabs = true;
        this.selectMe = 2;
        break;
    }
  }

}
