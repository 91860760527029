import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from "./components/register/register.component";
import { TempComponent } from "./components/temp/temp.component";
import {CoreModule} from "../core/core.module";
import { ForgotComponent } from "./components/forgot/forgot.component";
import { ForgotDialogComponent } from "./components/dialogs/forgot-dialog/forgot-dialog.component";
import { AuthDialogComponent } from "./components/dialogs/auth-dialog/auth-dialog.component";
import { UserInfoComponent } from "./components/user-info/user-info.component";
import { VerifyReminderDialogComponent } from "./components/dialogs/verify-reminder-dialog/verify-reminder-dialog.component";

@NgModule({
  declarations: [LoginComponent, RegisterComponent, TempComponent, ForgotComponent, ForgotDialogComponent, AuthDialogComponent,
    UserInfoComponent,
    VerifyReminderDialogComponent],
  imports: [
    CommonModule,
    CoreModule
  ],
  providers: [
    ForgotDialogComponent, AuthDialogComponent, VerifyReminderDialogComponent
  ]
})
// @ts-ignore
export class AuthModule { }
