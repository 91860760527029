import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {CoreModule} from "./core/core.module";
import {AppRoutes, AppRoutingProviders} from "./app.router";
import {VehiclesAccessModule} from "./vehicles-access/vehicles-access.module";
import {PersonsAccessModule} from "./persons-access/persons-access.module";
import {CollaboratorsModule} from "./collaborators/collaborators.module";
import {AuthModule} from "./auth/auth.module";
import {DemoModule} from "./demo/demo.module";
import {DashboardModule} from "./dashboard/dashboard.module";
import {InventoryModule} from "./inventory/inventory.module";
import {UsersModule} from "./users/users.module";
import {CompanyModule} from "./company/company.module";
import {CommonAreaModule} from "./common-area/common-area.module";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutes,
    CoreModule,
    DashboardModule,
    AuthModule,
    CollaboratorsModule,
    VehiclesAccessModule,
    PersonsAccessModule,
    InventoryModule,
    UsersModule,
    DemoModule,
    CompanyModule,
    CommonAreaModule,
  ],
  providers: [AppRoutingProviders],
  bootstrap: [AppComponent]
})
// @ts-ignore
export class AppModule { }
