import {ManagementItemObject} from "./managementItem.object";
import {ManagementGroupStatusEnum} from "./enums/managementGroupStatus.enum";
import {LogObject} from "../common/log.object";
import {ManagementGroupKindEnum} from "./enums/managementGroupKind.enum";

export class ManagementListGroupObject {
  position: number;
  name: string;
  dateOfCreation: number;
  description: string;
  dateTimeMomentOfCompletion: number;
  supportInfo: string;
  items: ManagementItemObject[];
  managementGroupStatus: ManagementGroupStatusEnum;
  managementGroupKind: ManagementGroupKindEnum;
  enabled: boolean;
  deleted: boolean;
  logs: LogObject[];

  constructor(managementListGroupObject?: ManagementListGroupObject) {
    if (managementListGroupObject) {
      this.position = managementListGroupObject.position;
      this.name = managementListGroupObject.name;
      this.dateOfCreation = managementListGroupObject.dateOfCreation;
      this.description = managementListGroupObject.description;
      this.dateTimeMomentOfCompletion = managementListGroupObject.dateTimeMomentOfCompletion;
      this.supportInfo = managementListGroupObject.supportInfo;
      this.items = managementListGroupObject.items;
      this.managementGroupStatus = managementListGroupObject.managementGroupStatus;
      this.managementGroupKind = managementListGroupObject.managementGroupKind;
      this.enabled = managementListGroupObject.enabled;
      this.deleted = managementListGroupObject.deleted;
      this.logs = managementListGroupObject.logs;
    } else {
      this.items = [];
      this.managementGroupStatus = ManagementGroupStatusEnum.CREATED;
      this.enabled = true;
      this.deleted = false;
      this.logs = [];
    }
  }
}
