import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchPlatesComponent } from "./components/search-plates/search-plates.component";
import {CoreModule} from "../core/core.module";
import {CommonAreaService} from "./shared/common-area.service";



@NgModule({
  declarations: [SearchPlatesComponent],
  imports: [
    CommonModule,
    CoreModule
  ], providers: [
    CommonAreaService,
  ]
})
export class CommonAreaModule { }
