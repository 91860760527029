import {UserInfoObject} from "./userInfo.object";

export class UserEditObject {
  userInfoObject: UserInfoObject;
  fromList: boolean;

  constructor(u?: UserEditObject) {
    if (u) {
      this.userInfoObject = u.userInfoObject;
      this.fromList = u.fromList;
    }
  }

}
